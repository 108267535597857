import { makeObservable, observable, computed, action } from 'mobx';

import {getMembersQuery} from '../../services/Queries';
import { apiHost } from '../../services/APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const EMPTY_MESSAGE = { status: "", help: "" };
const ERROR_MESSAGE = { status: "error", help: "Unable to fetch the Members. The Service is unavailable. Please try after sometime." };

export default class MemberListStore {
    
    state = PENDING;
    message = EMPTY_MESSAGE;
    members = [];
    rowCount = 0;
    selectedMembers=0;
   
    constructor(props) {
        this.apiProxy = props.apiProxy;

        makeObservable(this, {
            state: observable,
            message: observable,
            members: observable,
            rowCount: observable,
            selectedMembers:observable,

            isLoading: computed,
            isError: computed,
            isDone: computed,

            fetchMembers: action,
        });
    }

    get isLoading() {
        return this.state === PENDING;
    }

    get isError() {
        return this.state === ERROR;
    }

    get isDone() {
        return this.state === DONE;
    }

   
    /**
     * 
     * Send the request to the endpoint using the FetchAPI and return the list of members/patients
     *
     */
    fetchMembers = async () => {
        let count=0;
        this.state = PENDING;
        this.message = EMPTY_MESSAGE;

        const criteria = {
            userId: this.apiProxy.getUserId()
        }

        try {
            const response = await this.apiProxy.query(apiHost,getMembersQuery,criteria);
            const result = await response.json();
            console.log("result",result)
            if(result.data.fetchPatients === undefined) {
                this.state = ERROR;
                this.message = ERROR_MESSAGE;
                return;
            }
            const rowData = result.data.fetchPatients.patients;
            
            this.members = rowData;
            this.rowCount = rowData.length;
            for (let i=0;i<this.rowCount;i++){
                if(this.members[i].patient.isSelected){  
                    count+=1
                }     
            }
            this.selectedMembers=count;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
            this.message = ERROR_MESSAGE
        }
    }
}
