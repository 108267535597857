export const SearchStyle = {
  width: "85%",
  height: "30px",
  minHeight: "30px",
  maxHeight: "30px",
  margin: "2.4vh 7% 1vh 8%",
  borderRadius: "0 !important",
};

export const cardStyle = {
  className: "cart-card-style",
};
export const imageStyle = {
    marginLeft: "3px",
  };
export const badgeStyle = {
  color: "white",
  backgroundColor: "#8bc34a",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: "bolder",
  marginLeft: "25px",
};

export const tagStyle = {
    borderRadius: "3px",
    fontFamily: "Poppins-Regular",
  };
export const TestTubeIcon={ fontSize: "1.7rem", opacity: ".75" }

export const listStyle = {
    height: "100%",
    overflowY: "auto",
    marginLeft: "1vw",
    marginRight: "1vw",
    scrollbarWidth: "none",
  };
