import React from "react";
import style from "./Featured/Featured.module.css";
import image1 from "./images/ser1.jpg";
import image2 from "./images/ser2.jpg";
import image3 from "./images/ser3.jpg";
import image4 from "./images/ser4.jpg";
import image5 from "./images/ser5.jpg";
import image6 from "./images/ser6.jpg";
import bgImage from "./Featured/images/bgImg_services.jpeg";

export const ServicePage = () => {
  return (
    <div>
      <h2
        className={style.serviceheading}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        Our Services
      </h2>
      <div
        style={{
          backgroundColor: "transparent",
          padding: "0px 50px 50px 50px",
        }}
      >
        <div className={style.mainServiceDiv}>
          <div className={style.serviceimageBox}>
            <img src={image1} alt={"Image_h1"} id={style.SI1} className={style.serviceimage} />
          </div>
          <div className={style.textDiv} id={style.ST1} >
            <h3 className={style.heading_h3}>WHOLE AB SCAN</h3>
            <p className={style.text}>
              An abdominal ultrasound is a safe and painless test that uses
              sound waves to make images of the abdomen (belly). During the
              examination, an ultrasound machine sends sound waves into the
              abdominal area and images are recorded on a computer. The
              black-and-white images show the internal structures of the
              abdomen, such as the appendix, intestines, liver, gall bladder,
              pancreas, spleen, kidneys, and urinary bladder. A complete
              ultrasound of the abdomen evaluates all of the abdominal organs.
            </p>
            {/* <button className={style.button}>Book Now</button> */}
          </div>
        
          
          <div className={style.textDiv} id={style.ST2}>
            <h3 className={style.heading_h3} style={{ marginTop: "100px" }}>
              PFT - PULMONARY FUNCTION TEST
            </h3>
            <p className={style.text}>
              Pulmonary function tests (PFTs) are a group of tests that measure
              how well your lungs work. This includes how well you’re able to
              breathe and how effective your lungs are able to bring oxygen to
              the rest of your body. It is used to monitor the course of chronic
              lung disease, such as asthma or chronic obstructive pulmonary
              disease (COPD).{" "}
            </p>
            {/* <button className={style.button}>Book Now</button> */}
          </div>
          <div className={style.serviceimageBox2}>
            <img
              src={image2}
              alt={"Image_h1"}
              id={style.SI2}
              className={style.serviceimage2}
            />
          </div>
       
          <div className={style.serviceimageBox}>
            <img src={image3} alt={"Image_h1"}id={style.SI3} className={style.serviceimage} />
          </div>
          <div className={style.textDiv} id={style.ST3}>
            <h3 className={style.heading_h3}>DIGITAL X RAYS</h3>
            <p className={style.text}>
              Digital radiography / Xray is a form of X-ray imaging, where
              digital X-ray sensors are used instead of tradition photographic
              film. Its advantages include time efficiency through bypassing
              chemical processing and the ability to digitally transfer and
              enhance images. Also, the radiation produced is less as compared
              to conventional radiography.
            </p>
            {/* <button className={style.button}>Book Now</button> */}
          </div>
       
          <div className={style.textDiv} id={style.ST4}>
            <h3 className={style.heading_h3} style={{ marginTop: "100px" }}>
              DENTAL X RAYS
            </h3>
            <p className={style.text}>
              Dental X-rays (radiographs) are images of your teeth that your
              dentist uses to evaluate your oral health. These X-rays are used
              with low levels of radiation to capture images of the interior of
              your teeth and gums. Dental X-rays may seem complex, but they’re
              actually very common tools that are just as important as your
              teeth cleanings.
            </p>
            {/* <button className={style.button}>Book Now</button> */}
          </div>
          <div className={style.serviceimageBox2}>
            <img
              src={image4}
              alt={"Image_h1"}
              id={style.SI4}
              className={style.serviceimage2}
            />
          </div>
       
          <div className={style.serviceimageBox}>
            <img src={image5} alt={"Image_h1"}id={style.SI5} className={style.serviceimage} />
          </div>
          <div className={style.textDiv}id={style.ST5}>
            <h3 className={style.heading_h3}>CBCT DENTAL X RAYS</h3>
            <p className={style.text}>
              Dental cone beam computed tomography (CT) is a special type of
              x-ray machine used in situations where regular dental or facial
              x-rays are not sufficient. It is not used routinely because the
              radiation exposure from this scanner is significantly more than
              regular dental x-rays. This type of CT scanner uses a special type
              of technology to generate three dimensional (3-D) images of dental
              structures, soft tissues, nerve paths and bone in the craniofacial
              region in a single scan. Images obtained with cone beam CT allow
              for more precise treatment planning.{" "}
            </p>
            {/* <button className={style.button}>Book Now</button> */}
          </div>
       
          <div className={style.textDiv}id={style.ST6}>
            <h3 className={style.heading_h3} style={{ marginTop: "100px" }}>
              BLOOD SUGAR
            </h3>
            <p className={style.text}>
              Blood sugar, or glucose, is the main sugar found in your blood. It
              comes from the food you eat, and is your body's main source of
              energy. Your blood carries glucose to all of your body's cells to
              use for energy. You can test your blood sugar at home with a
              portable electronic device (glucose meter) that measures sugar
              level in a small drop of your blood.{" "}
            </p>
            {/* <button className={style.button}>Book Now</button> */}
          </div>
          <div className={style.serviceimageBox2}>
            <img
              src={image6}
              alt={"Image_h1"}
              id={style.SI6}
              className={style.serviceimage2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
