import React, { Component } from "react";
import { observer } from "mobx-react";
import { Card, List, Tag, Typography, Image, notification, Badge, Row, Col, Button } from "antd";
import { DONE } from "../../label.EN";
import MutableCartStore from "./MutableCartStore";
import TestSearch from "../search/TestSearch";
import ProductCard from "./ProductCard";
import testTube from "../../images/transparentTestTube.svg";
import { RiTestTubeLine } from 'react-icons/ri';
import { listStyle, imageStyle, tagStyle, cardStyle, SearchStyle, badgeStyle, TestTubeIcon } from "./CartItemsCss.js";


class CartItems extends Component {
  constructor(props) {
    super(props);
    this.apiProxy = props.apiProxy;
    this.listStore = props.cartStore;
    this.userId = this.apiProxy.getUserId();
    this.branchId = this.apiProxy.userContext.branchId;
    this.store = new MutableCartStore({
      apiProxy: this.apiProxy,
      listStore: this.listStore,
      userId: this.userId,
      notificationStore: props.notificationStore,
    });
  }

  onChange = async (productId) => {
    let status = await this.store.addProductToCart(productId);
    console.log(status);
    let type = productId.split("-")[0];
    let added = true;
    let message = "";
    if (status === 0) {
      message = "Hurray! A new test is added.";
    } else if (status === 1) {
      message = "Deleted repeated tests/profile, Added";
    } else {
      added = false;
      this.openNotification("success", "topRight", "This test is already added.");
    }
    if (added) {
      type === "P"
        ? this.openNotification("success", "topRight", message)
        : this.openNotification("success", "topRight", message);
    }
  };

  openNotification = (type, placement, description) => {
    notification[type]({
      description: description,
      placement,
      duration: 6,
    });
    this.store.state = DONE;
  };

  onSearch = (value) => {
    if (value.length > 2) {
      this.listStore.search(value);
    }
  };

  renderCardList = (cartProducts) => {
    return (
      <List
        locale={{ emptyText: <p>Please select a test</p> }}
        dataSource={cartProducts}
        style={listStyle}
        renderItem={(item) => (
          <List.Item>
            <ProductCard
              productId={item.product.id}
              store={this.store}
              name={item.product.name}
              parameters={item.product.parameters}
              instruction={item.product.specialInstructions}
              basePrice={item.shoppingCart.basePrice}
              quantity={item.shoppingCart.quantity}
              totalAmount={item.shoppingCart.actualPrice}
              cartId={item.shoppingCart.id}
            />
          </List.Item>
        )}
      />
    );
  };

  renderTestTube = () => {
    return (
      <Image
        src={testTube}
        height="13px"
        style={imageStyle}
        alt="cart_test_tube"
        preview={false}
      />
    );
  };

  renderTestCountTag = (tests) => {
    return (
      <Tag style={tagStyle} color="#2D94C6">
        {this.listStore.cartCount} {tests} {this.renderTestTube()}
      </Tag>
    );
  };

  nextPage = () => {
    const { showMembersFn, showTestsFn, showTimeFn } = this.props;
    console.log("nextpage");
    showMembersFn(false);
    showTestsFn(false);
    showTimeFn(true);
  };

  render() {
    const cartProducts = this.listStore.cartProducts;

    return (
      <Card {...cardStyle}>
        <Row align="bottom" justify="space-around">
          <Col span={19}>
            <TestSearch
              SearchStyle={SearchStyle}
              apiProxy={this.apiProxy}
              branchId={this.branchId}
              onChange={this.onChange}
              placeholder="Select a Test"
            />
          </Col>
          <Col span={4}>
            <Badge count={this.listStore.cartCount} style={badgeStyle}>
              <RiTestTubeLine style={TestTubeIcon} />
            </Badge>
          </Col>
        </Row>
        <div className="cart-time-style-div">
          {this.renderCardList(cartProducts)}
        </div>
        <div className="cart-next-style-div">
          {/* <Button
            style={{
              borderRadius: "15px",
              backgroundColor: "#2D94C6",
              color: "white",
              position: "absolute",
              bottom: 25,
              right: 55,
            }}
            onClick={this.nextPage}
          >
            Next
          </Button> */}
        </div>
      </Card>
    );
  }
}

export default observer(CartItems);
