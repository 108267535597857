import React from "react";
import { observer } from "mobx-react";

import { Card, Button, List, Typography } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import ProfileMemberCard from "./ProfileMemberCard";

const { Text } = Typography;

function ProfileMemberList({ allMembers, memberStore }) {

  const renderMemberList = () => {
    return (
      <List style={{ marginLeft: 10, marginRight: 10, height: '50vh', overflowY: 'auto' }}
        itemLayout="horizontal"
        dataSource={allMembers}
        renderItem={(item) =>
          <ProfileMemberCard patient={item.patient} salutation={item.salutationType} memberStore={memberStore} />
        }
      />
    )
  }

  const addMemberButton = () => {
    if (memberStore.canAdd) {
      return (
        <Button style={{ borderRadius: "15px", backgroundColor: "#8BC34A", color: "white" }} key='addButton' onClick={() => memberStore.setMode('add')}>
          Add Member<UserAddOutlined/>
        </Button>
      )
    }
  }

  const renderTitle = () => {
    return (
      <Text className="card-header">Members</Text>
    );
  }

  return (
    <Card title={renderTitle()}
      className="cart-card-style"
      style={{ paddingBottom: "10px" }}
      actions={[addMemberButton()]}>
      {renderMemberList()}
    </Card>
  );
}

export default observer(ProfileMemberList);
