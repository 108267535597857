import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, notification } from "antd";
import OtpInput from "react18-input-otp";
import "./BookTestModal.css";

const OtpInputStyle = {
  width: "2.5rem",
  height: "2.5rem",
  margin: "0 .5rem",
  fontFamily: "Poppins-Medium",
  color: "#6C757D",
  fontSize: "1.8rem",
  borderRadius: 10,
  border: "1px solid #CCCCCC",
  backgroundColor: "#ECF0F2",
};

const ResendOtpContainer = {
  paddingBottom: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

class OTPEntry extends Component {
  constructor() {
    super();
    this.state = { otp: "" };
  }

  header = () => {
    const mobileNumber = this.props.authStore.getMobileNumber();
    return (
      <>
        <p className="welcome-text">OTP Verification</p>
        {!this.props.authStore.getOtpErrorMessage() ? (
          <p className="home-text">
            Please enter the OTP received on <b> {mobileNumber}</b>
          </p>
        ) : (
          <div style={ResendOtpContainer}>
            <p className="home-text">
              You have entered the wrong OTP received on <b> {mobileNumber}</b>
            </p>
            <Button
              onClick={() => {
                this.setState({ otp: "" });
                this.props.authStore.generateOtp({
                  mobileNumber: mobileNumber,
                });
              }}
            >
              Resend OTP
            </Button>
          </div>
        )}
      </>
    );
  };

  handleOnOtpChange = (otpVal) => {
    this.setState({ otp: otpVal });
  };

  authenticateOtp = async () => {
    const error = await this.props.authStore.submitOtp(this.state.otp);
    if (error) {
      this.openNotification(
        "error",
        "We are unable to serve your request. Please check if you are connected to the internet or call us for help."
      );
    }
  }

  openNotification = (type, description) => {
    notification[type]({
      message: "Error",
      description: description,
    });
  };

  renderOTPInput = () => {
    const canSubmit = this.state.otp.length === 4;
    const buttonStyle = canSubmit ? "otp-button" : "otp-disabled-button";

    return (
      <>
        {!this.props.authStore.getOtpErrorMessage() ? (
          <>
            <OtpInput
              className="OTPInput"
              shouldAutoFocus={true}
              isInputNum={true}
              value={this.state.otp}
              onChange={this.handleOnOtpChange}
              numInputs={4}
              separator={
                <span>
                  <strong></strong>
                </span>
              }
              inputStyle={OtpInputStyle}
            />

            <Button
              size="large"
              onClick={this.authenticateOtp}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              shape="round"
              type="primary"
              htmlType="submit"
              disabled={!canSubmit}
              className={buttonStyle}
            >
              Submit OTP
            </Button>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        {this.header()}
        {this.renderOTPInput()}
      </>
    );
  }
}

export default observer(OTPEntry);
