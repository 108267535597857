import React, { Component } from "react";
import { observer } from "mobx-react";
import { Card, Typography, Space } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { Price } from "./Price";
const { Text } = Typography;

class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.productId = props.productId;
    this.name = props.name;
    this.parameters = props.parameters;
    this.instruction = props.instruction;
    this.basePrice = props.basePrice;
    this.totalAmount = props.totalAmount;
    this.quantity = props.quantity;
    this.cartId = props.cartId;

    this.store = props.store;
  }

  removeProduct = () => {
    this.store.deleteProductFromCart(this.cartId, this.productId);
  };

  rederParameters = () => {
    if (this.parameters > 1) {
      return (
        <div
          style={{
            width:"100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text className="card-content">Includes {this.parameters} Tests</Text>
          <div size="small" onClick={this.removeProduct}>
            <DeleteTwoTone twoToneColor="#FF0000" style={{fontSize:"1.1rem"}}/>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          width:"100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text className="card-content">Includes 1 Test</Text>
        <div size="small" onClick={this.removeProduct}>
          <DeleteTwoTone twoToneColor="#FF0000" style={{fontSize:"1.1rem"}}/>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Card
        style={{
          width: "95%",
          marginLeft: "2.5%",
          borderRadius: "10px",
          backgroundColor: "#f2f2f2",
        }}
        size="small"
        title={<Text className="card-title">{this.name}</Text>}
        extra={
          <Price
            basePrice={this.basePrice}
            totalAmount={this.totalAmount}
            quantity={this.quantity}
          />
        }
        hoverable="true"
        bodyStyle={{ paddingTop: 0 }}
      >
        <> {this.rederParameters()}</>
        <>
          <Space direction="vertical" size={0}>
            <Text
              style={{ fontFamily: "Poppins-Light", fontSize: "13px" }}
              type="secondary"
            >
              {this.instruction}
            </Text>
          </Space>
        </>
      </Card>
    )
  }
}

export default observer(ProductCard)
