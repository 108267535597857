import React from 'react'
import { observer } from 'mobx-react';

import { List, Avatar, Typography, Tag } from "antd";

const { Text } = Typography;

const humanizeGender = (genderChar) => {
  return genderChar === 'M' ? 'Male' : (genderChar === 'F' ? 'Female' : 'Other');
}

const yearAndMonth = (age) => {
  let yearPart = age.split('-')[0];
  let monthPart = age.split('-')[1];

  if (yearPart === '0') {
    return `Age ${monthPart} months`;
  }

  return `${yearPart} years ${monthPart} months`;
}

function ProfileMemberCard({ patient, salutation, memberStore }) {

  const genderText = humanizeGender(patient.gender);
  const ageText = yearAndMonth(patient.age);

  return (
    <List.Item key={patient.id} style={{ paddingLeft: 15, paddingRight: 10 }}>
      <List.Item.Meta
        title={<Text className="card-title">{salutation + '  ' + patient.name}</Text>}
        description={<Text style={{fontFamily: "Poppins-Light"}}>{ageText}</Text>}
      />
      <Text style={{ color: "#2D94C6", fontFamily: "Poppins-SemiBold" }}>{genderText}</Text>
    </List.Item>
  )
}

export default observer(ProfileMemberCard)