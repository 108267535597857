import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {SettingOutlined} from "@ant-design/icons";

function ServicePageButton({ onClick }) {
  const navigate = useNavigate();
  return (
    <button className="card-content" type='link' onClick={() =>{ navigate("./service")
  onClick()}} >Services</button>
  );
}
export default observer(ServicePageButton);
