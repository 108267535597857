export const RUPPEE_SYMBOL = '₹';

export const MALE = "Male";
export const FEMALE = "Female"

// PAGES
export const PRODUCTS_PAGE = "/products";
export const HOME_PAGE = "/";
export const REPORTS_PAGE = "/reports";
export const PROFILE_PAGE = "/profile";
export const PRODUCT_DETAIL_PAGE = "/productDetails";
export const MEMBERS_PAGE = "/members";
export const CART_PAGE = "/cart";
export const LOGIN_PAGE = "/login";
export const TEST_DETAIL_PAGE = "/testDetails";
export const BRANCH_LIST_PAGE = "/branches";
export const SERVICE_PAGE = "/service";


export const REGISTER_PAGE = "register";
export const ADDRESS_PAGE = "address";

// ---------------------------------------------------------------------

// PRODUCT PAGE
export const PROFILE = "PROFILE";
export const TEST = "TEST";
export const PACKAGES_TITLE = "Packages";
export const TESTS_TITLE = "Tests";

// ---------------------------------------------------------------------

// REPORT PAGE
export const SCHEDULED_ORDERS = "Scheduled Orders";
export const IN_PROGRESS_ORDERS = "Orders in progress ";
export const REPORTS = "Reports";
export const REPORTS_AND_ORDERS = "Reports and Orders";

// ---------------------------------------------------------------------

// ALERT MESSAGES
export const SELECT_TEST_MESSAGE = "Please select your tests.";
export const SELECT_SLOT_MESSAGE = "Please select date/time slot.";
export const SELECT_MEMBER_MESSAGE = "Please select members";

// ---------------------------------------------------------------------

// ERROR MESSAGES
export const ERROR = "error";
export const SPECIAL_CHAR_ERROR = "Numbers/Special characters not Allowed.";
export const SINGLE_CHAR_ERROR = "Single character name is not allowed.";
export const NO_NAME_ERROR = "Please provide name.";
export const NO_SALUTATION_ERROR = "Select salutation.";
export const NO_GENDER_ERROR = "Please select gender.";
export const NO_DOB_ERROR = "Please choose date of birth.";
export const NO_AGE_ERROR = "Please enter age.";
export const NO_YEARS_ERROR = "Please enter years.";
export const NO_MONTHS_ERROR = "Please enter months.";
export const NO_DAYS_ERROR = "Please enter days.";
export const YEAR_RANGE_ERROR = "1 - 99";
export const MONTH_RANGE_ERROR = "1 - 12";
export const DAY_RANGE_ERROR = "10 - 365";


// ---------------------------------------------------------------------

// STATE VALUES

export const INIT = "init";
export const PENDING = "pending";
export const DONE = "done";

