import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Form, InputNumber } from "antd";
import "./Login.css";

const MobileNumberStyle = {
  fontFamily: "Poppins-regular",
  borderRadius: 20,
  backgroundColor: "#ECF0F2",
  borderColor: "#CCCCCC",
  color: "#6C757D",
  borderWidth: 1,
  fontSize: 15,
  padding: 15,
  borderStyle: "solid",
  width: "auto"
};

const FormStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

class OTPGenerate extends Component {
  header = () => {
    return (
      <>
        <p className="welcome-text">Welcome</p>
        <p className="home-text">Your mobile number please</p>
      </>
    );
  };

  renderForm = () => {
    return (
      <Form size="large" name="login_form" style={FormStyle} onFinish={this.onFinish}>
        <Form.Item
          name="mobileNumber"
          rules={[
            {
              required: true,
              message: "Enter your mobile number.",
            },
            {
              pattern: new RegExp(/^[6-9]\d{9}$/gi),
              message: "Enter valid mobile number.",
            },
          ]}>
          <InputNumber autoFocus controls={false} type="number" maxLength={10} size="large" style={MobileNumberStyle} placeholder="your mobile number" />
        </Form.Item>

        <Form.Item>
          <Button shape="round" type="primary" htmlType="submit" disabled={this.props.authStore.isLoading} className="otp-button">
            Get OTP
          </Button>
        </Form.Item>
      </Form>
    );
  };

  render() {
    return (
      <>
        {this.header()}
        {this.renderForm()}
      </>
    );
  }

  onFinish = async (request) => {
    await this.props.authStore.generateOtp(request);
  };
}

export default observer(OTPGenerate);
