import React, { useContext } from "react";
import { Row, Col } from "antd";
import ReportCard from "./ReportCard";
import { StoreContext } from "../../storeContext";
import { observer } from "mobx-react";


const ReportList = ({ reports, title }) => {
  const { reportStore } = useContext(StoreContext);
  return (
    <Row gutter={[16, 16]}>
      {reports.map((report, index) => (
        <React.Fragment key={report.sidCode}>
          <Col span={24}>
            <div>
              <ReportCard reportStore={reportStore} report={report} title={title} />
            </div>
          </Col>
        </React.Fragment>
      ))}
    </Row>
  );
};

export default observer(ReportList);
