import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal, Row, Col, Typography } from 'antd';
import Address from './Address';
import FamilyMemberForm from '../members/FamilyMemberForm';
import ProfileMemberList from './ProfileMemberList';
import CartStore from '../cart/CartStore';
import { StoreContext } from '../../storeContext';
import UniversalHeader from '../page-header/UniversalHeader';

const { Text } = Typography;


class Profile extends Component {

    static contextType = StoreContext;

    constructor(props) {
        super(props);
        this.state = { isReady: false };
    }

    componentDidMount() {
        this.apiProxy = this.context.authStore.apiProxy;
        this.memberListStore = this.context.memberListStore;
        this.memberStore = this.context.memberStore;
        this.userId = this.apiProxy.getUserId();
        this.branchId = this.apiProxy.userContext.branchId;
        this.cartStore = new CartStore({ apiProxy: this.apiProxy, branchId: this.branchId, userId: this.userId })

        this.cartStore.fetchTests();
        this.memberListStore.fetchMembers();

        this.setState({ isReady: true })
    }

    hideModal = () => {
        this.memberStore.setMode('view');
    }

    renderAddMemberForm = () => {
        return (
            <Modal title="Add Member" visible={this.memberStore.mode === "add"} footer={null} onCancel={this.hideModal} maskClosable={false}>
                <FamilyMemberForm memberStore={this.memberStore} cartStore={this.cartStore} />
            </Modal>
        )
    }

    renderPage = () => {
        if (this.memberStore === undefined) {
            return <></>
        }

        const allMembers = this.memberListStore.members;
        return (
            <>
                <Row gutter={[8, 8]}>
                    <Col xs={24} xl={12}>
                        <Address apiProxy={this.apiProxy} />
                    </Col>
                    <Col xs={24} xl={12}>
                        <ProfileMemberList allMembers={allMembers} memberStore={this.memberStore} cartStore={this.cartStore} />
                        {this.memberStore.mode === "add" && this.renderAddMemberForm()}
                    </Col>
                </Row>
            </>
        )
    }

    renderTitle = () => {
        return (
            <Text className="page-header-title" >My profile</Text>
        )
    }

    render() {
        return (
            <>
                <UniversalHeader title={this.renderTitle()} />
                <div className='body-style' style={{padding: "3%"}}>
                    {this.state.isReady && this.renderPage()}
                </div>
            </>
        )
    }
}

export default observer(Profile);