import React, { useState } from "react";
import "../members/MemberDetails.css";
import {
  DatePicker,
  Form,
  Select,
  Input,
  Radio,
  Button,
  notification,
  message,
  Space,
} from "antd";

import {
  SPECIAL_CHAR_ERROR,
  SINGLE_CHAR_ERROR,
  NO_DOB_ERROR,
  NO_GENDER_ERROR,
  NO_NAME_ERROR,
  NO_SALUTATION_ERROR,
} from "../../label.EN";

const { Option } = Select;

const Center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const GenderStyle = {
  display: "flex",
  justifyContent: "space-evenly",
  columnGap: "2vw",
};

const GenderButtonStyle = { borderRadius: "10vw" };

const SalutationStyle = {
  display: "flex",
  flexDirection: "row",
  width: "Auto",
};

const SubmitButtonStyle = {
  borderRadius: "10px",
  background: "#f27906",
  color: "white",
};

const GenderTypes = ["Male", "Female", "Other"];
const dateFormatList = ["DD/MM/YYYY"];

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const failureNotification = (error) => {
  const args = {
    message: "Unable to Complete Registration ",
    description: error.help,
    duration: 0,
    type: "error",
  };
  notification.open(args);
};

export default function RegistrationForm({ registrationStore }) {
  const [form] = Form.useForm();

  const canSubmit = registrationStore.isInit;

  const renderErrorMessages = (message) => {
    return <p style={{ marginLeft: "12%" }}>{message}</p>;
  };
  const [maleDisabled, setMaleDisabled] = useState(false);
  const [femaleDisabled, setFemaleDisabled] = useState(false);

  const onFinish = async (request) => {
    await registrationStore.addUser(request);

    if (registrationStore.isError) {
      failureNotification(registrationStore.message);
    }

    if (registrationStore.isDone) {
      message.success("Thank You. Please provide your address to reach you.");
    }
  };

  return (
    <Form id="my-form" form={form} onFinish={onFinish}>
      <div style={SalutationStyle}>
        <Form.Item
          name="salutationId"
          rules={[
            {
              required: true,
              message: renderErrorMessages(NO_SALUTATION_ERROR),
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Salutation"
            onChange={(value) => {
              if (value === "1" || value === "5") {
                form.setFieldsValue({
                  gender: "M",
                });
                setFemaleDisabled(true);
                setMaleDisabled(false);
              } else if (value === "2" || value === "4") {
                form.setFieldsValue({
                  gender: "F",
                });
                setFemaleDisabled(false);
                setMaleDisabled(true);
              } else {
                setFemaleDisabled(false);
                setMaleDisabled(false);
              }
            }}
          >
            <Option value="1">Mr.</Option>
            <Option value="2">Mrs.</Option>
            <Option value="4">Ms.</Option>
            <Option value="5">Master.</Option>
            <Option value="6">Baby.</Option>
            <Option value="7">Dr.</Option>
            <Option value="8">B/O.</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: renderErrorMessages(NO_NAME_ERROR) },
            { min: 2, message: renderErrorMessages(SINGLE_CHAR_ERROR) },
            {
              pattern: new RegExp(/^[a-zA-Z\s]*$/),
              message: renderErrorMessages(SPECIAL_CHAR_ERROR),
            },
          ]}
        >
          <Input
            style={{
              width: "14rem",
              marginLeft: "1vw",
            }}
            onInput={e => e.target.value = e.target.value.toUpperCase()}
            className="input-box"
            size="large"
            placeholder="Enter your name"
          />
        </Form.Item>
      </div>

      <Form.Item
        name="birthDate"
        label="DOB"
        tooltip="Enter your DOB in DD/MM/YYYY format"
        rules={[{ required: true, message: renderErrorMessages(NO_DOB_ERROR) }]}
      >
        <DatePicker
          style={{
            width: "100%",
            marginLeft: "0.3vw",
            justifyContent: "center",
          }}
          required
          className="input-box"
          size="large"
          placeholder="DD/MM/YYYY"
          format={dateFormatList}
          disabledDate={(current) => {
            return current && current.valueOf() >= yesterday;
          }}
        />
      </Form.Item>

      <Form.Item
        name="gender"
        rules={[
          { required: true, message: renderErrorMessages(NO_GENDER_ERROR) },
        ]}
      >
        <Radio.Group
          required
          size="large"
          buttonStyle="solid"
          style={GenderStyle}
        >
          {GenderTypes.map((gender, index) => (
            <Space>
              <Radio.Button
                key={index}
                disabled={
                  gender === "Male"
                    ? maleDisabled
                    : gender === "Female"
                    ? femaleDisabled
                    : false
                }
                style={GenderButtonStyle}
                value={gender[0]}
              >
                {gender}
              </Radio.Button>
            </Space>
          ))}
        </Radio.Group>
      </Form.Item>

      <div style={Center}>
        <Form.Item>
          <Button
            htmlType="submit"
            disabled={!canSubmit}
            style={SubmitButtonStyle}
            size={"large"}
          >
            Next
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
