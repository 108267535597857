import { message, Card, Upload, Tooltip, Typography, Button } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";
import { assetHost } from "../../services/APIEndpoints";
import moment from "moment";
import { StoreContext } from "../../storeContext";

const { Text } = Typography;

const UPLOAD_PRESCRIPTION =
  "Please provide prescription in PNG/JPEG/JPG/PDF format,The max permissible prescription file size is 5MB.";

function UploadFiles({ apiProxy, showMembersFn, showTestsFn, showTimeFn }) {
  let { authStore } = useContext(StoreContext);
  let id = "a77ca40a-ba64-47a7-a559-a6944921fa9i";
  let userId = apiProxy.getUserId();
  let uploadUrl = assetHost + userId;
  const [fileList, setFileList] = useState(authStore.uploadedFiles);

  const beforeUpload = (file) => {
    if (fileList.length == 4) {
      message.error("You can only upload 5 files per booking");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.typ === "image/jpeg";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF/JPEG file!");
      return Upload.LIST_IGNORE;
    }

    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("File size must be less than 5MB!");
      return Upload.LIST_IGNORE;
    }

    if (isJpgOrPng && isLt2M) {
      setFileList([...fileList, file]);
      authStore.addFile(file);
    }
    return false;
  };

  const uploadButton = () => {
    return (
      <div>
        <CloudUploadOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );
  };
  const handleRemove = (e) => {
    let files = fileList.filter((file) => {
      return file.uid !== e.uid;
    });
    setFileList(files);
    authStore.removeFile(e);
  };
  const upload = async () => {
    let url = assetHost + id;
    let files = authStore.uploadedFiles;
    var formdata = new FormData();
    files.map((file, index) => {
      formdata.append(index + file.name, file, file.name);
    });
    console.log(files);
    try {
      let response = await apiProxy.asyncUploadPost(url, formdata);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  const SearchStyle = {
    height:"30px",
    minHeight:"30px",
    maxHeight:"30px",
    margin: "2.4vh 7% 1vh 8%",
    borderRadius: "0 !important",
  };
  const prevPage = () => {
    showMembersFn(true)
    showTestsFn(false)
    showTimeFn(false)
  }
  return (
    <>
      <div>
        <Card
          className="cart-card-style"
        >
          <div style={SearchStyle}><Text className="card-header">Prescription</Text></div>
          <div className="cart-card-style-div">
            <Tooltip title={UPLOAD_PRESCRIPTION} >
              <Upload
                size="small"
                fileList={fileList}
                accept=".png,.pdf,.jpeg,.jpg"
                listType="picture-card"
                onRemove={handleRemove}
                beforeUpload={beforeUpload}
              >
                {uploadButton()}
              </Upload>
            </Tooltip>
            {/* <Button style={{ borderRadius: "15px", backgroundColor: "#8BC34A", color: "white", fontWeight: "700",position:"absolute",bottom:25,left:55 }} onClick={() => prevPage()}>Prev </Button> */}
         </div>
        </Card>
      </div>
    </>
  );
}
export default UploadFiles;
