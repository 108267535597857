import { makeObservable, observable, computed, action } from "mobx";
import { apiHost } from "../../services/APIEndpoints";
import { sonicSearchQuery } from "../../services/Queries";

const PENDING = "pending";
const DONE = "done";
const ERROR = "error";

const EMPTY_MESSAGE = { status: "", help: "" };

export default class TestSearchStore {
    productList = [];

    state = PENDING;
    message = EMPTY_MESSAGE;

    constructor(props) {
        this.apiProxy = props.apiProxy;
        this.branchId = props.branchId;

        makeObservable(this, {
            state: observable,
            productList: observable,

            isLoading: computed,
            isError: computed,
            isDone: computed,

            search: action,
        });
    }

    get isLoading() {
        return this.state === PENDING;
    }

    get isError() {
        return this.state === ERROR;
    }

    get isDone() {
        return this.state === DONE;
    }

    search = async (key) => {

        const searchCriteria = {
            criteria: {
                branchId: this.branchId,
                product: 'ALL',
                searchKey: key
            },
        };

        this.productList = [];
        this.state = PENDING;

        try {
            const response = await this.apiProxy.query(apiHost,sonicSearchQuery,searchCriteria);
            const data = await response.json();

            if (data.data.searchProducts.error !== null) {
                this.state = DONE;
                return;
            }

            this.productList = data.data.searchProducts.products;
            this.state = DONE;

        } catch (e) {
            console.log(e);
        }
    };
}
