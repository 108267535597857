import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../login/Login";
import PrivateRouter from "../routes/PrivateRouter";
import HomePage from "../login/homePage/HomePage";
import ProductDisplay from "../../pages/product-page/ProductDisplay";
import MemberList from "../members/MemberList";
import Cart from "../cart/Cart";
import ReportDisplay from "../../pages/report-page/ReportDisplay";
import Profile from "../profile/Profile";
import ProductDetails from "../product-display/ProductDetails";
import TestDetails from "../product-display/TestDetails";
import { HOME_PAGE, LOGIN_PAGE, CART_PAGE, MEMBERS_PAGE, PRODUCT_DETAIL_PAGE, PROFILE_PAGE, REPORTS_PAGE, PRODUCTS_PAGE, TEST_DETAIL_PAGE, BRANCH_LIST_PAGE, SERVICE_PAGE } from "../../label.EN";
import BranchDisplay from "../../pages/branch-page/BranchDisplay";
import { ServicePage } from "../login/homePage/ServicePage";

export default function AppRoutes() {
    return (
        <Routes>
            <Route element={<PrivateRouter />}>
                <Route path={HOME_PAGE} element={<HomePage />} />
                <Route path={PRODUCTS_PAGE} element={<ProductDisplay />} />
                <Route path={PRODUCT_DETAIL_PAGE} element={<ProductDetails />}></Route>
                <Route path={MEMBERS_PAGE} element={<MemberList />} />
                <Route path={CART_PAGE} element={<Cart />}></Route>
                <Route path={REPORTS_PAGE} element={<ReportDisplay />} />
                <Route path={PROFILE_PAGE} element={<Profile />} />
                <Route path={TEST_DETAIL_PAGE} element={<TestDetails />} />
            </Route>
            <Route path={LOGIN_PAGE} element={<Login />} />
            <Route path={BRANCH_LIST_PAGE} element={<BranchDisplay />} />
            <Route path={SERVICE_PAGE} element={<ServicePage/>}/>
        </Routes>
    )
}