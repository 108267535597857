export const cartTitleStep = {
    fontSize: "0.9rem",
    color: "White",
    fontWeight: "700",
    backgroundColor: "#2d94c6",
    borderRadius: "4px",
    paddingLeft: "4px",
    paddingRight: "4px"
  };

  export const AmountTagStyle = {
    borderRadius: "15px",
    height: "38px",
    fontFamily: "Poppins-medium",
    fontSize: "15px",
    width: "116px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  
  export const CartStyle = {
    marginRight: "5%",
    fontSize: "28px",
  };
  export const mainHeaderStyle = {
    position: "sticky",
    top: 65,
    backgroundColor: "white",
    zIndex: 3,
  };
  export const pageHeaderStyle = {
    position: "sticky",
    zIndex: 3,
    padding: "10px 10px 25px 10px",
  };
  export const cartTitle = {
    color: "rgb(67,21,67)",
    fontWeight: "700",
  };