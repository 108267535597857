import React from "react";
import { Col, Row } from "antd";
import BranchCard from "./BranchCard";

const BranchList = ({ branches }) => {
    return (
        <>
            <Row gutter={[16, 16]}>
                {branches.map(
                    (branch, index) =>
                    (
                        <React.Fragment key={branch.id}>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6} >
                                <div >
                                    <BranchCard branch={branch} />
                                </div>
                            </Col>
                        </React.Fragment>
                    )
                )}
            </Row>
        </>
    )
}

export default BranchList
