import {
  Card,
  Row,
  Col,
  Radio,
  DatePicker,
  TimePicker,
  Typography,
  Slider,
  Input,
  Button,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Cart.css"
import Location from "../../images/location.png";
const { Text } = Typography;
const format = "HH:mm";

const radioStyle = {
  textAlign: "center",
  width: "34%",
  marginLeft: " 15px",
  marginTop: "10px",
  borderRadius: "5px",
  fontSize: ".8rem",
  fontWeight:"bolder",
  border: "1.5px solid #2d94c6",
  zIndex: 0,
};

const todayColor = {
  backgroundColor:"#2d94c6",
  fontWeight:"bolder",
  textAlign: "center",
  width: "34%",
  marginLeft: " 15px",
  marginTop: "10px",
  borderRadius: "5px",
  fontSize: ".8rem",
  fontWeight:"bolder",
  border: "1.5px solid #2d94c6",
  zIndex: 0,
};
const Flex={
  display:"flex",
  marginTop:"55px",
  flexDirection:"row",
  justifyContent:"space-evenly"
}
const Evenly={
  width:"30%",
  margin:"auto"
}
const EvenlyConfirm={
  width:"100%",
  display:"flex",
  justifyContent:"center"
}
const confirmButtonStyle = {
  width: "180px",
  height: "36px",
  fontSize: "1rem",
  fontWeight: "400",
};

const rowStyle = {
  marginTop: "2%",
  marginBottom: "1%",
};

const pickerTextStyle = {
  color: "#2D94C6",
  fontFamily: "Poppins-Regular",
  fontSize: "12px",
  marginLeft: "4%",
};

const suggestedSlotStyle = {
  color: "black",
  fontFamily: "Poppins-Regular",
  fontSize: "14px",
};
const suggestedSlotStyleLocation = {
  color: "black",
  display:"flex",
  fontFamily: "Poppins-Regular",
  fontSize: "14px",
  marginTop: "5%",
  marginLeft:"5%"
};
const datePickerStyle = {
  marginLeft: "3%",
  borderRadius: "15px",
  border: "#6C757D solid",
};

const timePickerStyle = {
  marginLeft: "3%",
  borderRadius: "15px",
  border: "#6C757D solid",
};


const getFormattedDate = (date) => {
  return date.format("DD-MMM-YY");
};


export default function TimeSlot({ cartStore, handleOnBooking, showConfirm, setShowConfirmation, userContext, showMembersFn, showTestsFn, showTimeFn }) {
  
  let alreadySelectedSlot = moment(cartStore.scheduledTime).utc();
  console.log("alreadySelectedSlot",alreadySelectedSlot);
  alreadySelectedSlot = alreadySelectedSlot.isValid()
    ? alreadySelectedSlot
    : moment();

  alreadySelectedSlot = alreadySelectedSlot.isValid()
    ? alreadySelectedSlot
    : moment();

  let currentHour = moment().hour();
  const [inputValue, setInputValue] = useState(
    currentHour >= 16 ? 6 : currentHour + 2
  );
  const [slotMin, setSlotMin] = useState(
    currentHour >= 16 ? 6 : currentHour + 2
  );

  const [slotMax, setSlotMax] = useState(18);
  const [dateSlot, setDateSlot] = useState(0);
  console.log("dateSlot",dateSlot);
  const [date, setDate] = useState(alreadySelectedSlot);

  const [selectedDateFor, setselectedDateFor] = useState(currentHour>=16?moment().date()+1:moment().date());
  const [time, setTime] = useState(moment);

  const[today,setToday] = useState("")

  const { Text, Paragraph } = Typography;
  useEffect(() => {
    if (currentHour >= 18) {
      setSlotMin(6);
      setInputValue(6);
    }
    setDate(moment( suggestedDates[0]));
    setSelectedSlot();
    cartStore.selectedDate = suggestedDates[0];
  }, []);

  let suggestedDates = [];
  let count = 0;
  for (let i = 0; count < 6; i++) {
    let date = moment().add(i, "days");
    if (i == 0 && currentHour >= 16) {
      continue;
    }
    count++;
    suggestedDates.push(date);
  }
  let suggestedSlots = [];
  for (let i = 0; i < 5; i++) {
    let slot = moment()
      .add(0, "days")
      .add(i + 1, "hours");
    suggestedSlots.push(slot);
  }
  const prevPage = () => {
    showMembersFn(false)
    showTestsFn(true)
    showTimeFn(false)
  }
  const handleDateChange = (e) => {
    const index = e.target.value;
    
    setDateSlot(index);
    setToday("")

    const selectedDate = suggestedDates[index];
    setselectedDateFor(moment(selectedDate).format("DD"))
    let minValue = 6;

    if (
      moment(selectedDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
    ) {
      minValue = currentHour >= 16 ? 18 : currentHour + 2;

    }

    setSlotMin(minValue ? minValue : currentHour);
    setInputValue(minValue ? minValue : currentHour);
    if (inputValue < currentHour) {
      setSlotMin(minValue);

      setInputValue(minValue);
    } else {
      setSlotMin(minValue);
      setInputValue(minValue);
    }

    setDate(moment(selectedDate));
    setSelectedSlot();
    cartStore.selectedDate = selectedDate;
  };
  const setSelectedSlot = () => {
    const selectedTime = date.startOf("day").add(inputValue, "hours");
    console.log("selectedTime".selectedTime);
    setTime(selectedTime);
    cartStore.selectedSlot = selectedTime;
  };
  const onChange = (newValue) => {
    setInputValue(newValue);
    setSelectedSlot();
  };
 
  const getSlotValue = (value) => {
    let period = "AM";
    if (value > 11) {
      period = "PM";
    }
    let hour = Math.floor(value % 12);
    if (hour === 0) {
      hour = 12;
    }
    let minutes = "00";
    if (value % 1 != 0) {
      minutes = "30";
    }
    return hour + ":" + minutes + " " + period;
  };

  const getMarks = () => {
    let marksObj = {};
    for (let i = slotMin; i <= slotMax; ) {
      marksObj[i] = {
        style: {
          inlineSize: "40px",
          overflowwrap: "break-word",
        },
        label: <p>{getSlotValue(i)}</p>,
      };
      i = i + 3;
    }
    return marksObj;
  };

  return (<>
    <Card
      className="cart-card-style"
      style={{ minHeight:"530px"}}
    >
        <div style={suggestedSlotStyleLocation}>
          <img style={{width:"20px", height:"20px",opacity:".8"}} src={Location}></img>
          <Typography
            style={{ marginLeft: "5px", fontWeight: "600", fontSize: "0.9rem" }}
          >
            {userContext.address}
          </Typography>
        </div>
      <Row style={{ marginTop: "10px",display:"flex",justifyContent:"center",alignItems:"center" }}>
        <div >
          <center>
          <Row gutter={[8, 8]} >
            <Radio.Group
              defaultValue={0}
              name="Date"
              value={dateSlot}
              buttonStyle="solid"
              onChange={handleDateChange}
            >
              {suggestedDates.map((slot, index) => {
                return <React.Fragment key={index}>
                  <Radio.Button style={slot.date() == selectedDateFor ?todayColor:radioStyle} value={index}>
                    {slot.date() === moment().date()
                      ? "Today":slot.date() === moment().date()+1?"Tomorrow"
                      : getFormattedDate(slot)}
                  </Radio.Button>
                </React.Fragment>
              })}
            </Radio.Group>
          </Row>
          </center>
        </div>
      </Row>
     
  
      <div style={{ marginTop: "30px", width: "100%" }}>
        <Row  style={{ marginTop: "10px", width: "100%",display:"flex",justifyContent:"center"}}>
          <Col span={17 }  style={{ marginTop: "10px", width: "100%"}}>
            <Slider
            style={{ marginTop: "10px", width: "100%"}}
              min={slotMin}
              max={slotMax}
              step={0.5}
              marks={getMarks()}
              tooltip={{
                open: false,
              }}
              onChange={onChange}
              value={typeof inputValue === "number" ? inputValue : 0}
            />
          </Col>
        </Row>
      </div>
      <Col span={7} style={{margin:"auto",alignItems:"center",display:"flex",justifyContent:"center",marginTop:"30px"}}>
            <Input
              style={{
                width: "75%",
                minWidth:"90px",
                fontSize: ".85rem",
                backgroundColor: "#2d94c6",
                color: "white",
                fontWeight:"bolder",
                textAlign:"center",
              }}
              disabled
              value={getSlotValue(inputValue)}
            />
          </Col>
  
      
      <div>
   <div style={Flex}>
  {/* <div style={Evenly}>
     <Button style={{ borderRadius: "15px", backgroundColor: "#8BC34A", color: "white", fontWeight: "700",justifyContent:"start" }} onClick={() => prevPage()}>Prev </Button>
     </div> */}
    <div  style={EvenlyConfirm}> <Button
       shape="round"
       className="comman-button"
       onClick={() => showConfirm()}
       style={confirmButtonStyle}
       key="1"
     >
       Confirm Booking
     </Button></div>
   </div>
        <Paragraph  style={{ margin: 0 ,padding:"20px",fontSize:".9rem"}}>
        After confirming, our phlebotomist will visit your address to collect the samples at the scheduled time. Please pay the order amount to our executive during sample collection. </Paragraph>
      </div>
      
    </Card>
    </>
  );
}

