import React, { useContext, useEffect } from "react";
import { Row, Col } from 'antd';
import BranchSearch from "../../components/branch-display/BranchSearch";
import BranchList from "../../components/branch-display/BranchList";
import { StoreContext } from "../../storeContext";
import { observer } from "mobx-react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import './BranchDisplay.css'
import UniversalHeader from "../../components/page-header/UniversalHeader";

const PageTitle = ({ search, branchStore }) => {
    return (
        <Row gutter={12}>
            {search && (
                <Col>
                    <ArrowLeftOutlined onClick={(() => {
                        if (search) {
                            branchStore.setSearch(false)
                            branchStore.fetchBranches()
                        }
                    }
                    )} />
                </Col>
            )}
            <Col>
                <span className="ant-page-header-heading-title page-header-title">Branches</span>
            </Col>
        </Row>
    )
}

const BranchDisplay = () => {
    const { branchStore } = useContext(StoreContext)
    const branches = branchStore.getBranches();
    const suggestions = branchStore.getBranchSuggestions()
    const search = branchStore.getSearch();

    useEffect(() => {
        branchStore.fetchBranchSearchItems();
        branchStore.fetchBranches()
    }, [branchStore])

    const renderTitle = () => (
        <PageTitle search={search} branchStore={branchStore} />
    )

    const renderPageExtra = () => (
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <BranchSearch branchStore={branchStore} suggestions={suggestions} />
            </Col>
        </Row>
    )

    return (
        <div>
            <UniversalHeader
                title={renderTitle()}
                extra={renderPageExtra()}
            />
            <div className="body-style">
                <BranchList branches={branches} />
            </div>
        </div>
    )
}

export default observer(BranchDisplay)



