import React, { Component } from "react";
import { observer } from "mobx-react";
import { Navigate } from "react-router-dom";
import "./Register.css";
import { CART_PAGE, PRODUCTS_PAGE } from "../../label.EN";
import { StoreContext } from "../../storeContext";
import AddressForm from "./AddressForm";
import AddressStore from "./AddressStore";


class RegisterAddress extends Component {

  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.addressStore = new AddressStore({ apiProxy: props.apiProxy })
  }

  renderPage = () => {

    if (this.addressStore.isDone) {
      this.context.authStore.nextPage = PRODUCTS_PAGE;
      return <Navigate to={CART_PAGE} />;
    }

    return <AddressForm addressStore={this.addressStore} />;
  }

  render() {
    return (
      <>
        <p className="welcome-text">Your Address </p>
        <p className="home-text">To reach you from our nearest branch</p>
        {this.renderPage()}
      </>
    );
  }
}

export default observer(RegisterAddress);
