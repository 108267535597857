import { action, computed, makeObservable, observable } from "mobx";

import { patientDataQuery, selectPatientQuery } from "../../services/Queries";
import { apiHost } from "../../services/APIEndpoints";
import { convertAgeToBirthDate, getDOB } from "../Util";

const PENDING = 'pending';
const DONE = 'done';
//const ERROR = 'error';

//const EMPTY_MESSAGE = { status: "", help: "" };
//const ERROR_MESSAGE = { status: "error", help: "Unable to fetch the Members. The Service is unavailable. Please try after sometime." };

const CART_VIEW_MODE = "view";
const CART_ADD_MODE = "add";
const CART_SELECT_MODE = "select";

export default class MemberStore {
  state = DONE;
  mode = CART_VIEW_MODE;

  constructor({ apiProxy, memberListStore }) {
    this.apiProxy = apiProxy;
    this.memberListStore = memberListStore;

    makeObservable(this, {
      state: observable,
      mode: observable,

      canAdd: computed,
      canSelect: computed,
      canFilter: computed,

      canSaveAddition: computed,
      canSaveSelection: computed,

      setMode: action,
      addMember: action,
      updateMembers: action,

    });
  }

  get canAdd() {
    return this.state === DONE && this.mode === CART_VIEW_MODE;
  }

  get canSelect() {
    return this.state === DONE && this.mode === CART_VIEW_MODE;
  }

  get canFilter() {
    return this.state === DONE && this.mode === CART_VIEW_MODE;
  }

  get canSaveAddition() {
    return this.state === DONE && this.mode === CART_ADD_MODE;
  }

  get canSaveSelection() {
    return this.state === DONE && this.mode === CART_SELECT_MODE;
  }

  setMode = (givenMode) => {
    this.mode = givenMode;
  }

  addMember = async (formData) => {

    this.state = PENDING;
    var DOB = getDOB(formData);

    var formDataInput = {
      userId: this.apiProxy.getUserId(),
      birthDate: DOB,
      salutationId: parseInt(formData.salutationId),
      gender: formData.gender,
      name: formData.name
    }

    let variables = { input: formDataInput };

    const response = await this.apiProxy.query(
      apiHost,
      patientDataQuery,
      variables
    );

    const result = await response.json();
    console.log("result", result)
    const errorMessage = result.data.addMember.error;
    if (errorMessage) {
      this.state = DONE;
      return;
    }

    await this.memberListStore.fetchMembers();
    this.state = DONE;
  }

  updateMembers = async (data) => {
    this.state = PENDING;

    let input = []

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      input.push({ patientId: item.patient.id, selected: item.patient.isSelected })

      console.log("updating member", item.patient.isSelected)
    }

    let variables = { input: input };

    const response = await this.apiProxy.query(apiHost, selectPatientQuery, variables);
    const result = await response.json();

    const errorMessage = result.data.insertPatients.error;
    if (errorMessage) {
      this.state = DONE;
      return;
    }

    await this.memberListStore.fetchMembers();
    this.state = DONE;
  }

}

