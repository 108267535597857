import React from "react";
import { Form, Input, Button, notification, message } from "antd";
const { TextArea } = Input;

const Center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const SubmitButtonStyle = {
  borderRadius: "10px",
  background: "#f27906",
  color: "white",
};

export default function AddressForm({ addressStore }) {
  const [form] = Form.useForm();

  const canSubmit = addressStore.isInit;

  const failureNotification = (error) => {
    const args = {
      message: "Unable to add your address now",
      description: error.help,
      duration: 0,
      type: "error",
    };
    notification.open(args);
  };

  const onFinish = async (request) => {
    await addressStore.saveAddress(request);

    if (addressStore.isError) {
      failureNotification(addressStore.message);
    }

    if (addressStore.isDone) {
      message.success("Thanks, Welcome to Numed.");
    }
  };

  return (
    <Form
      autoComplete="off"
      form={form}
      onFinish={onFinish}
      style={{ width: "100%" }}
    >
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: "We need your address to collect samples",
          },
        ]}
      >
        <TextArea
          rows={3}
          style={{ width: "100%" }}
          placeholder="Your address."
        />
      </Form.Item>

      <Form.Item
        name="pincode"
        rules={[
          {
            required: true,
            message:
              "We need your area pincode to reach you from our nearest branch.",
          },
          {
            pattern: new RegExp(/^(\d{6})$/),
            message: "Pincode is not Valid",
          },
        ]}
      >
        <Input
          type="text"
          maxLength={6}
          style={{ padding: "12px" }}
          className="register-input-box"
          placeholder="Your area pincode"
        />
      </Form.Item>

      <Form.Item style={Center}>
        <Button
          className="register-submit-btn"
          type="primary"
          size="large"
          disabled={!canSubmit}
          style={SubmitButtonStyle}
          htmlType="submit"
        >
          {" "}
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
