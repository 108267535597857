import React, { useState, useEffect } from "react";
import ServiceImg from "../../../../images/test.jpg";
import { OrangeCard } from "./OrangeCard";
import useShowLoginModal from "./useShowLoginModal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  blueDotsStyle,
  serviceImageStyle,
  serviceTitleStyle,
  serviceDescriptionStyle,
  readMoreStyle,
  readMoreTextStyle,
  rightMenuStyle,
  rightMenuItemStyle,
  leftMenuItemStyle,
  serviceImageBoxStyle,
} from "./ServicesCss";
import Accred from "../../../../images/accred.png";
import LeftArrow from "../../../../images/arrow-1.png";
import RightArrow from "../../../../images/arrow.png";
import Happy from "../../../../images/happy.png";
import Network from "../../../../images/network.png";
import Wide from "../../../../images/wide.png";

const responsiveStyles = {
  "@media (max-width: 1250px)": {
    flexDirection: "row",
  },
  "@media (max-width: 750px)": {
    flexDirection: "row",
  },
  "@media (max-width: 600px)": {
    flexDirection: "row",
  },
};

const OrangeCardContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  padding: "0% 12%",
  marginTop: "-170px",
  gap: "20px",
  position: "relative",
  ...responsiveStyles,
});

export const Services = () => {
  const showLoginModal = useShowLoginModal();

  const calculateVisibleCards = (width) => {
    if (width <= 750) {
      return 1;
    } else if (width <= 1050) {
      return 2;
    } else if (width <= 1250) {
      return 3;
    }
    return 4;
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(calculateVisibleCards(screenWidth));

  const handleLoginClick = () => {
    showLoginModal();
  };

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      setScreenWidth(width);
      setVisibleCards(calculateVisibleCards(width));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const serviceData = [
    {
      image: Accred,
      title: "ACCREDITATIONS",
      desc: "NABL Accredited ICMR approved",
    },
    {
      image: Network,
      title: "EXPANSIVE NETWORK",
      desc: "We are near you, Serving now with 400+ branches",
    },
    {
      image: Wide,
      title: "WIDE-RANGE",
      desc: "More than 1000 tests and 100+ comprehensive packages",
    },
    {
      image: Happy,
      title: "HAPPY CUSTOMERS",
      desc: "More than 2 million customers and 5 million+ tests",
    },
  ];

  const renderCarouselItems = () => {
    const startIndex = carouselIndex % serviceData.length;
    const endIndex = (carouselIndex + visibleCards) % serviceData.length;

    if (endIndex > startIndex) {
      return serviceData.slice(startIndex, endIndex);
    } else {
      return [...serviceData.slice(startIndex), ...serviceData.slice(0, endIndex)];
    }
  };

  const handleCarouselButton = (direction) => {
    if (direction === "next") {
      setCarouselIndex((prevIndex) => prevIndex + 1);
    } else if (direction === "prev") {
      setCarouselIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : serviceData.length - 1));
    }
  };

  return (
    <div style={{ padding: "5% 0%" }}>
      <h1
        style={{
          fontSize: "28px",
          fontWeight: "700",
          color: "#3396c8",
          padding: "2% 15%",
        }}
      >
        OUR SERVICES
      </h1>

      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: screenWidth <= 1250 ? "column" : "row",
        }}
      >
        <div style={serviceImageBoxStyle}>
          <img src={ServiceImg} alt="Service" style={serviceImageStyle} />
        </div>
        <div
          style={{
            width: screenWidth <= 1250 ? "70%" : "50%",
            display: "flex",
            flexDirection: "column",
            padding: screenWidth <= 1250 ? "50px" : "50px 90px 0px 50px",
            flex: "1",
            justifyContent: screenWidth <= 1250 ? "center" : "initial",
            alignItems: screenWidth <= 1250 ? "center" : "initial",
            margin: screenWidth <= 1250 ? "auto" : "auto",
          }}
        >
          <div style={{width:screenWidth <= 1250 ? "75%" : "auto"}}>
            <h1 style={serviceTitleStyle}>PET -</h1>
            <h1 style={serviceTitleStyle}>PULMONARY FUNCTION TEST</h1>
          </div>
          <p style={serviceDescriptionStyle}>
            Pulmonary function tests (PFTs) are a group of tests that measure how well your lungs work.
          </p>
          <div style={readMoreStyle}>
            <div className="read-more" style={readMoreTextStyle} onClick={handleLoginClick}>
              <div className="read-more-text">BOOK NOW</div>
            </div>
          </div>
          <div style={rightMenuStyle}>
            <div style={leftMenuItemStyle}>
              <img src={LeftArrow} alt="Down Arrow" />
            </div>
            <div style={rightMenuItemStyle}>
              <img src={RightArrow} alt="Down Arrow" />
            </div>
          </div>
        </div>
      </div>
      <div style={blueDotsStyle}></div>

      <OrangeCardContainer>
        {visibleCards < 4 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
              border: "1px solid gray",
              borderRadius: "50%",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => handleCarouselButton("prev")}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        )}
        {renderCarouselItems().map((item, index) => (
          <OrangeCard key={index} Image={item.image} Title={item.title} Desc={item.desc} />
        ))}
        {visibleCards < 4 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
              border: "1px solid gray",
              borderRadius: "50%",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => handleCarouselButton("next")}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        )}
      </OrangeCardContainer>
    </div>
  );
};
