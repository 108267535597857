import React from "react";
import { useNavigate } from "react-router-dom";
import { notification, Button, Row, Col } from "antd";
import { CheckCircleTwoTone,LeftCircleOutlined} from "@ant-design/icons";
import shoppingCart from "../../images/shoppingCartWhite.svg";
import UniversalHeader from "../page-header/UniversalHeader";
import { PRODUCTS_PAGE,DONE } from "../../label.EN";
import { observer } from "mobx-react";

const buttonStyle = {
  width: "90px",
  height: "35px",
};

const DetailsHeader = (props) => {
  const navigate = useNavigate();
  const cartProducts = props.cartProducts;
  const testId = props.testId;
  const name = props.name;
  const productStore = props.productStore;

  const openNotificationWithIcon = (type, title) => {
    notification[type]({
      message: `${title} added to cart`,
    });
  };

  const handleOnProductClick = async () => {
    let status=await productStore.addProductToCart(testId);
    let added =true; 
    let message=''
    if(status==0){
        message="Added"
    } 
    else if(status==1){
    message="Deleted repeated tests/profile, Added"}
    else {
      added=false 
      openNotification('success', 'topRight', status)
    }
    if(added){
    props.title === "Package" ?
      openNotification('success', 'topRight', message+" "+"profile")
      :
      openNotification('success', 'topRight',  message+" "+"test")
    }
    navigate("/products")
  };

  const openNotification = (type, placement, description) => {
    notification[type]({
      description: description,
      placement,
      duration: 6
    });
    productStore.setState(DONE);
  };



  const renderTitle = () => (
    <Row gutter={12}>
      <Col>
        <LeftCircleOutlined
          style={{ color: "white" }}
          onClick={() => {
            navigate(PRODUCTS_PAGE);
          }}
        />
      </Col>
      <Col>
        <span style={{ color: "white" }}>{name}</span>
      </Col>
    </Row>
  );

  const renderPageExtra = () => (
    <>
      {cartProducts.has(testId) ? (
        <CheckCircleTwoTone
          style={{ fontSize: "30px" }}
          twoToneColor="#52c41a"
        />
      ) : (
        <Button
          style={buttonStyle}
          shape="round"
          className="comman-button"
          onClick={() => handleOnProductClick()}
          icon={
            <img
              style={{ marginRight: "7px" }}
              height={20}
              src={shoppingCart}
              alt="shopping-cart-icon"
            />
          }
        >
          Add
        </Button>
      )}
    </>
  );

  return <UniversalHeader title={renderTitle()} extra={renderPageExtra()} />;
};
export default observer(DetailsHeader);
