import React, { memo, Component } from "react";
import "./NavBar.css";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Turn as Hamburger } from "hamburger-react";
import { Button, Layout, Typography, Row, Col, Menu, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { StoreContext } from "../../storeContext";
import logo from "../../images/numedlogo.png";
import locationImg from "../../images/location.svg";
import AccountMenu from "./AccountMenu";
import CartButton from "./CartButton";
import MessageButton from "./MessageButton";
import BookTestModal from "../login/BookTestModal";
import ProductPageButton from "./ProductPageButton";
import ReportMenu from "./ReportMenu";
import { CART_PAGE, HOME_PAGE } from "../../label.EN";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { addressStyle, headerStyle } from "./NavbarCss";
import AboutPageButton from "./AboutPageButton";
import ServicePageButton from "./ServicePageButton";
import ContactUsButton from "./ContactUsButton";
import FacilitiesButton from "./FacilitiesButton";

const { Text } = Typography;
const { Header } = Layout;
const caption = "Reliable and Quick";

const locationInformationStyle = {
  lineHeight: 1,
  paddingTop: 15,
};

class NavBar extends Component {
  static contextType = StoreContext;

  constructor() {
    super();
    this.state = { isOpen: false, isNavBarReady: false };
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
   // this.showLoginModal();
  }

  componentDidMount() {
    this.authStore = this.context.authStore;
    this.notificationStore = this.context.notificationStore;
    this.setState({ isNavBarReady: true });

    console.log( this.authStore.isReadyForService," this.authStore");

    if( !this.authStore.isReadyForService)
      {
        this.showLoginModal();
      }
  }
  handleHamburgerToggle = (toggled) => {
    this.setState({ isOpen: toggled });
  };

  onCloseDrawer = () => {
    this.setState({ isOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if isOpen has changed and close the drawer
    if (prevState.isOpen && !this.state.isOpen) {
      this.onCloseDrawer();
    }
  }
  renderServiceMenu = () => {
    return (
      <div className="menu-items">
        <AboutPageButton />
        <ServicePageButton />
        <ProductPageButton />
        <FacilitiesButton />
        <ContactUsButton />
      </div>
    );
  };
  renderServiceMenuHamburger = () => {
    const handleCloseDrawer = () => {
      this.setState({ isOpen: false });
    };
    return (
      <div className="ham-menu-items">
        <AboutPageButton  onClick={handleCloseDrawer}/>
        <ServicePageButton  onClick={handleCloseDrawer}/>
        <ProductPageButton  onClick={handleCloseDrawer}/>
        <FacilitiesButton  onClick={handleCloseDrawer}/>
        <ContactUsButton  onClick={handleCloseDrawer}/>
      </div>
    );
  };
  showLoginModal = () => {
    if (this.authStore === undefined) {
      return;
    }

    if (this.authStore.isReadyForService) {
      return;
    }

    this.authStore.showLoginModal();
  };

  renderBookATestButton = () => {
    if (!this.authStore.isReadyForService) {
      return (
        <Button
          className="book-a-test-button"
          shape="round"
          onClick={this.showLoginModal}
        >
          Book A Test
        </Button>
      );
    }
  };

  renderRightMenus = () => {
    return (
      <div style={{ opacity: 0.9, marginTop: "auto", marginBottom: "auto" }}>
        {this.renderServiceMenu()}
        <BookTestModal
          authStore={this.authStore}
          navigate={this.props.navigate}
        />
      </div>
    );
  };

  renderCartButton = () => {
    if (this.authStore.isReadyForService) {
      return (
        <Link to={CART_PAGE}>
          <AiOutlineShoppingCart
            className="Cart-menu"
            style={{ height: "30px", width: "30px" }}
          />
        </Link>
      );
    }
  };

  renderSupportButton = () => {
    if (this.authStore.isReadyForService) {
      return (
        <>
          <MessageButton
            apiProxy={this.authStore.apiProxy}
            navigate={this.props.navigate}
          />
        </>
      );
    }
  };

  render() {
    return (
      <div className="headerStyle">
   <div className="hamburger">
   <Hamburger
          toggled={this.state.isOpen}
          toggle={(toggled) => this.handleHamburgerToggle(toggled)}
        />
   </div>

        <div className="navbar-logo">
          <div
            style={{
              paddingLeft: "7%",
              paddingRight: "7%",
              textAlign: "center",
            }}
          >
            <Link to={HOME_PAGE}>
              <img alt="Nu-Med Labs" src={logo} height="63" title={caption} />
            </Link>
          </div>
        </div>
        {/* Dropdown menu */}
        {this.state.isOpen && (
  <Drawer
  placement="right"
  closable={false}
  onClose={this.onCloseDrawer}
  visible={this.state.isOpen}
  destroyOnClose={true}
  maskClosable={true}  // Allow closing when clicking outside the Drawer
  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
>
  {this.renderServiceMenuHamburger()}
</Drawer>

  
    
      
        )}

        <div className="menu-item">
          {this.authStore && this.renderCartButton()}
        </div>
        <div className="menu-item">
          {this.authStore && this.renderRightMenus()}
        </div>
        <div className="menu-item">
          {this.authStore && this.renderSupportButton()}
        </div>

        <div className="menu-items">
          <div>{this.authStore && this.renderBookATestButton()}</div>
        </div>
      </div>
    );
  }
}

export default memo(observer(NavBar));
