import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { List, Checkbox, Avatar, Typography, Tag } from "antd";

const { Text } = Typography;

const humanizeGender = (genderChar) => {
  return genderChar === "M" ? "Male" : genderChar === "F" ? "Female" : "Other";
};

const yearAndMonth = (age) => {
  let yearPart = age.split("-")[0];
  let monthPart = age.split("-")[1];

  if (yearPart === "0") {
    return `Age ${monthPart} months`;
  }

  return `${yearPart} years ${monthPart} months`;
};

function MemberCard({
  patient,
  salutation,
  memberStore,
  nonSaved,
  setNonSaved,
  onClick, // Add onClick prop
}) {
  let color = patient.isSelected ? "#f2f2f2" : "white";
  let selected = patient.isSelected;
  const genderText = patient.gender;
  const ageText = yearAndMonth(patient.age);
  const canSelect = memberStore.mode === "select";

  const [cardColor, setCardColor] = useState(color);
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    let mode = memberStore.mode;

    if (mode === "view") {
      setIsSelected(selected);
      setCardColor(color);
    }
  }, [memberStore.mode]);

  const handleCheck = (e) => {
    let checked = e.target.checked;
    let id = patient.id;
    nonSaved[id] = e.target.checked;

    setIsSelected(checked);
    setNonSaved({ ...nonSaved });
    if (e.target.checked) {
      setCardColor("#EFF8F8");
    } else {
      setCardColor("white");
    }
  };

  const handleClick = () => {
    onClick(patient.id); // Call the onClick function with the member id
  };

  return (
    <List.Item
      key={patient.id}
      style={{
        background: cardColor,
        paddingLeft: 15,
        paddingRight: 10,
        borderColor: "#D6DBE0",
        margin: " 0 2.5%",
        borderRadius: "10px",
        marginBottom: "8px",
      }}
      onClick={handleClick} // Add onClick handler to the List.Item component
      actions={[
        <Checkbox
          checked={isSelected}
          disabled={!canSelect}
          onChange={handleCheck}
        />,
      ]}
    >
      <List.Item.Meta
        title={
          <Text className="card-title">{salutation + "  " + patient.name}</Text>
        }
        description={
          <Text style={{ fontFamily: "Poppins-Light", fontSize: "13px" }}>
            {ageText}
          </Text>
        }
      />

      <Text style={{ color: "#2D94C6" }}>{genderText}</Text>
    </List.Item>
  );
}

export default observer(MemberCard);
