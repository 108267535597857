import { makeObservable, observable, computed, action } from "mobx";
import { apiHost } from "../../services/APIEndpoints";
import {
  deleteShoppingCartQuery,
  addToCartQuery,
} from "../../services/Queries";

const PENDING = "pending";
const DONE = "done";
const ERROR = "error";

const EMPTY_MESSAGE = { status: "", help: "" };
const ERROR_MESSAGE = {
  status: "error",
  help: "Unable to fetch the Products. The Service is unavailable. Please try after sometime.",
};

export default class MutableCartStore {
  state = PENDING;
  message = EMPTY_MESSAGE;

  constructor(props) {
    this.apiProxy = props.apiProxy;
    this.listStore = props.listStore;
    this.userId = props.userId;
    this.notificationStore = props.notificationStore;

    makeObservable(this, {
      state: observable,
      message: observable,

      isLoading: computed,
      isError: computed,
      isDone: computed,

      deleteProductFromCart: action,
      addProductToCart: action,
    });
  }

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isDone() {
    return this.state === DONE;
  }

  deleteProductFromCart = async (cartId, productId) => {
    const apiEndpoint = apiHost;

    const body = {
      input: {
        id: cartId,
        deleted: true,
      },
    };
    await this.deleteShoppingCart(apiEndpoint, body);
    this.notificationStore.deleteCartProduct(productId);

    await this.listStore.fetchTests();
  };

  addProductToCart = async (productId) => {
    const body = {
      input: {
        userId: this.userId,
        branchId: this.listStore.branchId,
        productId: productId,
      },
    }; 
    this.state = PENDING;
    try {
      const response = await this.apiProxy.mutate(
        apiHost,
        addToCartQuery,
        body
      );
      
      const result = await response.json();
      let errors=  result.data.addToShoppingCart.errors;

      if (errors) {
        this.message = ERROR_MESSAGE;
        this.state = DONE;
        console.log(errors)
        return errors[0].message;
      }

      this.notificationStore.setCartCount(
        result.data.addToShoppingCart.booking.cartCount
      );

      this.notificationStore.setCartProducts(productId)

      await this.listStore.fetchTests();
      this.state = DONE;
      return result.data.addToShoppingCart.booking.status;
    } catch (e) {
      console.log(e);
    }
  };

  deleteShoppingCart = async (url, deleteCriteria) => {
    this.state = PENDING;
    this.message = EMPTY_MESSAGE;

    try {
      const response = await this.apiProxy.mutate(
        url,
        deleteShoppingCartQuery,
        deleteCriteria
      );
      const data = await response.json();

      if (data.data.updateShoppingCart.error !== null) {
        this.message = ERROR_MESSAGE;
        this.state = DONE;
        return;
      }

      this.notificationStore.setCartCount(
        data.data.updateShoppingCart.booking.cartCount
      );

      this.state = DONE;
    } catch (e) {
      this.state = ERROR;
      this.message = ERROR_MESSAGE;
      console.log(e);
    }
  };
}
