import React from "react";
import { Button, Col, Divider, Row } from "antd";
import "./FooterComponent.css";
import logo from "../../images/numed-white.png";import { Link, useNavigate } from "react-router-dom";
import facebook from "../../images/fb.png"
import instagram from "../../images/instagram.png"
import twitter from "../../images/twitter.png"
const caption = "Reliable, Quick and Secured";

const FooterComponent = () => {
  
const navigate=useNavigate()
  const renderFooterColumn = ( subHeadingArray, links) => (
    <Col>
      <div className="footer-subtitle-container">
        {subHeadingArray.map((subHeading, index) => (
          
          <a href={links[index]&&links[index]} target="_blank"  >
            <h3 key={index} style={{color:"#f27905",fontWeight:"600",marginBottom:"17px"}}>{subHeading}</h3>
          </a>

        ))}
      </div>
    </Col>
  );

  const goToFacebook=()=>{
      const url = 'https://www.facebook.com/numedlabs/'; 
      window.open(url, '_blank');
  
  }
  const goToYoutube=()=>{
    const url = 'https://www.youtube.com/@numedlabs'; 
    window.open(url, '_blank');
}
const goToLinkedIn=()=>{
  const url = 'https://in.linkedin.com/company/nu-med-labs'; 
  window.open(url, '_blank');
}

  const renderServiceColumn = ( subHeadingArray, links) => (
      <Col>
        <div className="footer-subtitle-container">
          {subHeadingArray.map((subHeading, index) => {
            if(index==0){
              return (
                <Link  to="/service" >
                <h3 key={index} style={{color:"#f27905",fontWeight:"600",marginBottom:"17px"}}>{subHeading}</h3>
                </Link>
              )
            }
            else{
              return(
                <Link  to="/service" className="footer-link">
                <h3 key={index} style={{fontSize:"16px",marginBottom:"17px"}}>{subHeading}</h3>
                </Link>
              )
            }
})}
        </div>
      </Col>
    );
  return (
    <div className="footer-component-container">
      <Row gutter={[50, 10]}  style={{width: "100%", margin: "auto", display: "flex", justifyContent: "center", alignItems: "stretch"  }}>
        <Col
          style={{ marginTop: "-20px", height: "100%", width: "80%" }}
          lg={{ span: "7" }}
          sm={{ span: "22" }}
        >
            <img
              alt="Nu-Med Labs"
              src={logo}
              align="left"
              height="70"
              style={{marginBottom:"20px"}}
              title={caption}
            />
          <Row style={{ marginTop: "25px",width:"85%"}}>
            <h1 className="poppins-regular">
              Experience the highest standards of health test at the well-known
              NABL Accredited Lab in Kancheepuram
            </h1>
          </Row>
       
        </Col>
       <Col>
       <Row gutter={[30, 20]} >
       <Col>{renderFooterColumn( ["ABOUT US","TESTS","FACILITIES","CONTACT US"],["https://limsapi.numedlabs.com/about.html","https://limsapi.numedlabs.com/terms-and-privacy-policy.aspx"])}</Col>
       <Col>{renderServiceColumn( [
          "OUR SERVICES",
          "Whole Ab Scan",
          "PFT - Pulmonary Function Test",
          "Digital X Rays","Dental X Rays",
          "CBCT Dental X Rays",
          "Blood Sugar"
        ],[])}</Col>
       
        <Col style={{paddingLeft:"50px"}}>
         <h3 style={{color:"#f27905",fontWeight:"600",marginBottom:"17px"}}>SOCIAL</h3>
         <div style={{display:"flex",gap:"6%"}}>
          <img src={facebook} onClick={goToFacebook}/>
          <img src={instagram}/>
          <img src={twitter}/>
         </div>
        </Col></Row></Col>
      </Row>
     
     
    </div>
  );
};

export default FooterComponent;