import { action, makeObservable, observable, computed } from "mobx";
import { registerUserQuery } from "../../services/Queries";
import { apiHost } from "../../services/APIEndpoints";

const INIT = "init";
const PENDING = "pending";
const DONE = "done";
const ERROR = "error";

const EMPTY_MESSAGE = { status: "", help: "" };
const ERROR_MESSAGE = { status: "error", help: "We are unable to register you. Please contact us.", };

class RegistrationStore {
  state = INIT;
  message = EMPTY_MESSAGE;

  constructor(props) {
    this.apiProxy = props.apiProxy;

    makeObservable(this, {
      state: observable,
      message: observable,

      isInit: computed,
      isLoading: computed,
      isError: computed,
      isDone: computed,

      addUser: action,
    });
  }

  get isInit() {
    return this.state === INIT;
  }

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isDone() {
    return this.state === DONE;
  }

  addUser = async (formData) => {
    this.state = PENDING;
    this.message = EMPTY_MESSAGE;

    formData.phoneNumber = this.apiProxy.getMobileNumber();
    formData.birthDate = formData.birthDate.format("YYYY-MM-DD");
    formData.salutationId = parseInt(formData.salutationId);
    const variables = { input: formData };

    try {
      const response = await this.apiProxy.mutate(apiHost, registerUserQuery, variables);

      const result = await response.json();

      const errors = result.data.registerUser.error;

      if (errors !== null) {
        this.state = ERROR;
        this.message.error = ERROR;
        this.message.help = errors[0].message;
        return;
      }

      this.apiProxy.setUserContext(result.data.registerUser.detail);

      this.state = DONE;
    } catch (e) {
      this.state = ERROR;
      this.message = ERROR_MESSAGE;
    }
  };
}

export default RegistrationStore;
