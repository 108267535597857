import React from "react";
import familyBanner from "../../../images/family-banner.jpg";
import "./Banner.css"; // Import the CSS file
import Quote1 from "../../../images/quote-1.png";
import Quote2 from "../../../images/quote.png";

const Banner = () => {
  return (
    <div className="banner" style={{ backgroundImage: `url(${familyBanner})` }}>
      <div className="banner-text">
        <img src={Quote1} className="top-image" />
        <span className="banner-text-highlight">Health of your family </span>
         <div className="right-text"> is</div>
        <span className="right-text">life's greatest asset<img src={Quote2} className="top-image" /></span>
      </div>
    </div>
  );
};

export default Banner;
