import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { PRODUCTS_PAGE } from "../../label.EN";
import { FileTextOutlined } from "@ant-design/icons";

function ProductPageButton({ onClick }) {
  const navigate = useNavigate();
  return (
    <button className="card-content" type='link' onClick={() => {navigate(PRODUCTS_PAGE)
    onClick()}} >Tests</button>
  );
}
export default observer(ProductPageButton);
