let uiUrl = "http://35.154.161.62";
let backendHost = "https://numedlabs.com/hygieia";
let authLoginUrl = "https://numedlabs.com/auth/";

// const hostname = window && window.location && window.location.hostname;
// if (hostname === "localhost") {
//   backendHost = "http://localhost:8088";
//   authLoginUrl = "http://localhost:8088/auth";
//   uiUrl = "http://localhost:3000/";
// }

export const backendUrl = backendHost;
export const loginUrl = authLoginUrl;

export const apiHost =  backendHost+ "/graphql";
export const assetHost = backendHost+"/assets/users/";

export const baseUrl = uiUrl;
