export function isNull(value) {
    if (value == null || value.length === 0) {
        return true;
    }
    return value === undefined;
}

export const convertDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = "0" + dt;
    }
    if (month < 10) {
        month = "0" + month;
    }
    return dt + "/" + month + "/" + year;
};

export const timeConvert = (time) => {
    time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
};

export const getDOB = (formData) => {
    var age = {
        years: formData.years,
        months: formData.months,
        days: formData.days
    }

    const currentDate = new Date();
    const birthDate = new Date(
        currentDate.getFullYear() - age.years,
        currentDate.getMonth() - age.months,
        currentDate.getDate() - age.days
    );

    const year = birthDate.getFullYear();
    const month = (birthDate.getMonth() + 1).toString().padStart(2, '0');
    const date = birthDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${date}`;
}

