import React, { Component } from "react";
import HomeProductStore from "../HomeProductStore";
import Image from "../../../../images/cerulo.jpg";
import { observer } from "mobx-react";

class CustomScrollbar extends Component {
  render() {
    const { scrollContainer, isDragging } = this.props;

    const scrollbarStyle = {
      position: "absolute",
      top: 0,
      right: isDragging ? 0 : "-8px", // Adjust visibility based on dragging state
      height: "100%",
      width: "8px", // adjust the width of the scrollbar as needed
      background: isDragging ? "#ddd" : "#eee", // change colors as needed
      borderRadius: "4px",
      cursor: "pointer",
      transition: "right 0.2s", // Add a transition for smoother appearance/disappearance
    };

    return (
      <div
        style={scrollbarStyle}
        onMouseDown={this.props.onMouseDown}
      />
    );
  }
}


class Featured extends Component {
  constructor(props) {
    super(props);
    this.featuredTests = [
      "CERULOPLASMIN",
      "ADA - CSF",
      "A /GRATIO",
      "BILE ACIDS - TOTAL",
      "ISLET CELL ANTIBODY",
    ];
    this.testData = [
      {
        name: "CERULOPLASMIN",
        description:
          "Ceruloplasmin is a protein that is made in the liver. It stores and carries copper from the liver into the bloodstream and to the parts of your body that need it.",
      },
      {
        name: "ADA - CSF",
        description: "Used For Diagnosing Tuberculosis.",
      },
      {
        name: "A /G RATIO",
        description: "Used For Determining If Patient Has Liver Disease Or Kidney Disease.",
      },
      {
        name: "BILE ACIDS - TOTAL",
        description: "Bile acids, the water-soluble, amphipathic end products of cholesterol metabolism, are involved in liver, biliary, and intestinal disease. Formed in the liver, bile acids are absorbed actively from the small intestine, with each molecule undergoing multiple enterohepatic circulations before being excreted.",
      },
      {
        name: "ISLET CELL ANTIBODY",
        description: "islet test.",
      },
    ];
    this.store = new HomeProductStore({ apiProxy: props.apiProxy });
    this.state = {
      slidesToShow: 3,
      selectedTestIndex: 0,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    };
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  componentDidMount = async () => {
    await this.store.fetchFeaturedProducts(this.kind[0], 30);
    await this.store.fetchFeaturedProducts(this.kind[1], 30);
    console.log("Fetched data:", this.store.products);
  };

  handleTestClick = (index) => {
    this.setState({ selectedTestIndex: index });
  };

  handleMouseDown = (e) => {
    this.setState({
      isDragging: true,
      startX: e.pageX - this.scrollContainer.offsetLeft,
      scrollLeft: this.scrollContainer.scrollLeft,
    });
  };

  handleMouseMove = (e) => {
    if (!this.state.isDragging) return;
    const x = e.pageX - this.scrollContainer.offsetLeft;
    const scrollX = x - this.state.startX;
    this.scrollContainer.scrollLeft = this.state.scrollLeft - scrollX;
  };

  handleMouseUp = () => {
    this.setState({ isDragging: false });
  };

  render() {
    const selectedTest = this.testData[this.state.selectedTestIndex];

    return (
      <div
        style={{
          backgroundColor: "#fff8f2",
          paddingLeft: "15%",
          paddingBottom: "7%",
          position: "relative", // make the container relative
        }}
      >
        <div style={{ padding: "7% 15% 0% 0%" }}>
          <h1
            style={{
              fontSize: "28px",
              fontWeight: "700",
              color: "#3396c8",
              marginBottom: "20px",
            }}
          >
            FEATURED TESTS
          </h1>
          <div
            ref={(ref) => (this.scrollContainer = ref)}
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              overflowX: "scroll", // enable horizontal scrolling
              overflowY: "hidden",
              position: "relative", // make the container relative
            }}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
          >
            {this.featuredTests.map((test, index) => (
              <div
                key={index}
                style={{
                  color:
                    this.state.selectedTestIndex === index
                      ? "#3396c8"
                      : "#dad4cf",
                  fontWeight: 700,
                  cursor: "pointer",
                  minWidth: "150px",
                  textAlign: "center",
                  fontSize: "18px",
                  marginBottom: "30px",
                }}
                onClick={() => this.handleTestClick(index)}
              >
                {test}
              </div>
            ))}
          </div>
        </div>
        {selectedTest && (
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center-start",
                justifyContent: "center",
              }}
            >
              <h2 style={{ fontFamily: "Mont-Book", fontSize: "30px", marginBottom: "-1px" }}>
                {selectedTest.name}
              </h2>
              <p
                style={{
                  fontFamily: "Mont-Regular",
                  fontSize: "16px",
                  maxWidth: "85%",
                }}
              >
                {selectedTest.description}
              </p>
            </div>

            <img src={Image} alt={selectedTest.name} style={{
              maxWidth: "50%",
              height: "auto",
            }} />
          </div>
        )}
        <CustomScrollbar
          scrollContainer={this.scrollContainer}
          isDragging={this.state.isDragging}
          onMouseDown={this.handleMouseDown}
        />
      </div>
    );
  }
}

export default observer(Featured);
