import { action, makeObservable, observable } from "mobx";
import { DONE, ERROR, INIT, PENDING } from "../label.EN";
import { branchSearchQuery, fetchBranches, verifyPincodeQuery } from "../services/Queries";
import { apiHost } from "../services/APIEndpoints";


class BranchStore {
    state = INIT;
    branches = [];
    branchSearchSuggestion = [];
    search = false

    constructor(props) {
        this.apiProxy = props.apiProxy;
        makeObservable(this, {
            state: observable,
            branches: observable,
            search: observable,
            branchSearchSuggestion: observable,

            setState: action,
            setBranches: action,
            setSearch: action,
            setBranchSearchSuggestions: action,
        });
    }

    fetchBranches = async () => {
        this.setState(PENDING)
        try {
            const response = await this.apiProxy.query(
                apiHost,
                fetchBranches,
                null
            );

            const result = await response.json();
            const error = result.data.fetchBranches.error

            if (error) {
                this.setState(ERROR)
                return;
            }

            const branches = result.data.fetchBranches.branches
            this.setBranches(branches)
            this.setState(DONE)
        } catch (error) {
            this.setState(ERROR)
            console.log(error + " error at fetch branches api")
        }
    }

    fetchBranchSearchItems = async () => {
        this.setState(PENDING)
        try {
            const response = await this.apiProxy.query(
                apiHost,
                branchSearchQuery,
                null
            );
            const result = await response.json();
            const error = result.data.fetchBranchPincodes.error;

            if (error) {
                this.setState(ERROR);
                return;
            }
            const branchSuggestions = result.data.fetchBranchPincodes.branchPincodes;
            this.setBranchSearchSuggestions(branchSuggestions);
            this.setState(DONE)
        } catch (error) {
            this.setState(ERROR)
            console.log(error + " error at fetch branch suggestions api")
        }
    }

    searchBranch = async (pincode) => {
        this.setState(PENDING);
        try {
            const code = parseInt(pincode)
            const queryVariables = { pincode: code };
            const response = await this.apiProxy.query(
                apiHost,
                verifyPincodeQuery,
                queryVariables
            );
            const result = await response.json();
            const error = result.data.verifyPincode.error
            if (error) {
                this.setState(ERROR)
                return { errorMessage: error.message };
            }
            const branch = result.data.verifyPincode.branch;
            const branchArray = new Array(branch)
            this.setBranches(branchArray)
            this.setSearch(true)
            this.setState(DONE)
            return false
        } catch (error) {
            this.setState(ERROR)
            console.log(error, "Error in search Branch")
        }
    }

    getBranches = () => {
        return this.branches
    }

    setBranches = (branches) => {
        this.branches = branches
    }

    getState = () => {
        return this.state
    }

    setState = (state) => {
        this.state = state
    }

    getSearch = () => {
        return this.search
    }

    setSearch = (bool) => {
        this.search = bool
    }

    getBranchSuggestions = () => {
        return this.branchSearchSuggestion
    }

    setBranchSearchSuggestions = (suggestions) => {
        this.branchSearchSuggestion = suggestions;
    }
}

export default BranchStore