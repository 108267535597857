import { action, makeObservable, observable } from "mobx";
import { fetchProductsQuery, fetchFeaturedProductsQuery } from "../../../services/Queries";

import { apiHost } from "../../../services/APIEndpoints";

class HomeProductStore {
  products = [];
  rowCount = 0;

  constructor(props) {
    this.apiProxy = props.apiProxy;

    makeObservable(this, {
      products: observable,
      rowCount: observable,

      fetchProducts: action,
    });
  }

  fetchProducts = async(kind, offset, limit) => {
    const queryVariables = {criteria: {branchId: 2,categoryId: null,kind: kind,offset: offset,limit: limit}};
    
    const response = await this.apiProxy.query(apiHost,fetchProductsQuery,queryVariables);

    const result = await response.json();
    const error = result.data.fetchProducts.error;

    if (error) {
      return error.message;
    }

    const prods = result.data.fetchProducts.products;

    this.rowCount = prods.length;
    this.products = prods;
  }

  fetchFeaturedProducts = async(kind, limit) => {
    const queryVariables = {criteria: {branchId: 2,kind: kind,limit: limit}};
    
    const response = await this.apiProxy.query(apiHost,fetchFeaturedProductsQuery,queryVariables);

    const result = await response.json();
    const error = result.data.fetchFeaturedProducts.error;

    if (error) {
      return error.message;
    }

    const prods = result.data.fetchFeaturedProducts.products;

    this.rowCount = prods.length;
    this.products = prods;
  }
}

export default HomeProductStore;