import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Affix, Button, Card, Modal, notification, Typography } from 'antd';
import { PhoneTwoTone, SmileTwoTone, CompassTwoTone } from '@ant-design/icons';
import { apiHost } from '../../services/APIEndpoints';
import { generateTicketQuery } from '../../services/Queries';
// import { getTwoToneColor, setTwoToneColor } from '@ant-design/icons';


import logo from '../../images/numedlogo.png';
import { isNull } from '../Util';
import { BRANCH_LIST_PAGE } from '../../label.EN';

const ERROR_MESSAGE = "Unable to create ticket now.";
const SUCCESS_MESSAGE = "Ticket created successfully.";

// setTwoToneColor('#ffffff');
// getTwoToneColor();
const Center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
};
const { Text } = Typography;

class MessageButton extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false }
        this.apiProxy = props.apiProxy;
    }

    showModal = () => {
        this.setState({ visible: true })
    }

    hideModal = () => {
        this.setState({ visible: false })
    }

    notify = (status, type) => {
        const args = {
            message: "Support Request",
            description: status,
            duration: 0,
            type: type,
        };
        notification.open(args);
    };


    handleOk = async () => {
        const criteria = {
            userId: this.apiProxy.getUserId()
        }

        try {
            this.hideModal();
            const response = await this.apiProxy.query(apiHost, generateTicketQuery, criteria);
            const result = await response.json();
            if (isNull(result.data.generateTicket) || !isNull(result.data.generateTicket.errors)) {
                this.notify(ERROR_MESSAGE, "error");
                return;
            }
            if (result.data.generateTicket.rows > 0)
                this.notify(SUCCESS_MESSAGE, "success");
        }
        catch (e) {
            this.notify(ERROR_MESSAGE, "error");
        }

    };

    render() {
        return (
            <>
                <Affix style={{ position: 'fixed', bottom: 80, right: "2%" }} >
                    <Tooltip placement="left" title="View our Branches ?" color={'#108ee9'} key="tp2">
                        {/* <Button type="primary" onClick={() => this.props.navigate(BRANCH_LIST_PAGE)} icon={<CompassTwoTone />} shape="round" size="large" /> */}
                    </Tooltip>
                </Affix>
                <Affix style={{ position: 'fixed', bottom: 20, right: "2%" }} >
                    <Tooltip placement="left" title="Need Help ?" color={'#108ee9'} key="tp1">
                        <Button type="primary" onClick={this.showModal} icon={<PhoneTwoTone twoToneColor="#FFFFFF"/>} shape="round" size="large" />
                    </Tooltip>
                </Affix>
                {this.renderModal()}
            </>
        )
    }

    renderModal = () => {
        if (isNull(this.apiProxy) || isNull(this.apiProxy.userName)) {
            return;
        }
        const styledUser = <Text type="warning" strong>{this.apiProxy.userName}</Text>;
        return (
            <Modal centred visible={this.state.visible} onOk={this.handleOk} onCancel={this.hideModal} maskClosable={false}>
                <div style={Center}>
                    <img alt="Nu-Med Logo" src={logo} height={50} />
                    <p className="welcome-text">Happy to help <SmileTwoTone style={{ fontSize: '24px' }} /></p>
                    <Card bordered={false}>
                        <p className="home-text">Dear {styledUser},</p>
                        <p className="home-text">Do you need any assistance in booking?</p>
                        <p className="home-text">Please confirm.</p>
                    </Card>
                </div>
            </Modal>

        )
    }
}
export default observer(MessageButton);