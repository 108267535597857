import { action, makeObservable, observable } from "mobx";

class NotificationStore {
  cartCount = 0;
  launchFlag = 0;

  cartProducts = new Set();

  constructor(props) {
    makeObservable(this, {
      cartCount: observable,
      cartProducts: observable,
      setCartCount: action,
      setCartProducts: action,
      deleteCartProduct: action,
    });
  }

  setCartCount(cartCount) {
    this.cartCount = cartCount;
  }

  getCartCount() {
    return this.cartCount;
  }

  clearCartProducts() {
    this.cartProducts.clear()
  }

  setCartProducts(product) {
    this.cartProducts.add(product);
  }

  getCartProducts() {
    return this.cartProducts;
  }

  deleteCartProduct(product) {
    this.cartProducts.delete(product);
  }
}

export default NotificationStore;
