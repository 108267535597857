import React, { Component } from "react";
import { observer } from "mobx-react";
import { ConfigProvider, Select } from "antd";
import TestSearchStore from "./TestSearchStore";

class TestSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null
    }
    this.searchStore = new TestSearchStore({
      apiProxy: props.apiProxy,
      branchId: props.branchId,
    });
  }

  onSearch = (value) => {
    if (value.length > 2) {
      this.searchStore.search(value);
    }
  };

  onChange = async (value) => {
    let details=value.split(',');
    let productId=details[0];
    let price=details[1];
    
    await this.props.onChange(productId,price)
    this.setState({
      searchValue: null
    })

  }

  render() {
    const productList = this.searchStore.productList;

    return (
      <ConfigProvider renderEmpty={() => "Please type min 3 characters"}>
        <div className="my-select-container">
          <Select
            value={this.state.searchValue}
            style={this.props.SearchStyle}
            showSearch
            placeholder={this.props.placeholder}
            defaultActiveFirstOption={false}
            filterOption={false}
            onChange={this.onChange}
            onSearch={this.onSearch}
          >
            {productList.map((item) => (
              <Select.Option value={item.meta.id+','+item.price.basePrice} key={item.meta.id}>{item.meta.name}</Select.Option>
            ))}
          </Select>
        </div>
      </ConfigProvider>
    );
  }
}
export default observer(TestSearch);
