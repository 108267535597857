// ServicesCss.js
export const blueDotsStyle = {
    background: `
      radial-gradient(circle, #399ac8 1px, transparent 1.2px)`,
    backgroundSize: '10px 10px',
    margin: '0',
    padding: '0',
    height: '40vh',
    opacity:".5",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex:"0"
  };

  export const serviceImageBoxStyle = {
    width: "50%",
    margin:"auto"
  };

  export const serviceImageStyle = {
    width: "100%",
    height: "auto",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  
  
  export const serviceTitleStyle = {
    fontFamily: "Mont-Book",
    fontSize: "30px",
    marginBottom: "-5px",
  };
  
  export const serviceDescriptionStyle = {
    fontFamily: "Mont-Regular",
    fontSize: "16px",
    width: "75%",
  };
  
  export const readMoreStyle = {
    width: "75%",
    display: "flex",
    justifyContent: "flex-start",
  };
  
  export const readMoreTextStyle = {
    marginTop: "12px",
  };
  
  export const rightMenuStyle = {
    display: "flex",
    justifyContent: "right",
    width: "75%",
  };
  
  export const leftMenuItemStyle={
    padding: "1px",
    borderRadius: "5px",
    backgroundColor: "#f27906",
    marginRight: "10px",
    opacity: ".5",
  }

  export const rightMenuItemStyle = {
    padding: "1px",
    borderRadius: "5px",
    backgroundColor: "#f27906",
    marginRight: "10px",
  };
  