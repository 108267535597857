import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Statistic, Card } from "antd";
import DetailsHeader from "./DetailsHeader";

import SubTestDetails from "./SubTestDetails";
import { StoreContext } from "../../storeContext";

import { observer } from "mobx-react";
import { RUPPEE_SYMBOL } from "../../label.EN";
import calendarIcon from "../../images/schedule-calendar.svg";
import testTubeIcon from "../../images/coloredTestTube.svg";
import sampleIcon from "../../images/sampleCollected.svg";
import walletIcon from "../../images/coloredWallet.svg";

const cardStyle = {
  marginTop: "20px",
  borderRadius: "10px",
  padding: "20px",
  overflow: "hidden",
  background: "#FFFFFF",
  borderColor: "#D6DBE0",
};
const iconStyle = {
  marginRight: 20,
};
const titleStyle = {
  color: "#2d94c6",
  fontFamily: "Poppins-Regular",
};
const valueStyle = {
  fontFamily: "Poppins-Regular",
  whiteSpace: "pre-line"
};
const aboutValueStyle={
  fontFamily: "Poppins-Regular",
  whiteSpace: "pre-line",
  fontSize:"18px"
}
const rowStyle = {
  marginLeft: "5%",
  marginRight: "5%",
};
const cardTitle = {
  marginBottom: "4px",
  color: "#96CAE2",
  fontSize: "14px",
};
function TestDetails() {
  const details = useLocation();
  const storeContext = useContext(StoreContext);
  const notificationStore = storeContext.notificationStore;
  const productStore = storeContext.productStore;
  const cartProducts = notificationStore.getCartProducts();
  const testDetails = details.state.productDetails;
  const description = testDetails.glimpse;
  const specialInstructions =
    details.state.instructions === ""
      ? "No Special  Preparation Required"
      : details.state.instructions;
  const testId = details.state.testId;
  const subTests =
    testDetails.SubTestDetails === null ? false : testDetails.SubTestDetails;
  const totalTests = 1 + (subTests ? subTests.length : 0);
console.log("hahaha",description)
  const getStatisticTitle = (value) => {
    return <span style={titleStyle}>{value}</span>;
  };
  return (
    <div>
      <DetailsHeader
        testId={testId}
        name={testDetails.Testname}
        productStore={productStore}
        cartProducts={cartProducts}
        title="Test"
      />
      <div>
        <div className="body-style">
          <Row style={{ margin: "0 4 0 4%", padding: "1% 0 2% 3%" }}>
            <Col
              xs={23}
              sm={12}
              md={12}
              lg={16}
              xl={16}
              style={{ marginRight: "10px" }}
            >
              <Card style={cardStyle}>
                <div>
                  <Statistic
                    title={getStatisticTitle("About & Special Instructions")}
                    valueStyle={aboutValueStyle}
                    value= {`${description}\n ${specialInstructions} `}
                    formatter={(value) => (
                      <>
                        {value.split("\n").map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </>
                    )}
                    style={{ height: "170px" }}
                  />
                </div>
                
                <Card
                  style={{
                    background: "#F7F8F9",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={cardTitle}>Sub Tests</div>
                  {subTests && <SubTestDetails subTestDetails={subTests} />}

                  {!subTests && (
                    <div
                      style={{
                        background: "#F7F8F9",
                        height: "225px",
                        overflow: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>This test contains no sub tests.</p>
                    </div>
                  )}
                </Card>
              </Card>
            </Col>

            <Col xs={23} sm={11} md={11} lg={7} xl={7}>
              <Card style={cardStyle} hoverable bodyStyle={{ display: "flex" }}>
                <img src={calendarIcon} style={iconStyle} alt="calender_icon" />
                <Statistic
                  title={getStatisticTitle("Reports Available In")}
                  valueStyle={valueStyle}
                  value={testDetails.TAT + 1}
                  suffix="days"
                />
              </Card>
              <Card
                style={cardStyle}
                hoverable
                bordered={true}
                bodyStyle={{ display: "flex" }}
              >
                <img
                  src={testTubeIcon}
                  style={iconStyle}
                  alt="test_tube_icon"
                />
                <Statistic
                  title={getStatisticTitle("Total Tests")}
                  valueStyle={valueStyle}
                  value={totalTests}
                />
              </Card>

              <Card
                style={cardStyle}
                hoverable
                bordered={true}
                bodyStyle={{ display: "flex" }}
              >
                <img
                  src={sampleIcon}
                  style={iconStyle}
                  alt="sample_collection_icon"
                />
                <Statistic
                  title={getStatisticTitle("Sample Type")}
                  valueStyle={valueStyle}
                  value={testDetails.SampleType}
                />
              </Card>

              <Card
                style={cardStyle}
                hoverable
                bordered={true}
                bodyStyle={{ display: "flex" }}
              >
                <img src={walletIcon} style={iconStyle} alt="wallet_icon" />
                <Statistic
                  title={getStatisticTitle("Price")}
                  valueStyle={valueStyle}
                  prefix={RUPPEE_SYMBOL}
                  value={testDetails.Amount}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default observer(TestDetails);
