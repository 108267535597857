// AboutPageButton.js

import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

function AboutPageButton({ onClick }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
      window.open("https://limsapi.numedlabs.com/about.html", "_blank");
      // Call the provided onClick function (closing the drawer)
    }

    // Additional handling for the AboutPageButton click
    };

  return (
    <button className="card-content" onClick={handleClick}>
      About
    </button>
  );
}

export default observer(AboutPageButton);
