import React from "react";

import { Typography } from "antd";
import { RUPPEE_SYMBOL } from "../../label.EN";

const { Text } = Typography;

export function Price({ basePrice, quantity, totalAmount }) {
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <Text type="secondary">{RUPPEE_SYMBOL}{basePrice} x</Text>
            <Text strong style={{ paddingLeft: "3px" }}>{quantity}</Text>
            <Text strong style={{ paddingLeft: "10px" }}>{RUPPEE_SYMBOL}{totalAmount}</Text>
        </div>
    );
}

