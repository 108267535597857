import React, { useState } from "react";
import { Form, Select, Input, Radio, Button, Space } from "antd";
import { SPECIAL_CHAR_ERROR, SINGLE_CHAR_ERROR, NO_GENDER_ERROR, NO_NAME_ERROR, NO_SALUTATION_ERROR, } from "../../label.EN";
import MemberAge from "./MemberAge";

const { Option } = Select;

const GenderTypes = ["Male", "Female", "Other"];

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const GenderStyle = { display: "flex", justifyContent: "space-evenly",width:"90%",marginTop:"20px",margin:"auto",paddingTop:"15px" };
const GenderButtonStyle = { borderRadius: "10px" };
const SalutationStyle = { display: "flex", flexDirection: "row", justifyContent: "center", minWidth:"250px", maxWidth:"100%", marginBottom:"-10px" };
const SubmitButtonStyle = { borderRadius: "10px", background: "#f27906", color: "white", };
const Center = { display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", };
const dateFormatList = ["DD/MM/YYYY"];

export default function FamilyMemberForm({
  memberStore,
  cartStore,
  setMembers,
  memberListStore,
}) {
  const [form] = Form.useForm();
  const [maleDisabled, setMaleDisabled] = useState(false);
  const [femaleDisabled, setFemaleDisabled] = useState(false);

  const onFinish = async (request) => {

    await memberStore.addMember(request);
    await cartStore.fetchTests();
    memberStore.setMode("view");
    setMembers(memberListStore.members);
    form.resetFields();
  };

  const onSalutationChange = (value) => {
    if (value === "1" || value === "5") {
      form.setFieldsValue({
        gender: "M",
      });
      setFemaleDisabled(true);
      setMaleDisabled(false);
    } else if (value === "2" || value === "4") {
      form.setFieldsValue({
        gender: "F",
      });
      setFemaleDisabled(false);
      setMaleDisabled(true);
    } else {
      setFemaleDisabled(false);
      setMaleDisabled(false);
    }
  };

  const renderErrorMessages = (message) => {
    return <p style={{ marginLeft: "12%",marginBottom:"-10px", position:"relative" }}>{message}</p>;
  };
  const renderGenderErrorMessages = (message) => {
    return <p style={{ marginLeft: "16%",marginBottom:"-10px", position:"relative" }}>{message}</p>;
  };
  return (
    <Form id="my-form" form={form} onFinish={onFinish}>
      <div style={SalutationStyle}>
        <Form.Item
          name="salutationId"
          rules={[
            {
              required: true,
              message: renderErrorMessages(NO_SALUTATION_ERROR),
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Salutation"
            style={{ width: "7rem",fontSize:"1rem" }}
            onChange={(value) => onSalutationChange(value)}
          >
            <Option value={"1"}>Mr.</Option>
            <Option value={"2"}>Mrs.</Option>
            <Option value={"4"}>Ms.</Option>
            <Option value={"5"}>Master.</Option>
            <Option value={"6"}>Baby.</Option>
            <Option value={"7"}>Dr.</Option>
            <Option value={"8"}>B/O.</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          rules={[
            { required: true, message: renderErrorMessages(NO_NAME_ERROR) },
            { min: 2, message: renderErrorMessages(SINGLE_CHAR_ERROR) },
            {
              pattern: new RegExp(/^[a-zA-Z\s]*$/),
              message: renderErrorMessages(SPECIAL_CHAR_ERROR),
            },
          ]}
        >
          <Input
            style={{
              width: "12rem",
              fontSize:"1rem",
              marginLeft: "1vw",
            }}
            className="input-box"
            size="large"
            placeholder="Enter your name"
            type="text"
          />
        </Form.Item>
      </div>
      <div >
        <MemberAge form={form} />
      </div>
      <Form.Item
        name="gender"
        rules={[{ required: true, message: renderGenderErrorMessages(NO_GENDER_ERROR)}]}
      >
        <Radio.Group
          required
          size="large"
          buttonStyle="solid"
          style={GenderStyle}
        >
          {GenderTypes.map((gender, index) => (
            <Space>
              <Radio.Button
                key={index}
                style={GenderButtonStyle}
                disabled={
                  gender === "Male"
                    ? maleDisabled
                    : gender === "Female"
                      ? femaleDisabled
                      : false
                }
                value={gender[0]}
              >
                {gender}
              </Radio.Button>
            </Space>
          ))}
        </Radio.Group>
      </Form.Item>

      <div style={Center}>
        <Form.Item>
          <Button
            htmlType="submit"
            disabled={!memberStore.canSaveAddition}
            style={SubmitButtonStyle}
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
