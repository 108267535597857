import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../storeContext";
import { Collapse, List, Typography } from "antd";
import SubTestDetails from "./SubTestDetails";
const { Panel } = Collapse;

function SubProfileDetails({ id }) {
  console.log(id);
  const [subProfileDetails, setSubProfileDetails] = useState([]);
  const storeContext = useContext(StoreContext);
  const productStore = storeContext.productStore;

  useEffect(() => {
    const fetchSubProfileDetails = async () => {
      let result = await productStore.getSubProfileDetails(id);
      console.log(result);
      setSubProfileDetails(result);
    };
    fetchSubProfileDetails();
  }, [id, productStore]);
  return (
    <div
      style={{ maxHeight: "200px", overflow: "auto", backgroundColor: "white" }}
    >
      <List
        bordered
        dataSource={subProfileDetails}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text mark></Typography.Text> {item.TestName}
          </List.Item>
        )}
      />
      {/* {subProfileDetails.map((test, index) => {
                return (
                    <Collapse key={index}>
                        <Panel header={test.TestName}>
                            <p>Sample Collected : {test.Samples}</p>
                            {test.SubTest && (
                                <SubTestDetails subTestDetails={test.SubTestDetails} />
                            )}
                        </Panel>
                    </Collapse>
                );
            })} */}
    </div>
  );
}
export default SubProfileDetails;
