import {
  PageHeader,
  Card,
  Descriptions,
  List,
  Row,
  Col,
  Typography,
} from "antd";
import React from "react";

function SubTestDetails({ subTestDetails }) {
  return (
    <div style={{ height: "225px", overflow: "auto" }}>
      <List
        dataSource={subTestDetails}
        renderItem={(item, index) => (
          <List.Item
            style={{ fontSize: "15px", fontFamily: "Poppins-Regular" }}
          >
            <Typography.Text>{index + 1}.</Typography.Text> {item.SubTestName}
          </List.Item>
        )}
      />
    </div>
  );
}
export default SubTestDetails;
