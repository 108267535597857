import { action, makeObservable, observable, computed } from "mobx";
import { addNewAddressesQuery } from "../../services/Queries";
import { apiHost } from "../../services/APIEndpoints";

const INIT= "init";
const PENDING = "pending";
const DONE = "done";
const ERROR = "error";

const EMPTY_MESSAGE = { status: "", help: "" };
const ERROR_MESSAGE = {
  status: "error",
  help: "We are unable to add your address now. Please contact us.",
};

const SAVE_ERROR_MESSAGE = {
  status : "error",
  help: "Unable to save the data. Please contact us."
}

class AddressStore {
  state = INIT;
  message = EMPTY_MESSAGE;

  constructor(props) {
    this.apiProxy = props.apiProxy;

    makeObservable(this, {
      state: observable,
      message: observable,

      isInit: computed,
      isLoading: computed,
      isError: computed,
      isDone: computed,

      saveAddress: action,
    });
  }

  get isInit(){
    return this.state=== INIT;
  }

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isDone() {
    return this.state === DONE;
  }

  saveAddress = async (formData) => {
    this.state = PENDING;
    this.message = EMPTY_MESSAGE;
    formData.userId=this.apiProxy.getUserFuzzyId();
    formData.pincode=parseInt(formData.pincode);
    const variables = { input: formData };

    try {
      const response = await this.apiProxy.mutate(
        apiHost,
        addNewAddressesQuery,
        variables
      );

      const result = await response.json();

      const errors = result.data.registerNewAddress.error;

      if (errors !== null) {
        this.state = ERROR;
        this.message.error=ERROR;
        this.message.help =errors[0].message;
        return;
      }
      
      this.apiProxy.updateNewUserAddress(result);
      this.state = DONE;

    } catch(e) {
      this.state = ERROR;
      this.message = ERROR_MESSAGE;
    }
  };
}

export default AddressStore;
