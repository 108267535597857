
import React from 'react';
import { StoreContext } from '../../../../storeContext';

export default function useShowLoginModal() {
  const context = React.useContext(StoreContext);
  if (!context) {
    throw new Error('useShowLoginModal must be used within a StoreContext.Provider');
  }

  const authStore = context.authStore;
  const showLoginModal = React.useCallback(() => {
    if (authStore === undefined) {
      return;
    }

    if (authStore.isReadyForService) {
      return;
    }

    authStore.showLoginModal();
  }, [authStore]);

  return showLoginModal;
}
