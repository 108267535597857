import { action, makeObservable, observable, set } from "mobx";
import { addToCartQuery, fetchProductsQuery, fetchOrderQuery } from "../services/Queries";
import { TESTS_TITLE, TEST, PROFILE, ERROR, DONE, PENDING, INIT } from "../label.EN";

import { apiHost } from "../services/APIEndpoints";

const PROFILEDETAILURL = "https://limsapi.numedlabs.com/api/admin/ProfileTestDetails";
const SUBPROFILEDETAILURL = "https://limsapi.numedlabs.com/api/admin/ProfileBasedTestDetails";
const TESTDETAILURL = "https://limsapi.numedlabs.com/api/admin/GetResultDetails?Testid=";
const PRODUCTRANKURL = "https://limsapi.numedlabs.com/api/mobileteam/AddPageHitcounter";

class ProductStore {
  state = INIT;
  kind = TEST;
  products = [];
  activeFilterBtn = TESTS_TITLE;

  constructor(props) {
    this.apiProxy = props.apiProxy;
    this.notificationStore = props.notificationStore;
    makeObservable(this, {
      kind: observable,
      products: observable,
      activeFilterBtn: observable,
      state: observable,

      setProducts: action,
      setState: action,
      setKind: action,
      setActiveFilterBtn: action,
      addProductToCart: action,
    });
  }

  async fetchProducts(categoryId, kind, offset, limit) {
    const queryVariables = {
      criteria: {
        branchId: this.apiProxy.userContext.branchId,
        categoryId: categoryId,
        kind: kind,
        offset: offset,
        limit: limit,
      },
    };
    this.setState(PENDING)
    try {
      const response = await this.apiProxy.query(
        apiHost,
        fetchProductsQuery,
        queryVariables
      );

      const result = await response.json();
      const error = result.data.fetchProducts.error;

      if (error) {
        // handle error
        this.setState(ERROR)
        console.log(error.message);
        return error.message;
      }

      this.setProducts(result.data.fetchProducts.products);
      await this.setCartProducts();
      this.setState(DONE)
    } catch (e) {
      console.log(e)
    }
  }

  setCartProducts = async () => {
    const criteria = {
      criteria: {
        userId: this.apiProxy.getUserFuzzyId(),
        branchId: this.apiProxy.userContext.branchId,
        checkOut: false,
      },
    };
    const response = await this.apiProxy.query(
      apiHost,
      fetchOrderQuery,
      criteria
    );
    const result = await response.json();
    const error = result.data.fetchOrder.error;

    if (error) {
      return;
    }

    let products = result.data.fetchOrder.orderDetail.productCarts;
    this.notificationStore.setCartCount(products.length);
    this.notificationStore.clearCartProducts()
    if (products.length > 0) {
      for (let product of products) {
        this.notificationStore.setCartProducts(product.product.id)
      }
    } else {
      this.notificationStore.clearCartProducts()
    }

  }


  async addProductToCart(productId) {
    const queryVariables = {
      input: {
        userId: this.apiProxy.getUserId(),
        branchId: this.apiProxy.userContext.branchId,
        productId: productId,
      },
    };
    this.setState(PENDING)
    try {
      const response = await this.apiProxy.query(
        apiHost,
        addToCartQuery,
        queryVariables
      );
      const result = await response.json();
      const shoppingCart = result.data.addToShoppingCart
      const errors = shoppingCart.errors;
      if (errors) {
        this.setState(ERROR)
        // this.setErrorMessage(errors[0].message)
        console.log(errors)
        return errors[0].message
      }
      this.notificationStore.setCartCount(
        shoppingCart.booking.cartCount
      );
      this.notificationStore.setCartProducts(productId)
      this.setState(DONE)
      console.log(shoppingCart.status);
      return shoppingCart.booking.status;
    } catch (error) {
      console.log(error)
    }
  }

  async getProfileDetails(id) {
    let rank = await this.productRank(id);
    console.log(rank);
    let profileId = id.split("-")[1];
    let body = {
      "ProfileId": profileId
    };
    let response = await this.apiProxy.asyncPost(PROFILEDETAILURL, body);
    const result = await response.json();
    return result;
  }

  async getSubProfileDetails(id) {
    let body = {
      "ProfileId": id
    };
    let response = await this.apiProxy.asyncPost(SUBPROFILEDETAILURL, body);
    const result = await response.json();
    return result;
  }

  async getTestDetails(id) {
    let rank = await this.productRank(id);
    console.log(rank);
    let testId = id.split("-")[1];
    let url = TESTDETAILURL + testId
    let response = await this.apiProxy.getAsync(url);
    const result = await response.json();
    return result;
  }

  async productRank(id) {
    let body = {
      "userId": this.apiProxy.getUserId(),
      "Productid": id
    }
    let response = await this.apiProxy.asyncPost(PRODUCTRANKURL, body);
    let result = await response.json();
    return result
  }

  setKind(bool) {
    bool ? (this.kind = PROFILE) : (this.kind = TEST);
  }

  getKind() {
    return this.kind;
  }

  setProducts(products) {
    this.products = products;
  }

  getProducts() {
    return this.products;
  }

  setActiveFilterBtn(activeBtn) {
    this.activeFilterBtn = activeBtn
  }

  getActiveFilterBtn() {
    return this.activeFilterBtn
  }

  setState(state) {
    this.state = state
  }

  getState() {
    return this.state
  }
}

export default ProductStore;
