import React, { useContext } from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../../storeContext";
import { useNavigate } from "react-router-dom";
import HomePage from "./homePage/HomePage";

import { LOGIN_PAGE, PRODUCTS_PAGE, REGISTER_PAGE, ADDRESS_PAGE, CART_PAGE } from "../../label.EN";

function Login() {
  const context = useContext(StoreContext);
  let authStore = context.authStore;
  const navigate=useNavigate()
    if (authStore === undefined) {
      return (<HomePage />);
    }

    if (authStore.nextPage === LOGIN_PAGE || authStore.nextPage === REGISTER_PAGE || authStore.nextPage === ADDRESS_PAGE) {
     return (<HomePage />);
    }

    if (authStore.nextPage === PRODUCTS_PAGE) {
      navigate(CART_PAGE)
    }

}

export default observer(Login);
