import React from "react";
import "./HomeAbout.css";

export const AboutNumbers = () => {
  const data = [
    { Number: 11, FirstText: "Years of", LastText: "Experience" },
    { Number: 120, FirstText: "Years of", LastText: "Staffs" },
    { Number: 3293, FirstText: "Years of", LastText: "Volunteers" },
    { Number: 1212, FirstText: "Years of", LastText: "Tests" },
  ];

  return (
    <div className="about-numbers-container">
      {data.map(({ Number, FirstText, LastText }) => (
        <div
          key={Number}
          className="about-number-item"
        >
          <div className="about-number">{Number}</div>
          <div className="about-number-text">
            {FirstText}
            <br />
            {LastText}
          </div>
        </div>
      ))}
    </div>
  );
};
