import React, { useState, useEffect, useContext } from "react";
import { Pagination, notification, Col, Row } from "antd";
import "../../pages/product-page/ProductDisplay.css";
import { useNavigate } from "react-router-dom";
import {
  DONE,
  PACKAGES_TITLE,
  PRODUCT_DETAIL_PAGE,
  TEST_DETAIL_PAGE,
} from "../../label.EN";
import ProductCard from "./ProductCard";
import { StoreContext } from "../../storeContext";
import { observer } from "mobx-react";

const PAGESIZE = 30;

const paginationContainer = {
  margin: "5vh 0",
  display: "flex",
  justifyContent: "center",
};

const ProductList = ({ products, productStore, active }) => {
  const { notificationStore } = useContext(StoreContext);
  const cartProducts = notificationStore.getCartProducts();
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(30);
  const navigate = useNavigate();

  useEffect(() => {
    handleOnPageChange(1)
  }, [active])

  const handleOnProductClick = async (e, productId, active) => {
    e.stopPropagation();
    let status=await productStore.addProductToCart(productId);
    let added=true;
    let message=''
    if(status==0){
        message="Added"
    } 
    else if(status==1){
    message="Deleted repeated tests/profile, Added"}

    else {
      added=false 
      openNotification('success', 'topRight', status)
    }
    if(added){
    active === PACKAGES_TITLE ?
      openNotification('success', 'topRight', message+" "+"profile")
      :
      openNotification('success', 'topRight',  message+" "+"test")
    }
  };

  const openNotification = (type, placement, description) => {
    notification[type]({
      description: description,
      placement,
      duration: 6
    });

  };

  const handleOnPageChange = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * PAGESIZE);
    setMaxIndex(page * PAGESIZE);
  };

  const getDetails = async (product) => {
    console.log(product)
    let id = product.meta.id;
    let profilePrice = product.price.basePrice;
    console.log(product)
    let specialInstructions = product.meta.specialInstructions;
    if (active === PACKAGES_TITLE) {
      let profileDetails = await productStore.getProfileDetails(id);
      navigate(PRODUCT_DETAIL_PAGE, {
        state: { productDetails: profileDetails, profileId: id, price: profilePrice },
      });
    } else {
      let testDetails = await productStore.getTestDetails(id);
      navigate(TEST_DETAIL_PAGE, {
        state: { productDetails: testDetails, testId: id, instructions: specialInstructions },
      });
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {products.map(
          (product, index) =>
            index >= minIndex &&
            index < maxIndex && (
              <React.Fragment key={product.meta.id}>
                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                  <div>
                    <ProductCard
                      cartProducts={cartProducts}
                      product={product} g
                      getDetails={getDetails}
                      handleOnProductClick={handleOnProductClick}
                      active={active}
                    />
                  </div>
                </Col>
              </React.Fragment>
            )
        )}
      </Row>
      <div style={paginationContainer}>
        <Pagination
          current={current}
          defaultCurrent={1}
          onChange={handleOnPageChange}
          pageSize={PAGESIZE}
          total={products.length}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

export default observer(ProductList);
