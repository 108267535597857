import React, { Component } from "react";
import { observer } from "mobx-react";

import "./Register.css";

import RegistrationStore from "./RegistrationStore";

import RegistrationForm from "./RegistrationForm";
import RegisterAddress from "./RegisterAddress";

class Register extends Component {

  constructor(props) {
    super(props);
    this.registrationStore = new RegistrationStore({ apiProxy: props.apiProxy })
  }

  render() {
    if(this.registrationStore.isDone) {
      return <RegisterAddress apiProxy = {this.props.apiProxy}/>
    }

    return (
      <>
        <p className="welcome-text">About you</p>
        <p className="home-text">Required for mapping the test parameters</p>
        <RegistrationForm registrationStore={this.registrationStore} />
      </>
    );
  }
}

export default observer(Register);
