/**
 * Route to an authenticated route or to the Home Page
 */

import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { LOGIN_PAGE } from "../../label.EN";

import { StoreContext } from "../../storeContext";

const PrivateRouter = () => {
  const { authStore } = useContext(StoreContext);
  const authenticated = authStore.isAuthenticated;
  return authenticated ? <Outlet /> : <Navigate to={LOGIN_PAGE} />;
};

export default PrivateRouter;
