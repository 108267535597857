import { Form, InputNumber, Row, Col } from "antd";
import {
  MONTH_RANGE_ERROR,
  DAY_RANGE_ERROR,
  YEAR_RANGE_ERROR,
} from "../../label.EN";
import "./MemberDetails.css";
import styles from "./MemberAge.module.css";
const renderErrorMessage = (message) => {
  return <div style={{width:"200%",marginLeft:"16%"}}>{message}</div>;
};

const MemberAge = (form) => {
  const validateAge = async (_, value) => {
    const years = form.form.getFieldValue("years");
    const months = form.form.getFieldValue("months");
    const days = form.form.getFieldValue("days");

    if (!years && !months && !days) {
      form.form.setFields([
        {
          name: "age",
          errors: [renderErrorMessage("Provide Age in Years or Months or Days.")],
        },
      ]);
    } else {
      form.form.setFields([
        {
          name: "age",
          errors: [],
        },
      ]);
    }
  };

  return (
    <div style={{ maxWidth: "480px", minWidth: "300px", display:"flex",flexDirection:"col" }}>
      <Row
        gutter={24}
        style={{
          paddingLeft: "20px",
          marginBottom: "-20px",
          minWidth: "300px",
        }}
      >
        <Form.Item
          name="age"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          colon={false}
          className="form-item-no-margin"
        >
          <Row
            gutter={24}
            style={{
              marginLeft: "25%",
              marginBottom: "-20px",
              minWidth: "320px",
              display: "flex"
            }}
          >
               <Form.Item
          label={
            <span
              style={{
                display: "flex",
                marginTop: "35px",
              }}
            >
              Age :
            </span>
          }
          colon={false}
        ></Form.Item>
            <Col span={6} style={{ marginBottom: 0 }}>
              <Form.Item
                name="years"
                label="Years"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    type: "number",
                    min: 1,
                    max: 99,
                    message: renderErrorMessage(YEAR_RANGE_ERROR),
                  },
                  { validator: validateAge },
                ]}
              >
                <InputNumber
                  size="medium"
                  style={{ width: "100%", borderRadius: "5px" }}
                  className="hide-arrow-buttons"
                />
              </Form.Item>
            </Col>
            <Col span={6} style={{ marginBottom: 0 }}>
              <Form.Item
                name="months"
                label="Months"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    type: "number",
                    min: 1,
                    max: 12,
                    message: renderErrorMessage(MONTH_RANGE_ERROR),
                  },
                  { validator: validateAge },
                ]}
              >
                <InputNumber
                  size="medium"
                  style={{ width: "100%", borderRadius: "5px" }}
                  className="hide-arrow-buttons"
                />
              </Form.Item>
            </Col>
            <Col span={6} style={{ marginBottom: 0 }}>
              <Form.Item
                name="days"
                label="Days"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    type: "number",
                    min: 10,
                    max: 365,
                    message: renderErrorMessage(DAY_RANGE_ERROR),
                  },
                  { validator: validateAge },
                ]}
              >
                <InputNumber
                  size="medium"
                  style={{ width: "100%", borderRadius: "5px" }}
                  className="hide-arrow-buttons"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Row>
    </div>
  );
};

export default MemberAge;

// import { Form, InputNumber, Row, Col, Tooltip } from 'antd';
// import { NO_AGE_ERROR, NO_YEARS_ERROR, NO_MONTHS_ERROR, NO_DAYS_ERROR, MONTH_RANGE_ERROR, DAY_RANGE_ERROR, YEAR_RANGE_ERROR } from '../../label.EN';

// const renderErrorMessages = (message) => {
//     return <p style={{ marginLeft: '12%' }}>{message}</p>;
// };

// const validateAge = (_, values) => {
//     const { years, months, days } = values;

//     if(years > 0 ) {
//         return true;
//     }

//     if(months > 0 ) {
//         return true;
//     }

//     if(days > 10) {
//         return true;
//     }
//     return false;

// };

// const MemberAge = () => {
//     return (
//         <div style={{ maxWidth: "280px", }}>
//             <Row gutter={16}>
//                 <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
//                     <label required="true" style={{ marginTop: "10px" }}>
//                         <span style={{ color: 'red' }}>*</span> Age
//                     </label>
//                 </Col>
//                 <Col span={6}>
//                     <Form.Item label="Years" name="years" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
//                         rules={[
//                         {
//                             type: 'number',
//                             min: 1,
//                             max: 99,
//                             message: renderErrorMessages(YEAR_RANGE_ERROR),
//                         }
//                         ]}
//                     >
//                         <InputNumber size="medium" style={{ width: '100%', borderRadius: "5px" }} className="hide-arrow-buttons" />
//                     </Form.Item>
//                 </Col>
//                 <Col span={6}>
//                     <Form.Item label="Months" name="months" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
//                         rules={[
//                             {
//                                 type: 'number',
//                                 min: 1,
//                                 max: 12,
//                                 message: renderErrorMessages(MONTH_RANGE_ERROR),
//                             }
//                             ]}
//                     >
//                         <InputNumber size="medium" style={{ width: '100%', borderRadius: "5px" }} className="hide-arrow-buttons" />
//                     </Form.Item>
//                 </Col>
//                 <Col span={6}>
//                     <Form.Item label="Days" name="days" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
//                         rules={[
//                         {
//                             type: 'number',
//                             min: 10,
//                             max: 365,
//                             message: renderErrorMessages(DAY_RANGE_ERROR),
//                         }
//                         ]}
//                     >
//                         <InputNumber size="medium" style={{ width: '100%', borderRadius: "5px" }} className="hide-arrow-buttons" />
//                     </Form.Item>
//                 </Col>
//             </Row>
//         </div>

//     );
// };

// export default MemberAge;
