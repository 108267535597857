import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Typography } from "antd";
import { Navigate } from "react-router-dom";
import OTPGenerate from "./OTPGenerate";
import OTPEntry from "./OTPEntry";
import Register from "../register/Register";
import RegisterAddress from "../register/RegisterAddress";
import "./BookTestModal.css";

import logo from "../../images/numedlogo.png";
import { ADDRESS_PAGE, CART_PAGE, INIT, LOGIN_PAGE, PRODUCTS_PAGE, REGISTER_PAGE } from "../../label.EN";

const { Text, Paragraph } = Typography;

const Center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};



class BookTestModal extends Component {
  constructor(props) {
    super(props);
    this.authStore = props.authStore;
    this.navigate = props.navigate;
    this.count=1
  }

  hideModal = () => {
    this.authStore.hideLoginModal();
  };



  /**
   * Render a context specific Component
   * @returns
   */


  renderStage = () => {
    if (this.authStore.nextPage === LOGIN_PAGE) {
      if (this.authStore.step === INIT) {
        return <OTPGenerate authStore={this.authStore} />;
      }
      return <OTPEntry authStore={this.authStore} />;
    }

    if (this.authStore.nextPage === REGISTER_PAGE) {
      return <Register apiProxy={this.authStore.apiProxy} />;
    }

    if(this.count==1){
      if (this.authStore.nextPage === PRODUCTS_PAGE) {
        this.navigate(CART_PAGE);
      }
      this.count=2
    }

    if (this.authStore.nextPage === ADDRESS_PAGE) {
      return <RegisterAddress apiProxy={this.authStore.apiProxy} />;
    }

    this.authStore.isLoginModalVisible = false;
  };

  /**
   * The Modal will be closed automatically when the authstore is servicable.
   *
   * @returns
   */
  render() {
    const isVisible = this.authStore.isLoginModalVisible;

    return (
      <Modal
        className="BookTestModal"
        visible={isVisible}
        onCancel={this.hideModal}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <div style={Center}>
          <img alt="Nu-Med Logo" src={logo} height={90} />
          {this.renderStage()}
        </div>

        <Paragraph>
            We gather essential information to effectively communicate with you, providing updates
            on the testing progress, and aiding your <Text strong type="success">Doctor </Text> 
            in discussing the test results with you.
        </Paragraph>
      </Modal>
    );
  }
}

export default observer(BookTestModal);
