import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { PhoneTwoTone, MailTwoTone } from "@ant-design/icons";

const cardStyle = {
  boxShadow:
    " rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px , rgba(0, 0, 0, 0.09) 0px -3px 5px",
  marginTop: "20px",
  borderRadius: "10px",
  overflow: "hidden",
  minHeight: "300px",
};

const cardTitleStyle = { backgroundColor: "rgba(196,228,229,0.27)" }
const iconStyle = { fontSize: "large" }
const contactDetailsStyle = { fontFamily: "Poppins-SemiBold" }
const branchDetailsStyle = { fontFamily: "Poppins-Regular" }
const BranchCard = ({ branch }) => {
  const renderBranchDetails = (title, titleContent) => {
    if (titleContent) {
      return (
        <Col span={22}>
          <Typography.Paragraph
            style={branchDetailsStyle}
            ellipsis={true ? { rows: 2, tooltip: titleContent } : false}
          >
            {`${titleContent}`}
          </Typography.Paragraph>
        </Col>
      );
    } else {
      return (
        <Col span={22}>
          <h4 style={branchDetailsStyle}>{`${title} Details Unavailable`}</h4>
        </Col>
      );
    }
  };

  const renderCardTitle = (branchName) => (
    <Typography.Paragraph
      style={contactDetailsStyle}
      ellipsis={true ? { rows: 1, tooltip: branchName } : false}
    >
      {branchName}
    </Typography.Paragraph>
  );

  const renderContactDetails = (mobNumOne, mobNumTwo) => {
    if (mobNumOne && mobNumTwo) {
      return (
        <h4 style={contactDetailsStyle}>
          {`${branch.phoneNumber}, ${branch.mobileNumber}`}
        </h4>
      );
    } else {
      return (
        <h4 style={contactDetailsStyle}>
          Helpline Number - 044-2722 4599/1171
        </h4>
      );
    }
  };

  const renderSubAddressDetails = (titleContent) => {
    if (titleContent) {
      return (
        <Col span={22}>
          <h4 style={branchDetailsStyle}>{`${titleContent}`}</h4>
        </Col>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Card
      title={renderCardTitle(branch.branchName)}
      headStyle={cardTitleStyle}
      style={cardStyle}
      bodyStyle={{
        fontFamily: "Poppins-Bold",
        color: "#330033",
        minHeight: "25vh",
      }}
    >
      <div style={{ padding: "10px 20px 0 20px" }}>
        <Row gutter={12}>
          <Col span={2}>
            <PhoneTwoTone style={iconStyle} className="phone-icon" />
          </Col>
          <Col span={22} className="phone-icon">
            {renderContactDetails(branch.phoneNumber, branch.mobileNumber)}
          </Col>
        </Row>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://mail.google.com/mail/?view=cm&fs=1&to=${branch.email}&su=SUBJECT&body=BODY`}
        >
          <Row gutter={12}>
            <Col span={2}>
              <MailTwoTone style={iconStyle} className="phone-icon" />
            </Col>
            <Col span={22} className="phone-icon">
              {renderBranchDetails("E-mail", branch.email)}
            </Col>
          </Row>
        </a>
        <Row gutter={12}>
          {renderBranchDetails("Address", branch.branchAddress)}
        </Row>
        <Row gutter={12}>{renderSubAddressDetails(branch.state)}</Row>
        <Row gutter={12}>{renderSubAddressDetails(branch.city)}</Row>
        <Row gutter={12}>{renderSubAddressDetails(branch.pincode)}</Row>
      </div>
    </Card>
  );
};

export default BranchCard;
