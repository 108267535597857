import React from 'react'

export const OrangeCard = ({Image,Title,Desc}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "240px", padding: "30px 30px", backgroundColor: "#f27906", justifyContent: "center", alignItems: "center", borderRadius: "8px" }}>
      <div style={{ height: "80px", width: "80px" }}>
        <img src={Image} alt={Title} style={{ width: "80px", filter: "brightness(0) invert(1)" }} />
      </div>
      <h1 style={{ color: "white", fontFamily: "Mont-SemiBold", fontSize: "17px", marginTop: "15px", width: "120%", textAlign: "center" }}>{Title}</h1>
      <div style={{ height: "60px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <p style={{ color: "black", textAlign: "center", fontFamily: "Mont-Regular", fontSize: "16px", marginTop: "0" }}>{Desc}</p>
      </div>
    </div>
  )
}
