import React from "react";
import { ConfigProvider, Select, notification } from 'antd';
import { DONE } from "../../label.EN";

const { Option } = Select;

const BranchSearch = ({ branchStore, suggestions }) => {

    const openNotification = (type, placement, content) => {
        notification[type]({
            description: content,
            placement,
            duration: 6
        });
        branchStore.setState(DONE);
    };

    const onSearch = async (pincode) => {
        const value = pincode.slice(0, 6)
        console.log("total",pincode)
        console.log("value",pincode)
        if (value.length === 6) {
            const error = await branchStore.searchBranch(value);
            if (error) {
                openNotification('error', "top", error.errorMessage)
            }
        } else {
            openNotification('error', "top", "The PIN code must be six characters long.")
        }
    }

    return (
        <ConfigProvider renderEmpty={() => "Please type min 3 characters"}>
            <div className="branch-search-container">
                <Select
                    showSearch
                    className="branch-search"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Search Your Pincode"
                    onChange={ onSearch}
                >
                    {
                        suggestions.map((suggestion, index) => (<Option value={suggestion.searchValue} key={index}>{suggestion.searchValue}</Option>))
                    }
                </Select>
            </div>
        </ConfigProvider>
    )
}

export default BranchSearch