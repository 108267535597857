import React from "react";
import "./App.css";
import AppRoutes from "./components/routes/AppRoutes";
import { Layout } from "antd";
import NavBar from "./components/tool-bar/NavBar";
import FooterComponent from "./components/footer/FooterComponent";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useState } from "react";

const { Content, Footer } = Layout;

const minHeight = window.innerHeight * 0.89;

const tagManagerArgs = {
  gtmId: 'GTM-WDK2MB4'
}

TagManager.initialize(tagManagerArgs)

function onChangeClassName(location) {
  if (location === '/cart' ) {
    return 'cart-footer-background'
  }
  if (location === '/testDetails' ) {
    return 'cart-footer-background'
  }
  return 'footer-background'
}

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Layout>
      <NavBar navigate={navigate} isOpen={isDrawerOpen} onCloseDrawer={handleCloseDrawer} />
      <Content
        className="site-layout"
        style={{ padding: "0 0px", marginTop: 64 }}
      >
        <div style={{ padding: 2, minHeight, backgroundColor: "white" }}>
          <AppRoutes />
        </div>
      </Content>
      {/* <Footer className={onChangeClassName(pathname)}> */}
        <FooterComponent /> 
      {/* </Footer> */}
    </Layout>
  );
}

export default App;
