import React, { Component } from "react";
import APIProxy from "../../../services/APIProxy";
import UpArrow from "../../../images/down-right.png";
import "./HomeAbout.css"; 
import { AboutNumbers } from "./AboutNumbers";

class HomeAbout extends Component {
  constructor() {
    super();
    this.apiProxy = new APIProxy();
  }

  render() {
    return (
      <div className="home-about-container">
        <div className="section-title">ABOUT NU MED</div>
        <div className="content">
          <div className="left-content">
            Pathology Labs, Blood Test Home Visit, Labs, Thyroid Testing Laboratory,
            Blood Collection Centres, ECG Testing Labs, Digital X Ray Centres,
            Clinical Labs.
          </div>
          <div className="right-content">
            Established in the year 2008, Nu Med Labs in Kanchipuram Railway
            Station, Kanchipuram is a top player in the category Pathology Labs in
            Kanchipuram. This well-known establishment acts as a one-stop
            destination servicing customers both local and from other parts of
            Kanchipuram. Over the course of its journey, this business has
            established a firm foothold in its industry. The belief that customer
            satisfaction is as important as their products and services have helped
            this establishment garner a vast base of customers, which continues to
            grow by the day.
          </div>
        </div>
        <div className="read-more-about">
          <div className="read-more-text">READ MORE</div>
          <img
            src={UpArrow}
            alt="Down Arrow"
            className="arrow-icon"
          />
        </div>
        <AboutNumbers/>
      </div>
    );
  }
}

export default HomeAbout;
