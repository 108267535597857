import React, { Component } from "react";
import Banner from './Banner';
import HomeAbout from './HomeAbout';
import Featured from "./Featured/Featured";
import { Services } from "./Services/Services";

const WhiteCenter = { alignItems: "center",width:"100%", background: '#fff' };

class HomePage extends Component {
  render() {
    return (
      <>
        <div style={WhiteCenter}><Banner /></div>
        <div style={WhiteCenter}><HomeAbout /></div>
        <div style={WhiteCenter}><Featured/></div>
        <div style={WhiteCenter}><Services/></div>
      </>
    )
  }
}

export default HomePage;