import React, { useContext } from "react";
import { observer } from "mobx-react";
import CartStore from "./CartStore";
import { StoreContext } from "../../storeContext";
import { useNavigate } from "react-router-dom";
import CartComponent from "./CartComponent";

function Cart() {
  const context=useContext(StoreContext)
  const apiProxy = context.authStore.apiProxy;
  const notificationStore = context.notificationStore;
  const userId = apiProxy.getUserId();
  const branchId = apiProxy.userContext.branchId;

  const cartStore = new CartStore({
      apiProxy: apiProxy,
      branchId: branchId,
      userId: userId,
      notificationStore: notificationStore,
      authStore:context.authStore
    });

const navigate = useNavigate();
  return (
    <CartComponent navigate={navigate} apiProxy={apiProxy} cartStore={cartStore} notificationStore={notificationStore} />
  )
}
export default observer(Cart);
