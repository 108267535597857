import React, { useState } from "react";
import { Row, Col, Image, Modal } from "antd";
import { Progress, Space } from "antd";
import { Typography } from "antd";
import {
  CART_PAGE,
  IN_PROGRESS_ORDERS,
  REPORTS,
  RUPPEE_SYMBOL,
  SCHEDULED_ORDERS,
} from "../../label.EN";
import scheduleCalendar from "../../images/schedule-calendar.svg";
import scheduleClock from "../../images/schedule-clock.svg";
import wallet from "../../images/wallet.svg";
import barcode from "../../images/barcode.svg";
import testTube from "../../images/testTube.svg";
import undo from "../../images/undo.svg";
import download from "../../images/download.svg";
import rightHalfArrow from "../../images/right-half-arrow.svg";
import { useNavigate } from "react-router-dom";
import { timeConvert, convertDate } from "../Util";

const { Text, Link } = Typography;
const CALENDAR_ICON = "calendar-icon";
const CLOCK_ICON = "clock-icon";
// const scheduledTime = {

// };

const ReportBody = ({ report, title, reportSubLabelColor, reportStore }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRepeatModalOpen, setIsRepeatModalOpen] = useState(false);

  const date = report.schedule.date;
  const time = report.schedule.time;
  const sampleCollectedTime = report.schedule.actualTime;
  const sampleRecievedTime = report.schedule.actualTime;
  const reportGeneratedTime = report.schedule.reportTime;
  const navigate = useNavigate();
  let progress = report.schedule.progress;
  console.log("progress id",report.schedule.progress)
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const showRepeatModal = () => {
    setIsRepeatModalOpen(true);
  };

  const handleRepeatClose = () => {
    setIsRepeatModalOpen(false);
  };

  const repeatOrder = async (orderId) => {
    const mssg = await reportStore.repeatOrder(orderId);
    if (mssg === "success") {
      navigate(CART_PAGE);
    } else {
      console.log("Error in repeat order ", mssg);
    }
  };

  const renderSchedule = (title, content, image, alt) => (
    <div
      className="report-content-container"
      style={{ backgroundColor: "#ECF0F2" }}
    >
      <Image preview={false} width={15} src={image} alt={alt} />
      <div className="report-content-alignment">
        <h5 className="poppins-light no-margin" style={reportSubLabelColor}>
          {title}
        </h5>
        <h4 className="poppins-medium  no-margin" style={reportSubLabelColor}>
          {content}
        </h4>
      </div>
    </div>
  );

  const renderReportItems = (
    backgroundColor,
    image,
    alt,
    title,
    content,
    section,
    width
  ) => (
    <div
      className="report-content-container"
      style={{ backgroundColor: backgroundColor }}
    >
      <Image preview={false} width={width} src={image} alt={alt} />
      <div className="report-content-alignment">
        {section === REPORTS ? (
          <h4 className="poppins-semibold no-margin" style={{ color: "white" }}>
            {title}
          </h4>
        ) : section === "amount" ? (
          <>
            <h5
              className="poppins-light no-margin"
              style={{ color: "white" }}
            >{`${title}`}</h5>
            <h4
              className="poppins-medium  no-margin"
              style={{ color: "white" }}
            >{`${RUPPEE_SYMBOL} ${content}`}</h4>
          </>
        ) : (
          <>
            <h5 className="poppins-light no-margin" style={{ color: "white" }}>
              {title}
            </h5>
            <h4
              className="poppins-medium  no-margin"
              style={{ color: "white" }}
            >
              {content}
            </h4>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Col xxl={3} xl={3} lg={12} xs={12} sm={12} md={12}>
        {title === SCHEDULED_ORDERS ? (
          renderSchedule(
            "Scheduled Date",
            date,
            scheduleCalendar,
            CALENDAR_ICON
          )
        ) : title === IN_PROGRESS_ORDERS ? (
          <div
            style={{
              backgroundColor: "#ecf0f2",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Text style={{ fontWeight: "bold",fontSize: ".7rem" }}>Phlebotomist Number:</Text>
            <br />
            {report.phlebotomist !== null ? (
              <Text>{report.phlebotomist.phoneNumber}</Text>
            ) : (
              <Text>no one assigned</Text>
            )}
          </div>
        ) : (
          renderSchedule(
            "Report Date",
            convertDate(reportGeneratedTime),
            scheduleCalendar,
            CALENDAR_ICON
          )
        )}
      </Col>
      <Col xxl={3} xl={3} lg={12} xs={12} sm={12} md={12}>
        {title === SCHEDULED_ORDERS ? (
          renderSchedule(
            "Scheduled Time",
            timeConvert(time.slice(0, 5)),
            scheduleClock,
            CLOCK_ICON
          )
        ) : title === IN_PROGRESS_ORDERS ? (
          <div
            style={{
              backgroundColor: "#ecf0f2",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Text
              style={{ margin: "0px", fontSize: ".7rem", fontWeight: "bold" }}
            >
              {progress === 1
                ? "Phlebotomist Allocated"
                : progress == 2
                ? "Sample Collected"
                : "Sample Received"}
            </Text>
            <Progress
              percent={progress == 1 ? 33.33 : progress == 2 ? 66.66 : 100}
              showInfo={false}
            />
          </div>
        ) : (
          renderSchedule(
            "Report Time",
            timeConvert(reportGeneratedTime.slice(11, 16)),
            scheduleClock,
            CLOCK_ICON
          )
        )}
      </Col>
      <Col xxl={3} xl={3} lg={12} xs={12} sm={12} md={12}>
        {renderReportItems(
          "#F27906",
          wallet,
          "wallet-icon",
          "Amount",
          report.price,
          "amount",
          20
        )}
      </Col>

      <Col xxl={3} xl={3} lg={12} xs={12} sm={12} md={12}>
        {renderReportItems(
          "#8BC34A",
          barcode,
          "barcode-icon",
          "SID Code",
          report.sidCode,
          "",
          20
        )}
      </Col>

      <>
        {title === REPORTS ? (
          <>
            <Col
              onClick={showRepeatModal}
              xxl={3}
              xl={3}
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              {renderReportItems(
                "#104169",
                undo,
                "undo-icon",
                "Repeat Order",
                "",
                REPORTS,
                40
              )}
            </Col>
            <Col xxl={3} xl={3} lg={12} xs={12} sm={12} md={12}>
              <a
                href={`https://limsapi.numedlabs.com/api/report/getpdf?id=${report.schedule.reportId}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {renderReportItems(
                  "#2D94C6",
                  download,
                  "download-icon",
                  "Download Report",
                  "",
                  REPORTS,
                  40
                )}
              </a>
            </Col>
          </>
        ) : (
          <Col xxl={6} xl={6} lg={24} xs={24} sm={24} md={24}>
            <div
              onClick={showModal}
              className="report-content-container"
              style={{
                backgroundColor: "#2D94C6",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Row className="flex-center">
                <Image
                  preview={false}
                  width={30}
                  src={testTube}
                  alt="test-tube-icon"
                />
                <h4
                  className="poppins-semibold no-margin"
                  style={{ color: "white" }}
                >
                  View Booked Tests
                </h4>
              </Row>
              <Image
                preview={false}
                width={8}
                src={rightHalfArrow}
                alt="right-arrow-icon"
              />
            </div>
          </Col>
        )}
        <Modal
          style={{ color: "#2D94C6" }}
          footer={null}
          centered
          title={`SID - ${report.sidCode}`}
          open={isModalOpen}
          onCancel={handleClose}
          cancelText="Close"
        >
          <div>
            <h3 className="poppins-semibold" style={{ color: "#330033" }}>
              Tests for {report.patient.name}
            </h3>
            <ol className="poppins-semibold">
              {report.tests.map((test, id) => (
                <li key={id}>{test}</li>
              ))}
            </ol>
          </div>
        </Modal>
        <Modal
          style={{ color: "#2D94C6" }}
          centered
          title="Repeat Order"
          onOk={() => repeatOrder(report.orderId)}
          open={isRepeatModalOpen}
          okText="Confirm"
          onCancel={handleRepeatClose}
        >
          <div>
            <h3 className="poppins-semibold" style={{ color: "#330033" }}>
              The tests and members associated with this order will be added to
              the cart
            </h3>
            <ol className="poppins-semibold">
              {report.tests.map((test, id) => (
                <li key={id}>{test}</li>
              ))}
            </ol>
          </div>
        </Modal>
      </>
    </>
  );
};
export default ReportBody;
