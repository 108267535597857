import { action, computed, makeObservable, observable } from "mobx";
import { isNull } from "../components/Util";
import { otpGenerationQuery, fetchCurrentAddressQuery, otpValidationQuery, } from "../services/Queries"; import APIProxy from "../services/APIProxy";
import { LOGIN_PAGE, PRODUCTS_PAGE, REGISTER_PAGE, ADDRESS_PAGE, CART_PAGE } from "../label.EN";
import { apiHost } from "../services/APIEndpoints";
import socket from "../socket/Socket";

const INIT = "init";
const PENDING = "pending";
const DONE = "done";
const ERROR = "error";

// The Possible Page the User can navigate from here

// The two steps of the Login
const INIT_STEP = "init";
const OTP_STEP = "otp";

const INVALID_MOBILE = "The given mobile number is invalid.";
const OTP_GEN_ERROR = "Unable to generate OTP";
const OTP_FORMAT_ERROR = "The format of the OTP is incorrect";
const ERROR_MESSAGE = "Unable to fetch User Details";

class AuthStore {
  nextPage = LOGIN_PAGE;
  state = INIT;
  step = INIT_STEP;
  otpErrorMessage = "";
  uploadedFiles=[];

  isLoginModalVisible = false;

  apiProxy = new APIProxy();
  isAuthenticated = false;
  message = "";

  constructor(props) {
    this.notificationStore = props.notificationStore;
    makeObservable(this, {
      nextPage: observable,
      state: observable,
      step: observable,
      isLoginModalVisible: observable,
      otpErrorMessage: observable,
      uploadedFiles:observable,

      isLoading: computed,
      isError: computed,
      isDone: computed,
      isReadyForService: computed,

      showLoginModal: action,
      hideLoginModal: action,
      generateOtp: action,
      validateMobileNumber: action,
      submitOtp: action,
      validateOtp: action,
      resolveNextPage: action,
      setOtpErrorMessage: action,
      registerSocket: action,
      addFile:action,
      removeFile:action
    });
  }

  getOtpErrorMessage = () => this.otpErrorMessage;

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isDone() {
    return this.state === DONE;
  }

  get isReadyForService() {
    return this.nextPage === PRODUCTS_PAGE;
  }

  showLoginModal = () => {
    this.isLoginModalVisible = true;
  };

  hideLoginModal = () => {
    this.isLoginModalVisible = false;
  };

  setOtpErrorMessage = (message) => (this.otpErrorMessage = message);
  /**
   * When the request to generate a OTP is dispatched successfuly
   * we will promote to the next step
   * @param {*} mobileNumber
   * @returns
   */
  generateOtp = async (request) => {
    const mobileNumber = request.mobileNumber.toString();

    this.state = PENDING;
    this.setOtpErrorMessage("");

    this.apiProxy.init();

    this.validateMobileNumber(mobileNumber);

    if (this.state === ERROR) {
      return;
    }

    this.apiProxy.setMobileNumber(mobileNumber);

    const variables = { input: mobileNumber };

    const response = await this.apiProxy.query(
      apiHost,
      otpGenerationQuery,
      variables
    );

    const result = await response.json();

    const errorMessage = result.data.generateOtp.errors;

    if (errorMessage) {
      this.message = OTP_GEN_ERROR;
      this.state = ERROR;
      return;
    }

    if (result.data.generateOtp.rows === 1) {
      this.state = INIT;
      this.step = OTP_STEP;
    }
  };

  validateMobileNumber = (mobileNumber) => {
    if (mobileNumber.length !== 10) {
      this.message = INVALID_MOBILE;
      this.state = ERROR;
    }
  };

  validateOtp = (otp) => {
    this.state = PENDING;

    if (otp.length !== 4) {
      this.message = OTP_FORMAT_ERROR;
      this.state = ERROR;
      return;
    }

    const value = parseInt(otp);
    if (isNaN(value)) {
      this.message = OTP_FORMAT_ERROR;
      this.state = ERROR;
    }
  };

  hasUser = (result) => {
    return (
      !isNull(result.data.validateOtp) &&
      !isNull(result.data.validateOtp.otpData.user)
    );
  };

  submitOtp = async (otp) => {
    this.state = PENDING;

    this.isAuthenticated = false;
    this.validateOtp(otp);

    if (this.state === ERROR) {
      return;
    }

    const variables = {
      criteria: { phoneNumber: this.getMobileNumber(), otp: parseInt(otp) },
    };

    try {
      const response = await this.apiProxy.query(
        apiHost,
        otpValidationQuery,
        variables
      );
      const result = await response.json();
      const error = result.data.validateOtp.error;

      if (error) {
        this.state = ERROR;
        this.setOtpErrorMessage(error.message);
        return;
      }

      //isAuthenticated represents OTP Validated
      this.isAuthenticated = true;

      if (!this.hasUser(result)) {
        this.nextPage = REGISTER_PAGE;
        this.state = DONE;
        return;
      }

      const userContext = result.data.validateOtp.otpData;
      this.apiProxy.setUserContext(userContext);
      this.notificationStore.setCartCount(userContext.cartCount);

      const hasAddress = await this.setBranchDetails();

      if (hasAddress === 0) {
        this.nextPage = ADDRESS_PAGE;
        this.state = DONE;
        return;
      }

      this.state = DONE;
      this.resolveNextPage();
    } catch (e) {
      this.state = ERROR;
      this.message = ERROR_MESSAGE;
      return true;
    }
  };

  resolveNextPage = () => {
    if (!this.isAuthenticated) {
      this.nextPage = REGISTER_PAGE;
      return;
    }

    if (!this.apiProxy.hasAddress()) {
      this.nextPage = ADDRESS_PAGE;
      return;
    }

    //this.registerSocket();

    this.nextPage = PRODUCTS_PAGE;
    console.log("nextpage",this.nextPage);
    this.hideLoginModal();
    // this.notificationStore.launch();
  };

  setBranchDetails = async () => {
    const criteria = { userId: this.apiProxy.getUserFuzzyId() };
    const response = await this.apiProxy.query(
      apiHost,
      fetchCurrentAddressQuery,
      criteria
    );
    const result = await response.json();
    const error = result.data.fetchCurrentAddress.error;

    if (error) {
      return 0;
    }

    this.apiProxy.updateUserCurrentAddress(result);

    return 1;
  };

  getMobileNumber = () => {
    return this.apiProxy.getMobileNumber();
  };

  getApiProxy = () => {
    return this.apiProxy;
  };

  registerSocket = () => {
    socket
      .on("token", (data) => {
        console.log(data);
      })
      .emit("init", { id: this.apiProxy.getUserFuzzyId(), role: "member" });
  };
  addFile=(file)=>{
    console.log(file)
    this.uploadedFiles.push(file)
  }
  removeFile=(e)=>{
    console.log(e)
    let files= this.uploadedFiles.filter((file)=>{
      return file.uid!==e.uid
      
   })
   this.uploadedFiles=files
   console.log(this.uploadedFiles)
  }
}

export default AuthStore;
