import { action, makeObservable, observable } from "mobx";
import { fetchOrderHistoryQuery, repeatOrderQuery } from "../services/Queries";
import { apiHost } from "../services/APIEndpoints";

const INIT = "init";
const ERROR = "error";
const DONE = "done";

class ReportStore {
  state = INIT;
  inProgressOrders = [];
  scheduledOrders = [];
  reports = [];

  constructor(props) {
    this.apiProxy = props.apiProxy;
    makeObservable(this, {
      state: observable,
      reports: observable,
      inProgressOrders: observable,
      scheduledOrders: observable,

      setReports: action,
      setState: action,
      setInProgressOrders: action,
      setScheduledOrders: action,
      repeatOrder: action,
    });
  }

  setState = (state) => (this.state = state);

  setReports = (reports) => (this.reports = reports);

  setScheduledOrders = (scheduled) => (this.scheduledOrders = scheduled);

  setInProgressOrders = (progress) => (this.inProgressOrders = progress);

  repeatOrder = async (orderId) => {
    const queryVariables = {
      criteria: {
        branchId: this.apiProxy.userContext.branchId,
        userId: this.apiProxy.getUserFuzzyId(),
        orderId: orderId,
      },
    };

    const response = await this.apiProxy.query(
      apiHost,
      repeatOrderQuery,
      queryVariables
    );

    const result = await response.json();
    const errors = result.data.repeatOrder.errors;

    if (errors) {
      this.setState(ERROR);
      return errors.message;
    }

    return "success";
  };

  fetchReports = async () => {
    const queryVariables = {
      criteria: {
        userId: this.apiProxy.getUserFuzzyId(),
        branchId: this.apiProxy.userContext.branchId,
      },
    };

    const response = await this.apiProxy.query(
      apiHost,
      fetchOrderHistoryQuery,
      queryVariables
    );

    const result = await response.json();
    const error = result.data.fetchOrderHistory.error;

    if (error) {
      this.setState(ERROR);
      return error.message;
    }

    const orderHistory = result.data.fetchOrderHistory.orderHistory;

    console.log("Order History ", orderHistory);

    const scheduled = orderHistory.filter((order) => {
      if (order.schedule.actualTime === null && order.schedule.progress === 0) {
        return order;
      }
    });

    const progress = orderHistory.filter((order) => {
      if (
        (order.schedule.actualTime !== null ||
          order.schedule.progress !== 0 ||
          order.schedule.receivedTime !== null) &&
        order.schedule.reportTime === null
      ) {
        return order;
      }
    });
    const reports = orderHistory.filter((order) => {
      if (order.schedule.reportTime !== null) {
        return order;
      }
    });

    this.setScheduledOrders(scheduled);
    this.setInProgressOrders(progress);
    this.setReports(reports);

    this.setState(DONE);
  };

  getReports = () => this.reports;

  getScheduledOrders = () => this.scheduledOrders;

  getInProgressOrders = () => this.inProgressOrders;
}

export default ReportStore;
