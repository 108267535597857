import React from "react";
import { Card, Col, Collapse, Row } from "antd";
import { observer } from "mobx-react";
import ReportBody from "./ReportBody";
import { FEMALE, MALE } from "../../label.EN";

const { Panel } = Collapse;

const salutation = {
  1: "Mr.",
  2: "Mrs.",
  4: "Ms.",
  5: "Master.",
  6: "Baby.",
  7: "Dr.",
  8: "B/O.",
};

const reportLabelColor = { color: "#104169", paddingLeft: "10px" };
const reportSubLabelColor = { color: "#330033", paddingLeft: "10px" };
const cardBodyStyle = { color: "#330033" };

const cardContentContainer = {
  display: "flex",

  alignItems: "center",
};

const ReportCard = ({ report, title, reportStore }) => {
  return (
    <Card hoverable bodyStyle={cardBodyStyle} className="report-card-style">
      <Row gutter={[18, 18]} style={cardContentContainer}>
        <Col xxl={4} xl={4} lg={18} xs={18} sm={18} md={18}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3
              className="poppins-semibold no-margin"
              style={reportLabelColor}
            >{`${salutation[report.patient.salutationId]} ${
              report.patient.name
            }`}</h3>
            <h4
              className="poppins-light no-margin"
              style={reportSubLabelColor}
            >{`${report.patient.age.slice(
              0,
              2
            )} years ${report.patient.age.slice(4)} months`}</h4>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={4} xs={4} sm={4} md={4}>
          <h4
            className="poppins-semibold no-margin"
            style={reportLabelColor}
          >{`${report.patient.gender === "M" ? MALE : FEMALE}`}</h4>
        </Col>
        <ReportBody
          reportStore={reportStore}
          report={report}
          title={title}
          reportSubLabelColor={reportSubLabelColor}
        />
      </Row>
    </Card>
  );
};

export default observer(ReportCard);
