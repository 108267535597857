import { makeObservable, observable, computed, action } from "mobx";
import { apiHost } from "../../services/APIEndpoints";
import { checkOutQuery, getSelectProductsQuery, setScheduleForSelectedProductQuery } from "../../services/Queries";
import { assetHost } from '../../services/APIEndpoints';
import { StoreContext } from "../../storeContext";
const PENDING = "pending";
const DONE = "done";
const ERROR = "error";

const EMPTY_MESSAGE = { status: "", help: "" };
const ERROR_MESSAGE = {
  status: "error",
  help: "Unable to fetch the Cart Items. The Service is unavailable. Please try after sometime.",
};

export default class CartStore {
  cartProducts = [];
  cartCount = 0;
  totalCartAmount = 0;
  selectedDate = '';
  selectedSlot = ''
  state = PENDING;
  message = EMPTY_MESSAGE;
  bookingStatus = 0;
  bookingType="collection"
  scheduledTime = null;
  static contextType = StoreContext;
  
  constructor(props) {


    this.apiProxy = props.apiProxy;
    this.branchId = props.branchId;
    this.userId = props.userId;
    this.notificationStore = props.notificationStore;
    this.authStore=props.authStore

    makeObservable(this, {
      state: observable,
      cartProducts: observable,
      cartCount: observable,
      selectedDate: observable,
      selectedSlot: observable,
      bookingStatus: observable,
      scheduledTime: observable,
      bookingType:observable,

      isLoading: computed,
      isError: computed,
      isDone: computed,

      fetchTests: action,
    });
  }

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isDone() {
    return this.state === DONE;
  }

  /**
   * To Fetch the test from the Cart of the given user
   * @param {*} userId
   * @returns
   */
  fetchTests = async () => {
    const productCriteria = {
      criteria: {
        userId: this.userId,
        branchId: this.branchId,
        checkOut: false,
      },
    };

    this.state = PENDING;
    this.message = EMPTY_MESSAGE;

    this.cartProducts = [];
    this.cartCount = 0;

    try {
      const response = await this.apiProxy.query(
        apiHost,
        getSelectProductsQuery,
        productCriteria
      );

      const data = await response.json();

      if (data.data.fetchOrder.error !== null) {
        this.message = ERROR_MESSAGE;
        this.state = DONE;
        return;
      }

      const productData = data.data.fetchOrder.orderDetail.productCarts;
      const cartAmount = data.data.fetchOrder.orderDetail.totalPrice;

      this.cartProducts = productData;

      this.cartCount = productData.length;
      this.totalCartAmount = cartAmount;
      this.notificationStore.setCartCount(productData.length);



      if (this.totalCartAmount > 0) {
        this.scheduledTime = productData[0].schedule.timeFrom;
      }

      this.state = DONE;
    } catch (e) {
      this.state = ERROR;
      this.message = ERROR_MESSAGE;
      console.log(e);
    }
  };

  checkOutCart = async () => {

    const queryVariables = {
      criteria: {
        userId: this.userId,
        branchId: this.branchId,
      },
    };
    this.state = PENDING;
    if(this.bookingType=="collection"){
    await this.updateTimeSlote();}
    try {

      const response = await this.apiProxy.query(
        apiHost,
        checkOutQuery,
        queryVariables
      );
      const result = await response.json();
      let orderId=result.data.checkout.value.id;
      console.log(result.data)
      this.uploadFiles(orderId)

      if (result.data.checkout.error !== null) {
        this.message = ERROR_MESSAGE;
        this.state = DONE;
        this.bookingStatus = -1;
        return -1;
      }
      await this.fetchTests();
      this.notificationStore.setCartCount(0);
      this.state = DONE;
      this.bookingStatus = 1
      return 1;
    } catch (e) {
      console.log(e);
    }
  };
  updateTimeSlote = async () => {
    const slot = this.selectedDate.format("YYYY-MM-DD") + 'T' + this.selectedSlot.format('HH:mm:ss') + 'Z';

    const timeSlotInput = {
      input: {
        userId: this.userId,
        timeFrom: slot,
        timeTo: slot
      }
    }
    try {

      const slotResponse = await this.apiProxy.query(
        apiHost,
        setScheduleForSelectedProductQuery,
        timeSlotInput
      )

      const result = await slotResponse.json();
      if (result.data.updateScheduleCarts.error !== null) {
        this.message = ERROR_MESSAGE;
        this.state = DONE;
        return;
      }
      this.fetchTests();
      this.notificationStore.setCartCount(0);
      this.state = DONE;
    } catch (e) {
      console.log(e);
    }
  }
  uploadFiles=async(id)=>{
    console.log(id)
    let url=assetHost+id
    console.log(this.context)
        let files=this.authStore.uploadedFiles
        console.log(files)
        var formdata = new FormData();
        files.map((file,index)=>{
           
           formdata.append(index+file.name,file,file.name)
        })
        console.log(files) 
        try{
           let response =await this.apiProxy.asyncUploadPost(url,formdata)
           console.log(response);
          }
          catch(e){
            console.log(e)
          }
  }

}
