import React from "react";
import { PageHeader } from "antd";
import { observer } from "mobx-react";

const UniversalHeader = ({ headerStyle, title, extra, tags }) => {
  return (
    <PageHeader
      className="page-header-style"
      style={headerStyle}
      tags={tags}
      title={title}
      extra={[extra]}>
    </PageHeader >
  )
}

export default observer(UniversalHeader);
