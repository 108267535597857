import { Card, Typography, Image } from "antd";
import shoppingCart from "../../images/shopping-cart-add.svg";
import { PACKAGES_TITLE, RUPPEE_SYMBOL } from "../../label.EN";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { observer } from "mobx-react";
import slandingArrow from "../../images/slandingArrow.svg";
import testTube from "../../images/testTube.svg";

const cartContainer = {
  marginTop: "5%",
  display: "flex",
  backgroundColor: "rgba(196,228,229,0.27)",
  justifyContent: "space-between",
  alignItems: "center",
};

const fontColor = { color: "#593059" };

const ProductCard = ({
  product,
  getDetails,
  handleOnProductClick,
  active,
  cartProducts,
}) => {
  return (
    <Card
      className="cardStyle"
      style={
        cartProducts.has(product.meta.id)
          ? { borderColor: "#F27905" }
          : { borderColor: "#D6DBE0" }
      }
      bodyStyle={{ fontFamily: "Poppins-Bold", color: "#330033" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Text
          style={fontColor}
          variant="h4"
          component="div"
          ellipsis={true ? { rows: 1, tooltip: product.meta.name } : false}
        >
          {product.meta.name}
        </Typography.Text>
        <Image width={30} src={testTube} alt="test-tube-icon" preview={false} />
      </div>
      <div style={{ paddingTop: "0%" }}>
        {active === PACKAGES_TITLE ? (
          <Typography
            style={{ fontFamily: "Poppins-Regular", color: "#593059" }}
            variant="body2"
            color="text.secondary"
          >
            Includes {product.meta.parameters} Tests
          </Typography>
        ) : (
          <></>
        )}
        <>
          {!product.meta.specialInstructions ? (
            <Typography.Paragraph
              className="poppins-regular"
              style={{ color: "#6FB5D8" }}
              ellipsis={
                true
                  ? { rows: 1, tooltip: product.meta.specialInstructions }
                  : false
              }
            >
              No Special Preparation Required
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph
              className="poppins-regular"
              style={{ color: "#6FB5D8" }}
              ellipsis={
                true
                  ? { rows: 1, tooltip: product.meta.specialInstructions }
                  : false
              }
            >
              {product.meta.specialInstructions}
            </Typography.Paragraph>
          )}
          <Typography.Text
            className="view-link"
            style={{ color: "#F27905" }}
            onClick={() => getDetails(product)}
          >
            View details <Image src={slandingArrow} preview={false} />
          </Typography.Text>
        </>
      </div>
      <div style={cartContainer}>
        {cartProducts.has(product.meta.id) ? (
          <CheckCircleTwoTone
            style={{ fontSize: "30px", padding: "10px 20px 10px 20px" }}
            twoToneColor="#52c41a"
          />
        ) : (
          <img
            className="shopping-icon-link"
            style={{
              backgroundColor: "#DCEFF0",
              padding: "10px 20px 10px 20px",
            }}
            onClick={(e) => handleOnProductClick(e, product.meta.id, active)}
            src={shoppingCart}
            alt="shopping-cart-icon"
          />
        )}
        <Typography
          variant="body1"
          component="div"
          style={{ margin: 0, padding: "10px 20px 10px 0px" }}
        >
          {RUPPEE_SYMBOL}
          {product.price.basePrice}
        </Typography>
      </div>
    </Card>
  );
};

export default observer(ProductCard);
