import React, { useState } from "react";
import { observer } from "mobx-react";
import FamilyMemberForm from "./FamilyMemberForm";
import { Card, Button, List, Modal, Tag, Typography } from "antd";
import { TeamOutlined, UserAddOutlined } from "@ant-design/icons";
import { Avatar, Badge } from 'antd';
import MemberCard from "./MemberCard";
import "./MemberDetails.css"

const { Text } = Typography;

const memberButtonStyle = {
  borderRadius: "15px",
  backgroundColor: "#2D94C6",
  color: "white",
};
const saveButtonStyle = {
  borderRadius: "15px",
  backgroundColor: "#F27906",
  color: "white",
  border: "none"
};
const selectButtonStyle = {
  borderRadius: "15px",
  backgroundColor: "#2D94C6",
  color: "white",
  margin: "auto"
};

function MemberList({ allMembers, memberStore, cartStore, memberListStore,showMembersFn,showTestsFn, showTimeFn}) {
  const [members, setMembers] = useState(allMembers);
  const [nonSavedSelections, setNonSavedSelections] = useState({});
  
  const handleMemberClick = () => {
    if (memberStore.canSelect) {
      memberStore.setMode('select');
    }
  }

  const renderMemberList = () => {
    return (
      <List
        style={{ overflowY: 'auto', paddingTop: "0.25%" }}
        className="memberCard"
        itemLayout="horizontal"
        dataSource={members}
        renderItem={(item) =>
          <MemberCard
            patient={item.patient}
            salutation={item.salutationType}
            memberStore={memberStore}
            nonSaved={nonSavedSelections}
            setNonSaved={setNonSavedSelections}
            onClick={() => handleMemberClick()}
          />
        }
      />
    )
  }

  const hideModal = () => {
    memberStore.setMode('view');
  }

  const renderAddMemberForm = () => {
    return (
      <Modal title="Add Member" visible={memberStore.mode === "add"} footer={null} onCancel={hideModal} maskClosable={false}>
        <FamilyMemberForm
          memberStore={memberStore}
          cartStore={cartStore}
          setMembers={setMembers}
          memberListStore={memberListStore}
        />
      </Modal>
    );
  };

  const renderSelectedMembersCount = () => {
    return (
      <Tag style={{ borderRadius: "3px", fontFamily: "Poppins-Regular" }} color="#2D94C6">{memberListStore.selectedMembers} Selected <TeamOutlined /></Tag>
    )
  }

  const saveSelection = async () => {
    members.map(member => {
      if (nonSavedSelections[member.patient.id] !== undefined) {
        member.patient.isSelected = nonSavedSelections[member.patient.id]
      }
    })

    setMembers([...members])

    await memberStore.updateMembers(members);
    await cartStore.fetchTests();
    memberStore.setMode('view');
    let newMembers = memberListStore.members
    setMembers(newMembers)
  }

  const cancelSelection = async () => {
    memberStore.setMode('view');
    await memberListStore.fetchMembers();
    setMembers(memberListStore.members)
  }
  const nextPage = () => {
    showMembersFn(false)
    showTestsFn(true)
    showTimeFn(false)
  }
  const addMemberButton = () => {
    if (memberStore.canAdd) {
      return <Button style={{ borderRadius: "15px", backgroundColor: "#8BC34A", color: "white", fontWeight: "700" }} onClick={() => memberStore.setMode('add')}>Add <UserAddOutlined /></Button>
    }
  }

  const saveSelectionButton = () => {
    if (memberStore.canSaveSelection) {
      return <Button style={saveButtonStyle} type="primary" onClick={saveSelection}>Save</Button>
    }
  }

  const cancelSelectionButton = () => {
    if (memberStore.canSaveSelection) {
      return <Button style={memberButtonStyle} type="primary" onClick={cancelSelection}>Cancel</Button>
    }
  }
  const nextButton = () => {
   
      return <Button style={memberButtonStyle} type="primary" onClick={nextPage}></Button>
      
    
  }
  return (
    <Card
      title={
        <Badge count={memberListStore.selectedMembers} style={{ color: "white", backgroundColor: "#8BC34A", fontSize: "15px" }}>
          <UserAddOutlined style={{ height: "30px", width: "30px", fontSize: "25px" }} />
        </Badge>
      }
      className="cart-card-style"
      style={{ paddingBottom: "10px" }}
      //extra={renderSelectedMembersCount()}
      extra={addMemberButton()}
      actions={[
        saveSelectionButton(),
        nextButton(),
        cancelSelectionButton()
      ]}
    >
      {renderMemberList()}
      {renderAddMemberForm()}
    </Card>
  );
}

export default observer(MemberList);
