// Query to fetch the list of products

// import {exp} from 'react-native-reanimated';

export const fetchProductsQuery = `query ($criteria: Criteria!) {
    fetchProducts(criteria: $criteria) {
      products {
        meta {
          id
          name
          glimpse
          specialInstructions
          imageUrl
          reviewRating
          parameters
        }
        price {
          id
          basePrice
          promotionPrice
          promotionTag
        }
      }
      error {
        message
      }
    }
  }`;

export const fetchFeaturedProductsQuery = `query ($criteria: FeatureCriteria!) {
    fetchFeaturedProducts(criteria: $criteria) {
      products {
        meta {
          id
          name
          specialInstructions
          kind
          glimpse
          parameters
          imageUrl
        }
        price {
          id
          basePrice
          promotionPrice
        }
      }
      error {
        message
      }
    }   
  }`;

export const fetchCategoryQuery = `query ($user_id: String!) {
    fetchCategories(userId: $user_id) {
     category{
      id
      categoryName
      isSelected
    }
      error {
        message
      }
    }  
  }
  `;

export const fetchProductDetailsQuery = `query ($child: Child!) {
    fetchProductDetails(child: $child) {
      productDetails {
          id
          name
          seq
          kind
          parentId
      }
      error {
        message
      }
    }
  }`;

export const fetchPatientsQuery = `query ($userId: String!) {
    fetchPatients(userId: $userId) {
      patients {
        patient {
          id
          name
          age
          isSelected
        }
        salutationType
      }
      error {
        message
      }
    }  
  }`;

export const registerUserQuery = `mutation ($input: Registration!) {
    registerUser(userRegistration: $input) {
      error {
        field
        message
      }
      detail {
        user {
          id
          name
          email
          phoneNumber
        }
        patients {
          id
          name
          age
          gender
        }
        cartCount
      }
    }
  }`;

export const userLoginQuery = `query ($criteria: LoginRequest!) {
    login(loginRequest: $criteria) {
      userDetail {
        user {
          id
          name
          email        
        }
        patients {
          id
          age
          gender
        }      
        cartCount
      }
      error {
        message
      }
    }  
  }`;

export const addToCartQuery = `
  mutation ($input: NewShoppingCartRequest!) {
    addToShoppingCart(request: $input) {
      booking{
        shoppingCart {
          id
          userId
          branchId
          productId
          quantity
          basePrice
          actualPrice
          checkedOut
        }
        cartCount
        status
      }
      errors {
        field
        message
      }
    }
  }
  `;

export const addPatientQuery = `mutation ($input: PatientDetail!) {
    addPatient(patientDetails: $input) {   
      cartPatient{
        patients{
          id
          name
          birthDate
          gender
          age       
        }
        totalValue
      }   
      errors {
        field
        message
      }
    }
  }`;

export const addPatientToSelectedProductQuery = `mutation ($input: PatientDataInput!) {
    addMember(patientDetail: $input) {
      errors {
        field
        message
      }
      patient {
        id
        name
        userId
        age
      }
    }
  }`;

export const removePatientQuery = `mutation ($input: DeleteCriteria!) {
    removePatient(deleteCriteria: $input) {   
      cartPatient{
        patients{
          id
          name
          birthDate
          gender
          age       
        }
        totalValue
      }   
      errors {
        field
        message
      }
    }
  }`;

export const updatingScheduleTimeQuery = `mutation ($input: UpdateScheduleRequest!) {
    updateSchedule(request: $input) {
      errors {
        field
        message
      }
      rows
    }
  }`;

export const setScheduleForSelectedProductQuery = `mutation ($input: UpdateScheduleUser!) {
    updateScheduleCarts(request: $input) {
      errors {
        field
        message
      }
      schedules {
        id
        patientId
        shoppingCartId
        timeFrom
        timeTo
      }
    }
  }`;

export const fetchOrderQuery = `query ($criteria: OrderCriteria!) {
    fetchOrder(orderCriteria: $criteria) {
      orderDetail {
        user {
          id
          name
        }
        addresses {
          id
          pincode
          address
        }     
        productCarts {
          shoppingCart{
            id
            userId
            branchId
            productId
            quantity
            basePrice
            promotionPrice
            actualPrice
            checkedOut        
          }
          product {
            id
            name
            imageUrl
            reviewRating
            parameters
            specialInstructions   
            glimpse        
          }
          schedule {
            id
            patientId
            timeFrom
            timeTo
          }
        }     
        totalPrice
      }   
      error {
        message
      }
    }  
  }`;

export const fetchOrderHistoryQuery = `query ($criteria: OrderHistoryCriteria!) {
    fetchOrderHistory(criteria: $criteria) {
        orderHistory{
          orderId
          sidCode
          patient{
            id
            salutationId
            name
            age
            gender
          }
          tests
          schedule {
            timeFrom
            time
            date
            actualTime
            receivedTime
            reportTime
            reportId
            progress
          }
          price
          charges
          phlebotomist {
            id
            phoneNumber
          }
        }
      error {
        message
      }
    }  
  }
  `;

export const checkOutQuery = `query ($criteria: CheckoutCriteria!) {
    checkout(checkoutCriteria: $criteria) {
      value {
        id
      }
      error {
        message
      }
    }  
  }`;

export const deleteShoppingCartItemQuery = `mutation ($input: UpdateShoppingCartRequest!) {
    updateShoppingCart(request: $input) {
      booking {
        orders {
          user {
            id
            name
          }
          addresses {
            id
            pincode
            address
          }
          productCarts {
            shoppingCart{
              id
              promotionPrice
              basePrice
              promotionPrice
              quantity
              actualPrice
            }
            product {
              id
              name
              glimpse
              specialInstructions
              imageUrl         
            }
            schedule {
              id
              timeFrom
              timeTo
            }
          }
          totalPrice
        }  
        cartCount
      }
      error {
        field
        message
      }
    }
  }`;

export const selectMemberQuery = `mutation ($input: SelectedPatientDetail!) {
    insertPatientsCart(patientDetails: $input) {
      cartPatient {
        patients {
          id
          userId
          name
          age
          gender
          isSelected
        }
        totalValue
      }
      errors {
        field
        message
      }
    }
  }`;

export const searchQuery = `query ($criteria: SearchCriteria!) {
    searchProducts(criteria: $criteria) {
      products {
        meta {
          id
          name
          glimpse
          specialInstructions
          kind
          imageUrl
          reviewRating
          parameters
        }
        price {
          id
          basePrice
          promotionPrice
          promotionTag
        }
      }
      error {
        message
      }
    }  
  }`;

export const fetchCurrentAddressQuery = `query ($userId: String!) {
    fetchCurrentAddress(userId: $userId) {
     addressBranch {
      address{
        id
        address
        pincode
        isCurrent
      }
      branch {
        id
        name
        branchName
      }
    }
      error {
        message
      }
    }  
  }`;

export const fetchAddressesQuery = `query ($userId: String!) {
    fetchAddresses(userId: $userId) {
      addresses {
        address {
          id
          address
          pincode
          isCurrent
        }
        branch {
          id
          name
          branchName
        }
      }
      error {
        message
      }
    }  
  }
  `;

export const addNewAddressesQuery = `mutation ($input: AddressData!) {
    registerNewAddress(patientAddress: $input) {
     addressBranch {
        address {
          id
          address
          pincode
         isCurrent
        }
        branch {
          id
          name
          branchName
        }
      }
      error {
        field
        message
      } 
    }
  }`;

export const addCurrentAddressQuery = `mutation ($input: ChangeAddress!) {
    changeAddress(addressData: $input) {
      addressBranch {
        address {
          id
          userId
          pincode
          address
          isCurrent
        }
        branch {
          id
          name
          branchName
        }
      }
      error {
        field
        message
      }
     
    }
   
  }`;
export const selectPatientQuery = `mutation ($input: [SelectCriteria!]!) {
    insertPatients(patientDetails: $input) {
      patients {
        id
        userId
        name
        age
        gender
        isSelected
      }
      errors {
        field
        message
      }
    }
  }`;

export const patientDataQuery = `mutation ($input: PatientDataInput!) {
    addMember(patientDetail: $input) {
      errors {
        field
        message
      }
      patient {
        id
        name
        userId
        age
        
      }
    }
  }`;

export const deletePatientQuery = `mutation ($input: Int!) {
    deletePatient(patientId: $input) {
      errors {
        field
        message
      }
      patient{
        id
        name
        age
        deleted
      }
    }
  }
  `;

export const deleteAddressQuery = `mutation ($input: Int!) {
    deleteAddress(addressId: $input) {
      errors {
        field
        message
      }
      rows
    }
  }`;

export const verifyPincodeQuery = `query ($pincode: Int!) {
  verifyPincode(pincode: $pincode) {
      branch {
        id
        name
        branchName
        branchAddress
        pincode
        phoneNumber
        mobileNumber
        email
      }
    error {
      message
    }
  }  
}`;

export const fetchCountQuery = `query ($criteria: CartCountCriteria!) {
    fetchCartCount(criteria: $criteria) {
     value
      error {
        message
      }
    }  
  }`;

export const fetchSelectedMembersQuery = `query ($userId: String!) {
    fetchSelectedPatients(userId: $userId) {
      patients {
        patient {
          id
          name
          age
          isSelected
        }
        salutationType
      }   
      error {
        message
      }
    }  
  }`;

export const otpGenerationQuery = `mutation ($input: String!) {
    generateOtp(phoneNumber: $input) {
      errors {
        field
        message
      }
      rows
    }
  }`;

export const generateTicketQuery = `mutation ($userId: String!) {
    generateTicket(userId: $userId) {
      errors {
        field
        message
      }
      rows
    }
  }`;

export const getMembersQuery = `query ($userId: String!) {
  fetchPatients(userId: $userId) {
    patients {
      patient {
        id
        name
        age
        isSelected
        gender
      }
      salutationType
    }
   
    error {
      message
    }
  }  
}
`;

export const otpValidationQuery = `query ($criteria: OtpData!) {
    validateOtp(criteria: $criteria) {
      otpData{
        phoneNumber
        user {
          id
          name
          phoneNumber
        }
        patients {
          id
          name
          age
          gender
        }
        cartCount
      }
      error {
        message
      }
    }  
  }`;

export const deleteShoppingCartQuery = `mutation ($input: UpdateShoppingCartRequest!) {
    updateShoppingCart(request: $input) {
      booking {
        orders {
          user {
            id
            name
          }
          addresses {
            id
            pincode
           address
          }
        
          productCarts {
            shoppingCart{
              id
              promotionPrice
              basePrice
              promotionPrice
              quantity
              actualPrice 
          }
          product {
            id
            name
            glimpse
            specialInstructions
            imageUrl
            parameters
            
          }
          schedule {
            id
            timeFrom
            timeTo
          }
        }
        totalPrice
      }  
      cartCount
    }
      error {
        field
        message
      }
      
    }
  }`;

export const getSelectProductsQuery = `query ($criteria: OrderCriteria!) {
    fetchOrder(orderCriteria: $criteria) {
      orderDetail {
        user {
          id
          name
        }
        addresses {
          id
          pincode
          address
        }
        
        productCarts {
          shoppingCart{
            id
            actualPrice
            basePrice
            quantity
          }
          product {
            id
            name
            specialInstructions
            glimpse
            parameters
            
          }
          schedule {
            id
            timeFrom
            time
            date
          }
        }
        totalPrice
      }
      error {
        message
      }
    }   
  }`;

export const sonicSearchQuery = `query ($criteria: SearchCriteria!) {
    searchProducts(criteria: $criteria) {
      products {
        meta {
          id
          name
          kind
          parameters
        }
        price {
          id
          basePrice
          promotionPrice
        }
      }
      
      error {
        message
      }
    }   
  }`;

export const fetchBranches = `query {
  fetchBranches {
    branches {
        id
        name
        branchName
        branchAddress
        pincode
        city
        state
        phoneNumber
        mobileNumber
        email
    }
    error {
      message
    }
  }   
}`

export const branchSearchQuery = `query {
  fetchBranchPincodes{
  
  branchPincodes{
    branchId
    pincode
    locality
    city
    state
    searchValue
  }
  
  error {
    message
  }
}   
}`

export const repeatOrderQuery = `mutation ($criteria: RepeatOrderCriteria!) {
  repeatOrder(criteria: $criteria) {
    errors {
      field
      message
    }
    rows
  }
}`