import React, { useContext, useEffect, useState } from "react";
import ProductList from "../../components/product-display/ProductList";
import { observer } from "mobx-react";
import { StoreContext } from "../../storeContext";
import "./ProductDisplay.css";
import * as productStyle from "./ProductDisplayCss.js";
import { Button, Row, Col } from "antd";
import TestSearch from "../../components/search/TestSearch";
import { useNavigate } from "react-router-dom";
import {
  PACKAGES_TITLE,
  TESTS_TITLE,
  PROFILE,
  TEST,
  TEST_DETAIL_PAGE,
  PRODUCT_DETAIL_PAGE,
} from "../../label.EN";
import UniversalHeader from "../../components/page-header/UniversalHeader";

const SEARCH_PLACEHOLDER = "Search Test & Heath Packages";

const ProductDisplay = () => {

  const navigate = useNavigate();

  const [width, setWidth] = useState(undefined)

  useEffect(() => {
    const handleWidthChange = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleWidthChange)
    return () => {
      window.addEventListener("resize", handleWidthChange)
    }

  })

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  const { productStore } = useContext(StoreContext);
  const kind = productStore.getKind();
  const products = productStore.getProducts();
  const { authStore } = useContext(StoreContext);
  const apiProxy = authStore.apiProxy;
  const branchId = apiProxy.userContext.branchId;
  const active = productStore.getActiveFilterBtn();

  useEffect(() => {
    kind === PROFILE
      ? productStore.fetchProducts(null, kind, 0, 167)
      : productStore.fetchProducts(null, kind, 0, 997);
  }, [kind, productStore]);

  const onChange = async (value, price) => {
    if (value[0] === "P") {

      let details = await productStore.getProfileDetails(value);
      navigate(
        PRODUCT_DETAIL_PAGE, 
        { state: { productDetails: details, profileId: value, price: price } }
      );

    } else if (value[0] === "T") {

      let testDetails = await productStore.getTestDetails(value);
      navigate(
        TEST_DETAIL_PAGE, 
        { state: { productDetails: testDetails, testId: value, instructions: testDetails.Specialinstructions }}
      );
    }
  };

  const onToggle = (checked) => productStore.setKind(checked);

  const renderKindBtn = (typeContent, key) => (
    <Button
      key={key}
      onClick={() => {
        productStore.setActiveFilterBtn(typeContent);
        onToggle(typeContent === TESTS_TITLE ? 0 : 1);
      }}
      className="poppins-regular"
      style={active === typeContent ? productStyle.ACTIVE_STYLE : productStyle.INACTIVE_STYLE}
      type={active === typeContent ? "primary" : "default"}
    >
      {typeContent === TESTS_TITLE ? TEST + 'S' : PACKAGES_TITLE.toUpperCase()}
    </Button>
  );

  const renderTitle = () => {
    if (width > 725) {
      return (
        <span className="ant-page-header-heading-title page-header-title">{kind === PROFILE ? PACKAGES_TITLE : TESTS_TITLE}</span>
      );
    } else {
      <></>
    }
  }

  const renderTestSearch = () => {
    return (
      <TestSearch
        SearchStyle={productStyle.testSearchStyle}
        apiProxy={apiProxy}
        branchId={branchId}
        onChange={onChange}
        placeholder={SEARCH_PLACEHOLDER} />
    )
  }

  const renderPageExtra = () => (
    <Row gutter={10} style= {productStyle.renderKindBtnStyle}>
      <Col>
        {renderKindBtn(TESTS_TITLE, 1)}
      </Col>
      <Col>
        {renderKindBtn(PACKAGES_TITLE, 2)}
      </Col>
    </Row>
  )

  return (
    <div>
      <UniversalHeader
        title={renderTitle()}
        extra={renderPageExtra()}
        tags={renderTestSearch()}
      />
      <div className="body-style">
        <ProductList products={products} productStore={productStore} active={active} />
      </div>
    </div>
  );
};

export default observer(ProductDisplay);