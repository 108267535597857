import React from "react";
import { observer } from "mobx-react";
import { Button, Badge, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { CART_PAGE } from "../../label.EN";

function CartButton({ notificationStore }) {
  const navigate = useNavigate();
  const cartCount = notificationStore.getCartCount();
  return (
    <Button style={{ fontFamily: "Poppins-Regular",fontSize: "12px" , color:"#F27906"}}  type='link' onClick={() => navigate(CART_PAGE)}>
      Cart ({cartCount})
    </Button>
  );
}

export default observer(CartButton);
