import React, { useContext, useEffect } from "react";
import ReportList from "../../components/report-display/ReportList";
import { StoreContext } from "../../storeContext";
import { toJS } from 'mobx'
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { PRODUCTS_PAGE, SCHEDULED_ORDERS, IN_PROGRESS_ORDERS, REPORTS, REPORTS_AND_ORDERS } from "../../label.EN";
import "./ReportDisplay.css"
import UniversalHeader from "../../components/page-header/UniversalHeader";


const emptyMesssageStyle = { fontFamily: "Poppins-SemiBold" }

const emptyMessageContainer = { padding: "2vh 0 0 4vw" }

const reportBodyStyle = { margin: "20px 1%", borderRadius: "5px" }

const ReportDisplay = () => {
  const { reportStore } = useContext(StoreContext);
  const reports = reportStore.getReports();
  const scheduledOrders = reportStore.getScheduledOrders();
  const inProgressOrders = reportStore.getInProgressOrders();

  useEffect(() => {
    reportStore.fetchReports();
  }, [reportStore]);

  const renderTitle = () => (
    <span className="ant-page-header-heading-title page-header-title">{REPORTS_AND_ORDERS}</span>
  )

  const renderEmptyOrderMessage = (title) => {
    if (title === SCHEDULED_ORDERS) {
      return (
        <div style={emptyMessageContainer}>
          <p style={emptyMesssageStyle}>There are no orders scheduled for you at the moment.</p>
        </div>
      )
    } else if (title === IN_PROGRESS_ORDERS) {
      return (
        <div style={emptyMessageContainer}>
          <p style={emptyMesssageStyle}>There are no orders in progress for you at the moment.</p>
        </div>
      )
    } else {
      return (
        <div style={emptyMessageContainer}>
          <p style={emptyMesssageStyle}>You dont have any reports generated yet.</p>
          <Link to={PRODUCTS_PAGE} style={{ color: "black", textDecoration: 'underline' }}>
            <p style={emptyMesssageStyle}>Would you like to take a look at the tests we provide ?</p>
          </Link>
        </div>
      )
    }
  }

  const renderOrders = (title, order) => (
    <div style={{ backgroundColor: '#ECF0F2', padding: "1% 2%", marginBottom: '2%', borderRadius: '5px' }}>
      <h1 style={{ fontFamily: "Poppins-Bold", color: '#330033', fontSize: 'x-large' }}>{title}</h1>
      {toJS(order).length ? <ReportList reports={order} title={title} /> : renderEmptyOrderMessage(title)}
    </div>
  );

  return (
    <>
      <UniversalHeader
        title={renderTitle()}
      />
      <div style={reportBodyStyle}>
        {renderOrders(SCHEDULED_ORDERS, scheduledOrders)}
        {renderOrders(IN_PROGRESS_ORDERS, inProgressOrders)}
        {renderOrders(REPORTS, reports)}
      </div>
    </>
  );
};

export default observer(ReportDisplay);
