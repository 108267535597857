import React, { useEffect, useState } from "react"
import { Form, Input, Card, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FormItem from "antd/es/form/FormItem";
import * as addressStyle from "./AddressCss.js";

const {Text} = Typography;

export default function Address({ apiProxy }) {
    const [pincode, setPincode] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setPincode(apiProxy.userContext.pincode)
        setMobile(apiProxy.userContext.user.phoneNumber)
        setAddress(apiProxy.userContext.address)
        setName(apiProxy.userContext.user.name)
    }, [])

    const [form] = Form.useForm();

    const renderTitle = () => {
        return (
            <Text className="card-header">Address</Text>
        );
    }

    return (
        <Card title={renderTitle()} style={addressStyle.titleStyle} >
            <Form style={addressStyle.formStyle} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
                <FormItem label="Name">
                    <Input
                        value={name}
                        disabled={true}
                        style={addressStyle.formInputStyle}
                        required
                        className='input-box'
                        size="large"
                    />
                </FormItem>
                <FormItem label="Mobile">
                    <Input
                        value={mobile}
                        disabled={true}
                        style={addressStyle.formInputStyle}
                        required
                        className='input-box'
                        size="large"
                    />
                </FormItem>

                <Form.Item label="Address">
                    <TextArea rows={2}
                        value={address}
                        disabled={true}
                        style={addressStyle.formInputStyle}
                    />
                </Form.Item>
                <FormItem label="Pincode">
                    <Input
                        value={pincode}
                        disabled={true}
                        style={addressStyle.formInputStyle}
                        required
                        className='input-box'
                        size="large"
                    />
                </FormItem>
            </Form>
        </Card>
    )

}
