import { isNull } from "../components/Util";

export default class APIProxy {
  userContext = {};
  
  userName = "Raja Subramanian";
  
  authHeaders = {
    token: "",
    email: "",
    userFuzzyId: "",
    mobileNumber: "",
  };

  init = () => {
    this.authHeaders.token = "";
    this.authHeaders.email = "";
    this.authHeaders.userFuzzyId = "";
    this.authHeaders.mobileNumber = "";
    this.userContext = {};
    this.userName = "";
  };

  getUserFuzzyId = () => {
    return this.authHeaders.userFuzzyId;
  };

  setMobileNumber = (mobileNumber) => {
    this.authHeaders.mobileNumber = mobileNumber;
  };

  getUserId = () => {
    return this.authHeaders.userFuzzyId;
  };

  getMobileNumber = () => {
    return this.authHeaders.mobileNumber;
  };

  hasAddress =() =>{
    return (!isNull(this.userContext) && !isNull(this.userContext.pincode));
  }

  setUserContext =(userContext) =>{
    if (isNull(userContext.user)){
      return;
    }
    this.userContext=userContext;
    this.authHeaders.userFuzzyId = userContext.user.id;
    this.userName = userContext.user.name;
  }

 
  updateUserCurrentAddress=(result) => {

    if (isNull(result.data)|| isNull(result.data.fetchCurrentAddress)){
      return;
    }
  
    const data = result.data.fetchCurrentAddress.addressBranch;
      
    if (isNull(data)) {
      return;
    }

    this.userContext.pincode = data.address.pincode;
    this.userContext.address = data.address.address;
    this.userContext.branchId = data.branch.id;
    this.userContext.branchName = data.branch.branchName;
  }

  updateNewUserAddress = (result) =>{
    if (isNull(result.data)|| isNull(result.data.registerNewAddress)){
      return;
    }
    const data = result.data.registerNewAddress.addressBranch;
    if (isNull(data)){
      return;
    }
    
    this.userContext.address=data.address.address;
    this.userContext.pincode=data.address.pincode;
    this.userContext.branchId=data.branch.id;
    this.userContext.branchName=data.branch.branchName;
  }

  /**
   * 
   */
  getServicedBy = () => {
    if(!this.hasAddress()) {
      return `Searching your nearest branch`;
    }
    return `Serving you from ${this.userContext.branchName}.`
  }

  updateCredentialHeaders(credentials) {
    this.authHeaders = {
      token: credentials.token,
      email: credentials.email,
      userFuzzyId: credentials.id,
    };
  }

  get(url, action) {
    fetch(url, {
      headers: {
        ...this.authHeaders,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        action(json);
      });
  }

  post(url, bodyParams, action) {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...this.authHeaders,
      },
      body: JSON.stringify(bodyParams),
    })
      .then((response) => response.json())
      .then((json) => {
        action(json);
      });
  }

  query(url, queryString, variables) {
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        ...this.authHeaders,
      },
      body: JSON.stringify({ query: queryString, variables }),
    });
  }

  mutate(url, mutationQueryString, variables) {
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        ...this.authHeaders,
      },
      body: JSON.stringify({ query: mutationQueryString, variables }),
    });
  }

  asyncPost(url, bodyParams) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        ...this.authHeaders,
      },
      body: JSON.stringify(bodyParams),
    });
  }

  asyncUploadPost(url, bodyParams) {
    return fetch(url, {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        ...this.authHeaders,
      },
      body: bodyParams,
    });
  }

  asyncDelete(url, bodyParams) {
    return fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        ...this.authHeaders,
      },
      body: JSON.stringify(bodyParams),
    });
  }

  getAsync(url) {
    return fetch(url, {
      headers: {
        ...this.authHeaders,
      },
    });
  }
}
