import React, { Component } from "react";
import { observer } from "mobx-react";
import { LoadingOutlined, FieldTimeOutlined, FileAddOutlined, UserAddOutlined, ShoppingCartOutlined, } from "@ant-design/icons";
import { Steps } from "antd";
import { Modal, Row, Col, Button, notification, Result, Tag, Typography, Spin, Switch, Segmented, } from "antd";
import TimeSlot from "./TimeSlot";
import MemberList from "../members/MemberList";
import CartItems from "./CartItems";
import UploadFiles from "./UploadFiles";
import { StoreContext } from "../../storeContext";
import { SyncOutlined } from "@ant-design/icons";
import { SELECT_TEST_MESSAGE, SELECT_SLOT_MESSAGE, SELECT_MEMBER_MESSAGE, REPORTS_PAGE, RUPPEE_SYMBOL, } from "../../label.EN";
import UniversalHeader from "../page-header/UniversalHeader";
import {RiTestTubeLine}  from 'react-icons/ri';
import "./cartComponent.css";
import * as cartComponentStyle from "./CartComponentCss.js";

const { Text } = Typography;

const stepsContainer = { padding: "10px", width: "95%", margin: "auto" };
const stepsDiv = { paddingBottom: "-10px", backgroundColor: "white" };

const antIcon = (
  <LoadingOutlined style={{ fontSize: 84, }} spin />
);

class CartComponent extends Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.navigate = props.navigate;
    this.apiProxy = props.apiProxy;
    this.cartStore = props.cartStore;
    this.notificationStore = props.notificationStore;

    this.state = {
      bookingStatus: 0,
      showConfirmation: false,
      current: 2,
      cartstate: "Add_Member",
      showMembers: false,
      showTests: false,
      showTime: true,
      memberLine: true,
      testLine: true,
      isReady: false,
      loading: false,
      bookingType: "collection",
    };
  }

  async componentDidMount() {
    this.notificationStore = this.context.notificationStore;
    this.memberListStore = this.context.memberListStore;
    this.memberStore = this.context.memberStore;
    this.userContext = this.apiProxy.userContext;

    await this.cartStore.fetchTests();
    await this.memberListStore.fetchMembers();

    this.setState({ isReady: true });
  }
  showTimeFn=(value)=>{
    if(value==true){
      this.setState({showTime:value,memberLine:true,testLine:true})
    }
    this.setState({showTime:value})
  }
  showTestsFn=(value)=>{
    if(value==true){
      this.setState({showTests:value,memberLine:true})
    }
    this.setState({showTests:value})
  }
  showMembersFn=(value)=>{
    if(value==true){
      this.setState({showMembers:value,memberLine:false,testLine:false})
    }
    this.setState({showMembers:value})
  }
  notify = (status, type) => {
    const args = {
      message: "Home Collection",
      description: status,
      duration: 5,
      type: type,
    };
    notification.open(args);
  };

  handleOnBooking = () => {
    if (this.notificationStore.cartCount === 0) {
      this.notify(SELECT_TEST_MESSAGE, "warning");
      return 0;
    }
    if (
      (this.cartStore.selectedDate === "" ||
        this.cartStore.selectedSlot === null) &&
      this.cartStore.bookingType == "collection"
    ) {
      this.notify(SELECT_SLOT_MESSAGE, "warning");
      return 0;
    }
    if (this.memberListStore.selectedMembers === 0) {
      this.notify(SELECT_MEMBER_MESSAGE, "warning");
      return 0;
    }
    return 1;
  };

  hideBookingModal = () => {
    this.setState({ bookingStatus: 0 });
    this.navigate(REPORTS_PAGE);
  };

  showConfirm = () => {
    let result = this.handleOnBooking();
    if (result === 1) {
      this.setState({ showConfirmation: true });
    }
  };

  hideConfirm = () => {
    this.setState({ showConfirmation: false });
  };

  setShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleCofirm = async () => {
    this.setState({ showConfirmation: false, loading: true });
    let status = await this.cartStore.checkOutCart();
    this.setState({ bookingStatus: status });
  };
  handleBookingTypeToggle = (e) => {
    if (e === true) {
      this.cartStore.bookingType = "collection";
    } else {
      this.cartStore.bookingType = "walkin";
    }
  };
  renderBookingConfirmation = () => {
    return (
      <Modal
        title="Booking Status"
        visible={this.state.showConfirmation}
        onOk={this.handleCofirm}
        maskClosable={false}
        onCancel={this.hideConfirm}
      >
        <p>Please Confirm to place the order</p>
      </Modal>
    );
  };

  renderBookingStatus = () => {
    if (this.state.bookingStatus === 1) {
      this.setState({ loading: false });
    }
    return (
      <>
        <Spin
          style={{
            position: "absolute",
            zIndex: "100",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          indicator={antIcon}
          spinning={this.state.loading}
        />
        <Modal
          title="Booking Status"
          visible={this.state.bookingStatus === 1}
          footer={null}
          onCancel={this.hideBookingModal}
          maskClosable={false}
        >
          <Result
            status="success"
            title="Your order is placed succesfully!"
            subTitle="The flabotamist will arrive at your place on scheduled time"
          />
        </Modal>
      </>
    );
  };

  renderPage = () => {
    if (this.memberStore === undefined) {
      return <></>;
    }

    const allMembers = this.memberListStore.members;
    return (
      <div>
        <Row gutter={[8, 8]}>
          {this.state.showMembers && (
            <Col xs={24} md={12} xl={12} style={{ margin: "auto" }}>
              <MemberList
                allMembers={allMembers}
                memberStore={this.memberStore}
                cartStore={this.cartStore}
                memberListStore={this.memberListStore}
                showMembersFn={this.showMembersFn}
                showTestsFn={this.showTestsFn}
                showTimeFn={this.showTimeFn}
              />
            </Col>
          )}

          {this.state.showTests && (
            <Col xs={24} md={12} xl={12}>
              <UploadFiles apiProxy={this.apiProxy}   showMembersFn={this.showMembersFn}
                showTestsFn={this.showTestsFn}
                showTimeFn={this.showTimeFn}/>
            </Col>
          )}
          {this.state.showTests && (
            <Col xs={24} md={12} xl={12}>
              <CartItems
                apiProxy={this.apiProxy}
                cartStore={this.cartStore}
                notificationStore={this.notificationStore}
                showMembersFn={this.showMembersFn}
                showTestsFn={this.showTestsFn}
                showTimeFn={this.showTimeFn}
              />
            </Col>
          )}
          {this.state.showTime && (
            <Col xs={24} md={12} xl={12} style={{ margin: "auto" }}>
              <TimeSlot
                cartStore={this.cartStore}
                bookingStatus={this.state.bookingType}
                handleOnBooking={this.handleOnBooking}
                showConfirm={this.showConfirm}
                setShowConfirmation={this.setShowConfirmation}
                userContext={this.userContext}
                showMembersFn={this.showMembersFn}
                showTestsFn={this.showTestsFn}
                showTimeFn={this.showTimeFn}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  };

  renderTotalAmount = () => {
    if (this.cartStore === undefined) {
      return <></>;
    }
    if (this.cartStore.isDone) {
      return (
        <Tag style={cartComponentStyle.AmountTagStyle} color="#104169">
          <ShoppingCartOutlined style={cartComponentStyle.CartStyle} /> {RUPPEE_SYMBOL}
          {this.cartStore.totalCartAmount}
        </Tag>
      );
    }
    if (this.cartStore.isError) {
      return <Tag color="red">...</Tag>;
    }

    return (
      <Tag icon={<SyncOutlined spin />} color="processing">
        ..
      </Tag>
    );
  };

   memberStep = ()=> {
    return({
    description:  <div style={this.state.showMembers ? cartComponentStyle.cartTitleStep : { color: "black" }}>  Step 1 </div> ,
    title: <div style={this.state.showMembers ? cartComponentStyle.cartTitle:{}}> Clans </div>,
    status: this.state.memberLine ? "finish" : this.state.showMembers ? "process" : "process",
    icon: <UserAddOutlined style={this.state.showMembers ? cartComponentStyle.cartTitle:{}} />,
  })};

   testsStep = ()=>{
    return({
    description: ( <div style={this.state.showTests ? cartComponentStyle.cartTitleStep : { color: "black" }}>  Step 2 </div> ),
    title: <div style={this.state.showTests ? cartComponentStyle.cartTitle:{}}> Tests </div>,
    status: this.state.testLine ? "finish" : this.state.showTests ? "process" : "process",
    icon: <RiTestTubeLine style={this.state.showTests ? cartComponentStyle.cartTitle:{}} />,
  })};

   timeStep = ()=> {
    return ({
    description: ( <div style={this.state.showTime ? cartComponentStyle.cartTitleStep : { color: "black" }}>  Step 3 </div> ),
    title: <div style={this.state.showTime ? cartComponentStyle.cartTitle:{}}> Done </div>,
    status: this.state.showDone ? "process" : "process",
    icon: <FieldTimeOutlined style={this.state.showTime ? cartComponentStyle.cartTitle:{}} />,
  })};
  
  renderPageExtra = () => {
    return (
      <Row>
        <div>{this.renderTotalAmount()}</div>
      </Row>
    );
  };

  renderPageTitle = () => {
    return (
      <Text className="page-header-title" style={{ marginLeft: "5px" }}>
        Booking
      </Text>
    );
  };

  onChange = (value) => {
    if (value == 0) {
      this.setState({
        showMembers: true,
        showTests: false,
        showTime: false,
        memberLine: false,
        testLine: false,
      });
    } else if (value == 1) {
      this.setState({
        showMembers: false,
        showTests: true,
        showTime: false,
        memberLine: true,
        testLine: false,
      });
    } else if (value == 2) {
      this.setState({
        showMembers: false,
        showTests: false,
        showTime: true,
        memberLine: true,
        testLine: true,
      });
    }
    this.setState({ current: "value" });
  };

  render() {
    return (
      <div>
        <div style={cartComponentStyle.mainHeaderStyle}>
          <UniversalHeader
            headerStyle={cartComponentStyle.pageHeaderStyle}
            title={this.renderPageTitle()}
            extra={this.renderPageExtra()}
          />

          <div style={stepsContainer}>
            <Steps
              labelPlacement="horizontal"
              size="small"
              style={stepsDiv}
              responsive={false}
              current={this.state.current}
              onChange={this.onChange}
              items={[this.memberStep(), this.testsStep(), this.timeStep()]}
            />
          </div>
        </div>

        <div className="body-style " style={{ paddingTop: "2%" }}>
          {this.state.isReady && this.renderPage()}
          {this.state.isReady && this.renderBookingConfirmation()}
          {this.state.isReady && this.renderBookingStatus()}
        </div>
      </div>
    );
  }
}
export default observer(CartComponent);
