import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import {Collapse,PageHeader,Row,Col,Statistic,Card,List,Tag} from "antd";
import SubProfileDetails from "./SubProfileDetails";
import { StoreContext } from "../../storeContext";
import DetailsHeader from "./DetailsHeader";

import { observer } from "mobx-react";
import { RUPPEE_SYMBOL } from "../../label.EN";

import calendarIcon from "../../images/schedule-calendar.svg";
import sampleIcon from "../../images/sampleCollected.svg";

const { Panel } = Collapse;

const cardStyle = {
  marginTop: "20px",
  borderRadius: "10px",
  padding: "20px",
  overFlow: "scroll",
  background: "#FFFFFF",
  borderColor: "#D6DBE0",
  fontFamily: "Poppins-Regular",
};

const cardStyleSecondRow = {
  marginTop: "20px",
  borderRadius: "10px",
  padding: "20px",
  overFlow: "scroll",
  background: "#FFFFFF",
  borderColor: "#D6DBE0",
  minHeight: "160px",
  fontFamily: "Poppins-Regular",
};

const includeTestList = {
  padding: "10px",
  borderRadius: "10px",
  borderColor: "#D6DBE0",
  overflow: "auto",
  overflowX: "hidden",
  maxHeight: "500px",
};

const includeTestStyle = {
  marginTop: "3px",
  borderRadius: "5px",
  padding: "5px",
  width: '100%', 
  overflow: 'hidden', 
  paddingLeft: "15px",
  background: "#ECF0F2",
  borderColor: "#D6DBE0",
  maxHeight: "20vw",
  width: "84vw",
  fontFamily: "Poppins-Regular",
};

const iconStyle = {
  marginRight: 20,
};

const cardTitle = {
  marginBottom: "6px",
};

const titleStyle = {
  color: "#2d94c6",
  fontFamily: "Poppins-Regular",
};

const valueStyle = {
  fontFamily: "Poppins-Regular",
  fontSize: "1.8rem",
};

const tagStyle = {
  padding: "8px 20px",
  fontSize: "15px",
  marginRight: "20px",
  marginBottom: "5px",
  borderRadius: "5px",
  fontFamily: "Poppins-Regular",
};

const ProductDetails = () => {
  const details = useLocation();
  const storeContext = useContext(StoreContext);
  const notificationStore = storeContext.notificationStore;
  const cartProducts = notificationStore.getCartProducts();
  const productStore = storeContext.productStore;

  const profileId = details.state.profileId;
  const price = details.state.price;
  const productDetails = details.state.productDetails.TestDetails;
  const sampleTypes = details.state.productDetails.SampleTypeList;
  const profile = details.state.productDetails;
  const specialInstructions = details.state.productDetails.Specialinstructions;
  const tat = Number(details.state.productDetails.TAT);
  const totalTests = details.state.productDetails.NoOfparams;
  const description = details.state.productDetails.glimpse;

  console.log("productDetails",details.state.productDetails);
  console.log("productdescription",description);

  const getSpecialInstructions = () => {
    if (specialInstructions == "") {
      return "No Special Preparation Required";
    } else {
      return specialInstructions;
    }
  };

  const getStatisticTitle = (value) => {
    return <span style={titleStyle}>{value}</span>;
  };

  return (
    <>
      <DetailsHeader
        testId={profileId}
        name={profile.ProfileName}
        productStore={productStore}
        cartProducts={cartProducts}
        title="Package"
      />
      <div className="body-style">
        <div style={{ marginLeft: "4%", marginRight: "4%" }}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} md={24} lg={16} xl={16}>
              <Card style={cardStyle} hoverable bordered={true}>
                {/* <Statistic
                  title={getStatisticTitle("About & Special Instructions")}
                  valueStyle={valueStyle}
                  value={getSpecialInstructions()}
                /> */}
                 <div>
                  <div style={cardTitle}>
                    {getStatisticTitle("About & Special Instructions")}
                  </div>
                  {
                    <>
                    <p>
                      {description}
                    </p>
                    <p>
                      {getSpecialInstructions()}
                    </p>
                    </>
                  }
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={24} lg={8} xl={8}>
              <Card style={cardStyle} hoverable bodyStyle={{ display: "flex" }}>
                <img src={calendarIcon} style={iconStyle} alt="calender_icon" />
                <Statistic
                  title={getStatisticTitle("Reports Available In")}
                  valueStyle={valueStyle}
                  value={tat + 1}
                  suffix="days"
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} md={24} lg={16} xl={16}>
              <Card
                style={cardStyleSecondRow}
                hoverable
                bordered={true}
                bodyStyle={{ display: "flex" }}
              >
                <img
                  src={sampleIcon}
                  style={iconStyle}
                  alt="sample_collection_icon"
                />
                <div>
                  <div style={cardTitle}>
                    {getStatisticTitle("Sample Types")}
                  </div>

                  {sampleTypes.map((item) => {
                    if (item !== "") {
                      return (
                        <Tag style={tagStyle} color="blue">
                          {item}
                        </Tag>
                      );
                    }
                  })}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={24} lg={8} xl={8}>
              <Card
                style={cardStyleSecondRow}
                hoverable
                bordered={true}
                bodyStyle={{ display: "flex" }}
              >
                <img
                  src={sampleIcon}
                  style={iconStyle}
                  alt="sample_collection_icon"
                />
                <Statistic
                  title={getStatisticTitle("Price")}
                  prefix={RUPPEE_SYMBOL}
                  valueStyle={valueStyle}
                  value={price}
                />
              </Card>
            </Col>
          </Row>
        </div>

        <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
          <PageHeader
            title={`Includes ${totalTests} tests`}
            className="card-title"
          />
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card hoverable bordered={true} style={includeTestList}>
              {productDetails.map((product, index) => {
                if (product.ProfileType === "test") {
                  return (
                    <List
                      style={includeTestStyle}
                      dataSource={[product]}
                      renderItem={(item) => (
                      <List.Item >{item.Testname}</List.Item>
                      )}
                    />
                  );
                }
                return (
                  <Collapse style={includeTestStyle} bordered={false}>
                    <Panel header={product.Testname} key={index}>
                      {product.ProfileType === "Subprofile" && (
                        <SubProfileDetails id={product.SubProfileId} />
                      )}
                    </Panel>
                  </Collapse>
                );
              })}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default observer(ProductDetails);
