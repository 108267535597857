import React from "react";

import AuthStore from "./stores/AuthStore";

import MemberStore from "./components/members/MemberStore";
import MemberListStore from "./components/members/MemberListStore";

import ProductStore from "./stores/ProductStore";
import ReportStore from "./stores/ReportStore";
import NotificationStore from "./stores/NotificationStore";
import BranchStore from "./stores/BranchStore";


const notificationStore = new NotificationStore();
const authStore = new AuthStore({ notificationStore });
const apiProxy = authStore.getApiProxy();

const memberListStore = new MemberListStore({ apiProxy });
const memberStore = new MemberStore({ apiProxy, memberListStore });
const productStore = new ProductStore({ apiProxy, notificationStore });
const reportStore = new ReportStore({ apiProxy });
const branchStore = new BranchStore({ apiProxy });


export const StoreContext = React.createContext({
  authStore,
  memberStore,
  memberListStore,
  productStore,
  reportStore,
  notificationStore,
  branchStore
});
